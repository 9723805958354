import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import {PopupProps} from "./PopupEditing";
import {observer} from "mobx-react";
import {useRootStore} from "../../../stores/contexts";

export type PopupDialogProps = PopupProps & {
  editComponent: JSX.Element,
}

export const PopupDialog:  React.FC<PopupDialogProps> = observer(({
  editComponent,
  row,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
}) => {
  const rootStore = useRootStore();
  const { langStore } = rootStore;

  return (
    <Dialog
        open={open}
        onBackdropClick={() => {}}
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        onClose={onCancelChanges}
        aria-labelledby="form-dialog-title"
    >
      <DialogActions style={{justifyContent: 'flex-start'}}>
        <Button onClick={onApplyChanges} color="primary">
          {"OK"}
        </Button>
        <Button onClick={onCancelChanges} color="secondary">
          {langStore.cancelCommand}
        </Button>
      </DialogActions>
      <DialogContent>
        {editComponent}
      </DialogContent>
    </Dialog>
  );
});