import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';


import esLocale from 'date-fns/locale/es'
import ptSaLocale from 'date-fns/locale/pt-BR'
import enSaLocale from 'date-fns/locale/en-US'
import {TextField, Typography} from "@mui/material";
import {EventNewCard} from "./EventNewCard";


export const EventTime = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  const locale = {
    es: esLocale,
    pt: ptSaLocale,
    en: enSaLocale,
  };

  let utcTime = "";
  if (eventNewStore.eventTime !== null) {
    try {
      utcTime = `UTC ${eventNewStore.eventTime.toISOString().substring(11,16)}`;
    } catch (e) {
      utcTime = "";
    }
  }

  return (
      <EventNewCard id={langStore.eventDateTimeTitle} title={langStore.eventDateTimeTitle}
                    errorText={eventNewStore.eventTimeErrorMessage}
                    description={langStore.eventDateTimeDescription}>
        <LocalizationProvider adapterLocale={ locale[langStore.language] } dateAdapter={AdapterDateFns}>
          <div style={{display: "flex"}}>
            <DatePicker
              label={langStore.eventDateLabel}
              value={eventNewStore.eventTime}
              onChange={(value: Date | null) => eventNewStore.onChangeEventDateTime(value)}
              renderInput={(params: any) => <TextField sx={{ m: 1 }} {...params} />}
             />
            <TimePicker
              label={langStore.eventTimeLabel}
              value={eventNewStore.eventTime}
              onChange={(value: Date | null) => eventNewStore.onChangeEventDateTime(value)}
              renderInput={(params: any) => <TextField sx={{ m: 1 }} {...params} />}
            />
            <Typography sx={{display: "inline", pt: 2}} variant={"h4"}>{utcTime}</Typography>
          </div>
        </LocalizationProvider>
      </EventNewCard>
  );
});
