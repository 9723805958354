import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {red} from "@mui/material/colors";
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onClick: () => void;
    disabled?: boolean;
}

export const CancelButton: React.FC<Props> = observer(({onClick, disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
        langStore
    } = rootStore;

    return (
        <Button
            size={prefStore.toolbarSize}
            variant="outlined" startIcon={<CancelIcon sx={{ color: red[500] }} />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onClick}>
            {langStore.cancelCommand}
        </Button>
    );
});
