// @ts-nocheck
import React from "react";
import {TableRowDetail} from '@devexpress/dx-react-grid-material-ui';
import {TableColumn, TableRow} from "@devexpress/dx-react-grid";

type TableCellProps = {
  row: any,
  tableRow: TableRow;
  tableColumn: TableColumn;
}

type DetailCellProps = TableCellProps & {
  row: any,
  changeRow: (event: object) => void,
  cancelChanges: (event: object) => void,
  applyChanges: (event: object) => void,
  children: any;
  processValueChange: (event: object) => void;
  editingRowIds: any[];
  addedRows: any[];
};

export const DetailCell: React.FC<DetailCellProps> = ({
  children,
  changeRow,
  editingRowIds,
  addedRows,
  processValueChange,
  applyChanges,
  cancelChanges,
  ...restProps
}) => {
  const { row } = restProps;
  return (
    <TableRowDetail.Cell {...restProps}>
      {React.cloneElement(children, {
        row, changeRow, processValueChange, applyChanges, cancelChanges,
      })}
    </TableRowDetail.Cell>
  );
};