import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {
    Box,
    Card,
    Checkbox, FormControl, FormControlLabel,
    Grid,
    TextField,
} from "@mui/material";
import {useRootStore} from "../../../stores/contexts";
import {UserNewCard} from "./oneUser/UserNewCard";
import {ItemsCRUD} from "../../common/controls/ItemsCRUD";
import {RoleMiniComponent} from "./userUpdate/RoleMiniComponent";
import {OrganizationMiniComponent} from "./userUpdate/OrganizationMiniComponent";

export type UserFormProps = {
  editedRow?: any;
}

export const UserForm: React.FC<UserFormProps> = observer((
  {
    editedRow,
  }) => {
  const rootStore = useRootStore();
  const { langStore, userOneStore } = rootStore;
  
  if (editedRow) {
    useEffect(() => {
      userOneStore.setEditedRow(editedRow);
    }, [editedRow, userOneStore]);
  }

  const sxCards = {minWidth: 275, m: 2, backgroundColor: "darkgray"};

  return (
    <Grid
        id={"TopOfContent"}
        container
        spacing={0}
        direction="column"
        bgcolor="lightgray"
        alignItems="center"
        justifyContent="center"
    >
      <Box sx={{pt: 1}}
           bgcolor="lightgray"
           alignItems="center"
           justifyContent="center">

        <Card sx={{...sxCards}} square={false}>
          <UserNewCard title={langStore.userDataTitle}
            id={langStore.userDataTitle}
            errorText={userOneStore.nameErrorMessage}>
            <FormControl>
              <TextField
                sx={{mt:1, p: 1, display:"inline", width: 500}}
                fullWidth
                label={langStore.labelForName}
                value={userOneStore.name}
                onChange={(event) => userOneStore.setName(event.target.value)}
              />
              <TextField
                sx={{mt:1, p: 1, display:"inline"}}
                fullWidth
                label={"Email"}
                value={userOneStore.email}
                error={userOneStore.emailErrorMessage !== ""}
                helperText={userOneStore.emailErrorMessage}
                onChange={(event) => userOneStore.setEmail(event.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    sx={{ m: 1 }}
                    checked={userOneStore.active}
                    onChange={(event) => userOneStore.setActive(event.target.checked)}/>
                }
                label={langStore.isUserActiveLabel}
              />
            </FormControl>
          </UserNewCard>

          <UserNewCard title={langStore.userRolesTitle}
            id={langStore.userRolesTitle}
            errorText={userOneStore.rolesErrorMessage}>
            <ItemsCRUD
                columns={[{name: langStore.fieldNameLang, title: langStore.labelForName}]}
                rows={userOneStore.rolesCrudRows}
                onRestore={(id) => userOneStore.onRestoreRole(id)}
                onCommitChanges={(changes) => userOneStore.onRolesCommitChanges(changes)}
                columnWidths={[{columnName: langStore.fieldNameLang, width: 300}]}
                dummyRestoredObservable={userOneStore.dummyRestoredObservable}
                showEditCommand={false}
                inlineEdit={false}
                popupComponent={(row: any) => <
                  RoleMiniComponent editedRow={row}
                />}
            />
          </UserNewCard>

          <UserNewCard title={langStore.userOrganizationsTitle}
            id={langStore.userOrganizationsTitle}
            errorText={userOneStore.organizationsErrorMessage}>
            <ItemsCRUD
                columns={[{name: "name", title: langStore.labelForName}, {name: "description", title: langStore.descriptionLabel}]}
                rows={userOneStore.organizationsCrudRows}
                onRestore={(id) => userOneStore.onRestoreOrganization(id)}
                onCommitChanges={(changes) => userOneStore.onOrganizationsCommitChanges(changes)}
                columnWidths={[{columnName: "name", width: 150}, {columnName: "description", width: 500}]}
                dummyRestoredObservable={userOneStore.dummyRestoredObservable}
                showEditCommand={false}
                inlineEdit={false}
                popupComponent={(row: any) => <OrganizationMiniComponent editedRow={row} />}
            />
          </UserNewCard>
        </Card>
      </Box>
    </Grid>
  );
});