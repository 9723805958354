import React from 'react';
import {Route, RouteParams} from '../components/my-mobx-router/src';
import {
    LoginPage,
    NotFoundPage,
    HomePage,
    ChangePasswordPage,
} from "../components";

import {
    EventNewPage,
    EventListPage,
    EventViewPage,
    EventReviewPage,
    EventUpdatePage,
    UserListPage,
    UserNewPage,
    UserUpdatePage,
} from "../entities";
import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";

export const [
    homePath,
    loginPath,
    changePasswordPath,
    eventNewPath,
    eventViewPath,
    eventUpdatePath,
    eventListPath,
    eventReviewPath,
    userListPath,
    userNewPath,
    userUpdatePath,
    pageNotFoundPath,
] = [
    "/",
    "/login",
    "/change-password",
    "/event/new",
    "/event/view",
    "/event/update",
    "/event/list",
    "/event/review",
    "/user/list",
    "/user/new",
    "/user/update",
    "not-found",
];

async function ifNotLoggedInRedirect<P extends RouteParams> (
    redirectPath: RouteNameType,
    route: Route<RootStore, P>,
    params: P,
    store: RootStore
) {
    const { authStore } = store;
    if (authStore.status !== "logged") {
        authStore.setLogIn(redirectPath);
        await store.breadcrumbsStore.push(loginPath);
        return false;
    }
    return true;
}

export type RouteNameType =
    typeof homePath
    | typeof loginPath
    | typeof changePasswordPath
    | typeof eventNewPath
    | typeof eventViewPath
    | typeof eventUpdatePath
    | typeof eventListPath
    | typeof eventReviewPath
    | typeof userListPath
    | typeof userNewPath
    | typeof userUpdatePath
    | typeof pageNotFoundPath;

export type OneEventParams = {
    id?: string;
};

export class RoutesStore {
    private rootStore: RootStore;

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    get routes(): Record<RouteNameType, Route<RootStore, any>> {
        return {
            [homePath]: new Route<RootStore>({
                path: homePath,
                component: <HomePage/>,
                title: this.routeTitle[homePath],
            }),
            [loginPath]: new Route<RootStore>({
                path: loginPath,
                component: <LoginPage/>,
                title: this.routeTitle[loginPath],
            }),
            [changePasswordPath]: new Route<RootStore>({
                path: changePasswordPath,
                component: <ChangePasswordPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(homePath, route, params, store),
                title: this.routeTitle[changePasswordPath],
            }),
            [eventNewPath]: new Route<RootStore>({
                path: eventNewPath,
                component: <EventNewPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(eventNewPath, route, params, store),
                title: this.routeTitle[eventNewPath],
            }),
            [eventViewPath]: new Route<RootStore, OneEventParams>({
                path: eventViewPath,
                component: <EventViewPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(homePath, route, params, store),
                title: this.routeTitle[eventViewPath],
            }),
            [eventUpdatePath]: new Route<RootStore, OneEventParams>({
                path: eventUpdatePath,
                component: <EventUpdatePage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(homePath, route, params, store),
                title: this.routeTitle[eventUpdatePath],
            }),
            [eventListPath]: new Route<RootStore>({
                path: eventListPath,
                component: <EventListPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(eventListPath, route, params, store),
                title: this.routeTitle[eventListPath],
            }),
            [eventReviewPath]: new Route<RootStore>({
                path: eventReviewPath,
                component: <EventReviewPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(eventReviewPath, route, params, store),
                title: this.routeTitle[eventReviewPath],
            }),
            [userListPath]: new Route<RootStore>({
                path: userListPath,
                component: <UserListPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(userListPath, route, params, store),
                title: this.routeTitle[userListPath],
            }),
            [userNewPath]: new Route<RootStore>({
                path: userNewPath,
                component: <UserNewPage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(userNewPath, route, params, store),
                title: this.routeTitle[userNewPath],
            }),
            [userUpdatePath]: new Route<RootStore>({
                path: userUpdatePath,
                component: <UserUpdatePage/>,
                beforeEnter: (route, params, store) =>
                    ifNotLoggedInRedirect(userUpdatePath, route, params, store),
                title: this.routeTitle[userUpdatePath],
            }),
            [pageNotFoundPath]: new Route<RootStore>({
                path: pageNotFoundPath,
                component: <NotFoundPage/>,
                title: this.routeTitle[pageNotFoundPath],
            }),
        }
    }

    get routeTitle(): Record<RouteNameType, string> {
        return {
            [loginPath]: this.rootStore.langStore.loginPathLabel,
            [changePasswordPath]: this.rootStore.langStore.changePasswordPathLabel,
            [homePath]: this.rootStore.langStore.homePathLabel,
            [eventNewPath]: this.rootStore.langStore.eventNewPathLabel,
            [eventViewPath]: this.rootStore.langStore.eventViewPathLabel,
            [eventUpdatePath]: this.rootStore.langStore.eventUpdatePathLabel,
            [eventListPath]: this.rootStore.langStore.eventListPathLabel,
            [eventReviewPath]: this.rootStore.langStore.eventReviewPathLabel,
            [userListPath]: this.rootStore.langStore.userListPathLabel,
            [userNewPath]: this.rootStore.langStore.userNewPathLabel,
            [userUpdatePath]: this.rootStore.langStore.userUpdatePathLabel,
            [pageNotFoundPath]: this.rootStore.langStore.pageNotFoundPathLabel,
        }
    }
}
