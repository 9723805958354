import {Workbook} from "exceljs";
import saveAs from "file-saver";

export const excelExport = async (columns: {header: string, key: string, width: number}[], data: Record<string, any>[], fileName: string, title: string="Hoja 1") => {
   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   const workbook = new Workbook();
   const worksheet = workbook.addWorksheet(title, {properties:{tabColor:{argb:'FFC0000'}}});

   worksheet.columns = columns;

   for (const row of data) {
      worksheet.addRow(row);
   }

   const excelBuffer = await workbook.xlsx.writeBuffer();
   const blob = new Blob([excelBuffer], {type: fileType});
   await saveAs(blob, fileName );
}
