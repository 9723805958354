import {LangStore} from "../stores/LangStore";

export type ApiError = {
    title:string;
    message: string;
    code: string;
    fieldName?: string;
    entityName?: string;
    serviceName?: string;
}

interface Error {
    name: string;
    message: string;
    stack?: string;
}

const prismaErrorKey = "PrismaError: ";
const validationErrorKey = "ValidationError: ";
const unauthorizedErrorKey = "Unexpected error value: [function UnauthorizedError]: ";


export const getApiError = (error: Error, langStore: LangStore): ApiError => {
    if (error.message.startsWith(prismaErrorKey)) {
        const prismaError = JSON.parse(error.message.slice(prismaErrorKey.length));
        const code = prismaError.response.errors[0].extensions.code;
        return {
            title: "Error de base de datos",
            message: prismaCode2String(code),
            code
        }
    } else if (error.message.startsWith(validationErrorKey)) {
        const theError = JSON.parse(error.message.slice(validationErrorKey.length));
        const {code, entityName, fieldName} = theError.response.errors[0].extensions;
        return {
            title: "Error de validación",
            message: `${fieldName} en ${entityName}`,
            fieldName,
            entityName,
            code
        }

    } else if (error.message.startsWith(unauthorizedErrorKey)) {
        const theError = JSON.parse(error.message.slice(unauthorizedErrorKey.length));
        const {code, entityName, fieldName, serviceName} = theError.response.errors[0].extensions;
        return {
            title: langStore.authorizationError,
            message: langStore.doNotHavePermission,
            code: code,
            entityName,
            fieldName,
            serviceName,
        }
    }
    return {
        title: "Error en el server",
        message: error.message,
        code: ""
    }
}

const prismaCode2String = (code: string): string => {
    if (code === "P1000")
        return "Fallo en el acceso al servidor de base de datos. Las credenciales no son válidas";
    if (code === "P1001")
        return "No se puede acceder al servidor de base de datos. Asegúrese de que el servidor de base de datos esté ejecutándose";
    if (code === "P1002")
        return "Tiempo de espera agotado. Asegúrese que el servidor de base de datos esté activo";
    if (code === "P1003")
        return "El servidor no puede conectarse con la base de datos";
    if (code === "P1008")
        return "Tiempo de espera agotado durante la operación"
    if (code === "P1009")
        return "La base de datos ya existe en el servidor"
    if (code === "P1010")
        return "El acceso a la base de datos fue denegado"
    if (code === "P1011")
        return "Error al abrir una conexión TLS"
    if (code === "P1012")
        return "Error en algún parámetro o atributo"
    if (code === "P1013")
        return "El string de conexión a la base de datos es incorrecto"
    if (code === "P1014")
        return "El string de conexión a la base de datos es incorrecto"
    if (code === "P1014")
        return "El tipo de modelo no existe"
    if (code === "P1015")
        return "El esquema Prisma no está soportado por la versión de base de datos"
    if (code === "P1016")
        return "El número de parámetros en el query (raw) es incorrecto"
    if (code === "P1017")
        return "El servidor de base de datos cerró la conexión"

    if (code === "P2000")
        return "El valor porvisto para el tipo de columna es muy largo"
    if (code === "P2001")
        return "El registro buscado por la condición no existe"
    if (code === "P2002")
        return "La restricción de unicidad falló"
    if (code === "P2003")
        return "La restricción de integridad referencial falló"
    if (code === "P2005")
        return "Un valor guardado en la base de datos es incorrecto para el tipo de campo"
    if (code === "P2022")
        return "La columna no existe en la base de datos actual"

    if (code === "PE2001")
        return "El registro ha sido modificado por otro usuario"

    return code;
}