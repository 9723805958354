import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {StoreStatus, WebPageStore} from "../../stores/types";
import {getApi, download} from "../../api/apiClient";
import {Event} from "../../api/graphql";
import {downloadFromBlob, getUrlFromBlob} from "./imageUtils";

export class EventViewStore implements WebPageStore {
  readonly rootStore: RootStore;
  status: StoreStatus = "uninitialized";
  callbackUpdatedParams: any;
  storeId: string = "";
  event: Event | null = null;
  eventId: string | null = null;
  errorDialogOpen: boolean = false;
  errorTitle: string = "Error";
  errorDescription: string = "Hubo un error";
  contentTop: number = 0;
  currentImage: string = "";

  constructor(rootStore: RootStore, instanceId: string) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.storeId = instanceId;
  }

  setStatus(status: StoreStatus) {
    this.status = status;
  }

  destroy() {
    // this object will be unusable
  }

  setCallbackUpdateParams(callbackUpdatedParams: any) {
    this.callbackUpdatedParams = callbackUpdatedParams;
  }

  get processingTitle() {
    return  this.rootStore.langStore.processingTitle;
  }

  get processingDescription() {
    return this.rootStore.langStore.processingDescription;
  }

  setEvent(event: Event) {
    this.event = event;
  }

  setEventId(eventId: string) {
    this.eventId = eventId;
  }

  setContentTop(contentTop: number) {
    this.contentTop = contentTop;
  }

  private async queryEvent(eventId: string): Promise<Event> {
    let data;
    const api = getApi(this.rootStore.authStore);
    data = await api.GetEvent({id: eventId});
    return data.eventOne as Event;
  }

  * download(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    downloadFromBlob(blob, name);
  }

  * downloadImage(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    if (blob.type.split("/")[0] === "image") {
      this.currentImage = yield getUrlFromBlob(blob);
    } else {
      downloadFromBlob(blob, name);
    }
  }

  setErrorDialogOpen(value: boolean, title?: string, description?: string) {
    if (title) {
      this.errorTitle = title;
    }
    if (description) {
      this.errorDescription = description;
    }
    this.errorDialogOpen = value;
  }

  * initialize(): any {
      try {
        this.status = "processing";
        const {eventTypeStore, impactTypeStore, impactStore, confidenceStore, informationSourceStore, stateStore} = this.rootStore;
        yield eventTypeStore.initialize();
        yield impactTypeStore.initialize();
        yield impactStore.initialize();
        yield confidenceStore.initialize();
        yield informationSourceStore.initialize();
        yield stateStore.initialize();

        if (this.eventId) {
          const event = yield this.queryEvent(this.eventId);
          this.setEvent(event);
        }

        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
  }

  onCloseErrorDialog(): void {
      this.errorDialogOpen = false;
  }
}