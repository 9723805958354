import * as React from 'react';
import {
    Card,
    CardContent,
    Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {disableOnTrue} from "../eventNew/utils";

type Props = {
    title: string;
    description?: string;
    errorText?: string;
    disabled?: boolean;
    children?: JSX.Element | JSX.Element[];
    id?: string;
}

export const EventViewCard: React.FC<Props> = observer(({id, disabled, title, errorText, description, children}) => {
  return (
    <div id={id} style={disableOnTrue(disabled)}>
      <Card sx={{ minWidth: 275, m: 1}} square={false}>
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {title}
            </Typography>
            <Typography sx={{ maxWidth: 500, m: 1 }} variant="body2" color="text.secondary">
                {description}
            </Typography>
            {children}
        </CardContent>
      </Card>
    </div>
  );
});
