import * as React from 'react';
import {observer} from "mobx-react";
import {Checkboxes} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";

export const EventType = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
    <EventNewCard id={langStore.eventTypeTitle} errorText={eventNewStore.eventTypesErrorMessage} title={langStore.eventTypeTitle}>
      <Checkboxes
        options={rootStore.eventTypeStore.eventTypeOptions}
        getValue={(id: string) => eventNewStore.eventTypes[id]}
        onChange={(id: string, selected: boolean) => eventNewStore.onSelectEventType(id, selected)} />
    </EventNewCard>
  );
});
