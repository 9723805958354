import {scroller} from "react-scroll";
import React from "react";
import {Box, Button} from "@mui/material";
import {observer} from "mobx-react";

type Props = {
    links: Record<string, string>[],
    navigateTo?: string;
    navigateToHash?: string;
    contentTop: number;
}

export const NavigationBar: React.FC<Props> = observer(({
    links,
    navigateTo,
    navigateToHash,
    contentTop,
}) => {
    const scroll = (to: string) => {
        scroller.scrollTo(to, {
            duration: 800,
            smooth: 'easeInOutQuint',
            delay: 0,
            offset: -(contentTop),
        })
    }

    const onNavigateTo = React.useCallback(
  (navigateTo: string | undefined, navigateToHash: string | undefined) => {
            if (navigateTo && navigateToHash) {
                scroll(navigateTo);
            }
          },
  [navigateTo, navigateToHash]);

    onNavigateTo(navigateTo, navigateToHash);

    const buttons = links.map((x, index) => {
        return <Button
            key={index}
            size={"small"}
            onClick={() => scroll(x.id)}>{x.label}
        </Button>
    });

    return (
        <Box sx={{ml: 2}}>
            {buttons}
        </Box>
    );
});