/**
 * @author Fido García <garciafido@gmail.com>
 */
import {getApiError} from "../../../api/apiErrors";
import {Entity, ListEntityStore} from "../types";

export const reloadOneEntityOnList = async <T extends Entity>(store: ListEntityStore<T>, index: number, id: string) => {
  try {
      store.setStatus("processing");
      const entity = await store.service.getOne(store.rootStore, id);
      store.setStatus("ok");
      if (store.entityList[index].id === id) {
          store.setEntity(entity, index);
      }
  } catch (error) {
    if (error instanceof Error) {
        const apiError = getApiError(error, store.rootStore.langStore);
        store.setErrorDialogOpen(true, apiError.title, apiError.message);
    } else {
        store.setErrorDialogOpen(true, store.rootStore.langStore.serverError, undefined);
    }
    store.setStatus("error");
  }
};


