import * as React from 'react';
import {
    MenuItem, Select, Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";

export const EventTemporalPrecision = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  const options = langStore.temporalPrecision.map((option, index) => {
      return (
        <MenuItem key={index} value={option.seconds}>
            <Typography sx={{m:1, display: "inline"}}>{option.label}</Typography>
        </MenuItem>
      );
  });

  return (
      <EventNewCard id={langStore.temporalPrecisionTitle} title={langStore.temporalPrecisionTitle} errorText={eventNewStore.temporalPrecisionErrorMessage}>
        <Select
          id="drop-down-precision"
          value={eventNewStore.temporalPrecisionSeg}
          onChange={
              (event) =>
                  eventNewStore.onSelectTemporalPrecision(event.target.value)}
          sx={{ m:1, width: 500 }}
        >
            {options}
        </Select>
      </EventNewCard>
  );
});
