import * as React from 'react';
import {
    FormControlLabel, FormGroup, Checkbox
} from "@mui/material";
import {observer} from "mobx-react";
import {RelatedOption} from "../types";
import {useRootStore} from "../stores/contexts";

type Props = {
    options: RelatedOption[] | null;
    getValue: (id: string) => boolean;
    onChange: (id: string, selected: boolean) => void;
}

export const Checkboxes: React.FC<Props> = observer(({options, getValue, onChange}) => {
  let elements: JSX.Element[] = [];
  const rootStore = useRootStore();
  const { langStore } = rootStore;

  if (options !== null) {
      elements = options.map((x, index) => {
          return <FormControlLabel
              key={index}
              control={<Checkbox size="small"
                                 sx={{ m: 1 }}
                                 checked={getValue(x.id) || false}
                                 onChange={(event, checked) => onChange(x.id, checked)}/>}
              label={x[langStore.labelNameLang]} />;
      });
  }

  return (
    <FormGroup>
      {elements}
    </FormGroup>
  );
});
