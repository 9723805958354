import React, {ChangeEvent} from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onChangeFile: (file: any) => void;
    acceptFileTypes?: string;
    disabled?: boolean;
}

export const FileUploadButton: React.FC<Props> = observer(({onChangeFile, acceptFileTypes,disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
    } = rootStore;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0)
         onChangeFile(event.target.files[0]);
    }

    return (
        <div>
            <label>
                <input
                    style={{ display: "none" }}
                    id="file-upload"
                    name="upload-photo"
                    accept={acceptFileTypes}
                    onChange={handleFileChange}
                    type="file"
                />
                <Button
                    size={prefStore.toolbarSize}
                    variant="outlined" startIcon={<SaveIcon />}
                    disabled={disabled === undefined ? false : disabled}
                    component="span">
                    Subir archivo
                </Button>{" "}
            </label>
        </div>
    );
});

export const SaveAndCreateButton: React.FC<Props> = observer(({onChangeFile, disabled}) => {
    return (
        <Button
            size={"small"}
            variant="outlined" startIcon={<SaveAsIcon />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onChangeFile}>
            Guardar y Crear uno nuevo
        </Button>
    );
});
