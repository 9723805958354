import React from "react";
import {
    DragDropProvider,
    Grid,
    Table,
    TableColumnReordering,
    TableColumnResizing,
    TableHeaderRow,
    TableColumnVisibility,
    TableEditRow,
    TableEditColumn,
    TableInlineCellEditing,
    TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import {
    PagingState,
    SelectionState,
    SortingState,
    EditingState,
} from "@devexpress/dx-react-grid";
import {ActionColumns} from "./plugins/ActionColumns";

export const reactGridExpress = (
    {
        columns,
        rows,
        getRowId,
        sorting,
        onSortingChange,
        columnExtensions,
        currentPage,
        pageSize,
        onSelectionChange,
        cellComponent,
        actionColumns,
        columnOrder,
        onColumnOrderChange,
        columnWidths,
        onColumnWidthsChange,
        hiddenColumnNames,
        onHiddenColumnNamesChange,
        sortingHint,
        noColumnMessage,
        editingProps,
        noDataMessage,
        inlineEdit,
        addCommand = "agrega",
        editCommand = "edita",
        deleteCommand = "borra",
        commitCommand = "commit",
        cancelCommand = "cancel",
        showEditCommand,
        showDeleteCommand,
        showAddCommand,
        PopupEditing,
        DetailEditCell,
        DetailCell,
        ToggleCell,
        popupDialog,
        editComponent,
    }
) => {
    return (
        <Grid columns={columns} rows={rows} getRowId={getRowId}>
            <SortingState
                sorting={sorting}
                onSortingChange={(sorting) => onSortingChange(sorting)}
                columnExtensions={columnExtensions}
            />
            <PagingState
                currentPage={currentPage}
                pageSize={pageSize}
            />
            <SelectionState
                onSelectionChange={(rowsSelection) => onSelectionChange(rowsSelection)}
            />
            <DragDropProvider/>
            {editingProps && <EditingState
                {...editingProps}
            />}

            <Table
                tableComponent={(props) => <Table.Table {...props} padding={"none"}/>}
                cellComponent={cellComponent}
                noDataCellComponent={(props) => <Table.NoDataCell {...props} getMessage={() => noDataMessage}/>}
            />

            <ActionColumns actionColumns={actionColumns}/>

            <TableColumnReordering
                order={columnOrder}
                onOrderChange={(columnOrder) => onColumnOrderChange(columnOrder)}
            />
            <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={(columnWidths) => onColumnWidthsChange(columnWidths)}
            />
            <TableHeaderRow
                showSortingControls
                messages={{
                    sortingHint,
                }}
            />
            {editingProps && <TableEditRow/>}
            {editingProps && <TableEditColumn
                showAddCommand={showAddCommand}
                showEditCommand={showEditCommand}
                showDeleteCommand={showDeleteCommand}
                messages={{
                    addCommand,
                    editCommand,
                    deleteCommand,
                    commitCommand,
                    cancelCommand,
                }}
            />}
            <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={onHiddenColumnNamesChange}
                messages={{
                    noColumns: noColumnMessage,
                }}
            />

            {editComponent &&
                <TableRowDetail
                  contentComponent={editComponent}
                  cellComponent={DetailCell}
                  toggleCellComponent={ToggleCell}
                />}
            {editComponent && <DetailEditCell />}

            {inlineEdit && <TableInlineCellEditing
                startEditAction={"click"}
                selectTextOnEditStart={true}
            />}
            {popupDialog && <PopupEditing popupDialog={popupDialog} />}
        </Grid>
    );
};