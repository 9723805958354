import React, {useEffect, useLayoutEffect, useState} from 'react';
import {
    LatLon,
} from "./types";
import {observer} from "mobx-react";
import {cropImage} from "./satelliteImageUtils";
import {DrawLocations} from "./DrawLocations";
import PlayIcon from '@mui/icons-material/PlayArrow';
import NextIcon from '@mui/icons-material/SkipNext';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviousIcon from '@mui/icons-material/SkipPrevious';
import StopIcon from '@mui/icons-material/Pause';
import NextEventIcon from '@mui/icons-material/ArrowCircleDown';
import PreviousEventIcon from '@mui/icons-material/ArrowCircleUp';
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import Slider from '@mui/material/Slider';
import {useTheme} from "@mui/material/styles";
import {useRootStore} from "../../stores/contexts";
import {runInAction} from "mobx";

const marks = [{value: 1}, {value: 1.5}, {value: 2}, {value: 2.5}, {value: 3}, {value: 3.5}, {value: 4}];

export type ImagesDatesType = {
        year: string,
        month: string,
        day: string,
        hours: string,
        minutes: string,
        uri: string,
        nearest: boolean,
      }[];

type Props = {
    latLon: LatLon,
    dates: ImagesDatesType,
    imageIndex: number,
    setImageIndex: (imageIndex: number) => void,
    previousEvent: () => void;
    disabledPreviousEvent: boolean;
    nextEvent: () => void;
    disabledNextEvent: boolean;
    scale: number;
    playing: boolean;
    setPlaying: (value: boolean) => void;
    setScale: (scale: number) => void;
    disabledOk: boolean;
    disabledEdit: boolean;
    disabledDelete: boolean;
    doAction: (status: "ok" | "edit" | "delete") => void;
    canRemove: boolean;
};

const imageSample = "https://satelite.cptec.inpe.br/repositoriogoes/goes16/goes16_web/ams_ret_ch13_baixa/2022/07/S11635388_202207270240.jpg";
const imageSampleSec = "https://satelite.cptec.inpe.br/repositoriogoes/goes16/goes16_web/ams_ret_ch13_baixa/2022/07/S11635388_202207270240.jpg";

export const SatelliteImage: React.FC<Props> = observer(({
    latLon,
    dates,
    imageIndex,
    setImageIndex,
    previousEvent,
    disabledPreviousEvent,
    nextEvent,
    disabledNextEvent,
    scale,
    playing,
    setPlaying,
    setScale,
    disabledOk,
    disabledEdit,
    disabledDelete,
    doAction,
    canRemove,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [framesLoaded, setFramesLoaded] = useState<Record<number, boolean>>({});
    const [corsError, setCorsError] = useState(false);
    const crop = cropImage(latLon);
    const rootStore = useRootStore();
    const {
        langStore,
    } = rootStore;

    useLayoutEffect(() => {
        setFramesLoaded({});
    }, [dates]);

    const theme = useTheme();
    const boxesColor = `${theme.palette.primary.main}30`;

   useEffect(() => {
     fetch(imageSample)
       .then(data => {
         setCorsError(false);
       })
       .catch(err => {
         setCorsError(true);
       })
   }, [])

    useEffect(() => {
        let interval = setInterval(() => {
            if (playing && imageLoaded) {
                setImageLoaded(false);
                runInAction(() => {
                    nextFrame();
                })
            }
        }, 75)
        return () => clearInterval(interval);
    });

    const deferredScale = scale;

    const nextFrame = () => {
            if (imageIndex + 1 < dates.length) {
                setImageIndex(imageIndex + 1);
            } else {
                setImageIndex(0);
            }
    }

    const previousFrame = () => {
        if (imageIndex > 0) {
            setImageLoaded(false);
            setImageIndex(imageIndex - 1);
        }
    }

    return (
        <div>
            <div style={{position: "relative", overflow: "hidden"}}>
                <img src={dates[imageIndex].uri}
                     onLoad={() => {
                       setImageLoaded(true);
                       setCorsError(false);
                       setFramesLoaded({...framesLoaded, [imageIndex]: true})
                     }}
                     alt={imageIndex.toString()}
                     style={{
                       objectPosition: `-${crop.leftImage}px -${crop.topImage}px`,
                       objectFit: "none",
                       width: `${crop.widthImage}px`,
                       height: `${crop.heightImage}px`,
                       transform: `scale(${deferredScale},${deferredScale})`,
                     }}
                />
                <DrawLocations
                    points={[crop.point]}
                    xFactor={1}
                    yFactor={1}
                    height={crop.heightImage}
                    width={crop.widthImage}
                    radius={5}
                    color={"magenta"}
                />
                <Typography bgcolor={"white"} fontSize={12}
                            style={{paddingLeft: 3, position: "absolute", bottom: 5, left: 5}}>
                  {framesLoaded[imageIndex] ? "" : "loading..."}
                </Typography>
            </div>

            <Grid container spacing={0} direction={"row"}>
                <Grid item xs={12}>
                    <Slider
                            size="small"
                            aria-label="Zoom"
                            value={scale}
                            step={0.5}
                            marks={marks}
                            min={1}
                            max={4}
                            onChange={(event: Event, newValue: number | number[]) => {setScale(newValue as number)}}
                          />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{backgroundColor: boxesColor}}
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                >
                    <Typography sx={{pt: 0, marginBottom: 0}} fontWeight={dates[imageIndex].nearest ? "bold" : "normal"}  fontSize={16} >
                        {`${dates[imageIndex].year}-${dates[imageIndex].month}-${dates[imageIndex].day} ${dates[imageIndex].hours}:${dates[imageIndex].minutes} UTC`}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{backgroundColor: boxesColor}}
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                >
                    <Box sx={{marginTop: 0}}>
                        <IconButton disabled={playing || imageIndex <= 0} onClick={() => previousFrame()}>
                            <PreviousIcon/>
                        </IconButton>
                        <IconButton onClick={() => {setPlaying(!playing)}}>
                            {!playing && <PlayIcon/>}
                            {playing && <StopIcon/>}
                        </IconButton>
                        <IconButton disabled={playing || imageIndex + 1 >= dates.length} onClick={() => nextFrame()}>
                            <NextIcon/>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} sx={{marginTop: 3}}>
                          <Button
                              variant="contained" color="success" startIcon={<DoneIcon/>}
                              disabled={disabledOk}
                              onClick={() => doAction("ok")}
                          >
                            Ok
                          </Button>
                          <Button
                              variant="outlined" startIcon={<EditIcon/>}
                              disabled={disabledEdit}
                              onClick={() => doAction("edit")}
                          >
                              {langStore.modifyAction}
                          </Button>
                          {canRemove &&
                          <Button
                                  variant="contained" color="error" startIcon={<DeleteIcon/>}
                                  disabled={disabledDelete}
                                  onClick={() => doAction("delete")}
                              >
                                  {langStore.removeAction}
                          </Button>}
                    </Stack>
                </Grid>
                <Grid>
                    <Box sx={{marginTop: 2}}>
                        <Stack direction="row" spacing={1}>
                          <Button
                              variant="outlined" startIcon={<PreviousEventIcon/>}
                              disabled={disabledPreviousEvent}
                              onClick={() => previousEvent()}
                          >
                            {langStore.previousReport}
                          </Button>
                          <Button variant="outlined" startIcon={<NextEventIcon/>}
                                  disabled={disabledNextEvent}
                                  onClick={() => nextEvent()}>
                              {langStore.nextReport}
                          </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            {corsError && <Button target={"_blank"} href={imageSampleSec}>{langStore.saveCORSException}</Button>}
        </div>
    );
});
