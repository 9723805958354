import * as React from 'react';
import {observer} from "mobx-react";
import {Checkboxes} from "../../../../components";
import {RelatedOption} from "../../../../types";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";


export const EventImpacts = observer(() => {
  let impacts = [];
  const rootStore = useRootStore();
  const { langStore, impactTypeStore, impactStore, eventNewStore } = rootStore

  if (rootStore.impactStore.impactOptions !== null) {
    const impactRows =  impactStore.impactOptions;
    for (let i=0; i < impactTypeStore.ImpactTypes.length; i++) {
      const iType = impactTypeStore.ImpactTypes[i];
      const title = iType[langStore.fieldNameLang];
      const options = impactRows.filter((a: RelatedOption) => a.parentId === iType.id).map(x=> {return {...x, [langStore.labelNameLang]: x.id + ": " + x[langStore.labelNameLang]}});
      impacts.push(
        <EventNewCard key={i} title={title}>
          <Checkboxes
            key={i}
            options={options}
            getValue={(id: string) => eventNewStore.impacts[id]}
            onChange={(id: string, selected: boolean) => eventNewStore.onSelectImpact(id, selected)} />
        </EventNewCard>
      );
    }
    return <div>{impacts}</div>;
  } else {
    return <div>{rootStore.impactStore.status}</div>;
  }
});
