import {makeAutoObservable, runInAction} from 'mobx';
import {RootStore} from '../../stores';
import {WebPageStore, ReturnDataParams, StoreStatus} from "../../stores/types";
import {getApi} from "../../api/apiClient";
import {Event, EventFilter, Maybe} from "../../api/graphql";
import {ColumnWidthInfo, Sorting} from "../common/types";
import {mapFilter} from "./filterUtils";
import {excelExport} from "../../stores/excelUtils";
import {getApiError} from "../../api/apiErrors";
import VectorLayer from "ol/layer/Vector";
import {EventFeatureType, getFeatureCollection, getFeatures} from "./getFeatures";
import {EventGridStore} from "./EventGridStore";

export class EventListStore implements WebPageStore {
    readonly rootStore: RootStore;
    status: StoreStatus = "uninitialized";
    grid: EventGridStore;
    anchorMenu: null | HTMLElement = null;
    callbackUpdatedParams: any;
    storeId: string = "";
    pageSize = 500;
    idToDelete: string = "";
    deleteDialogOpen: boolean = false;
    rowsSelection: (string | number)[] = [];
    currentPage = 1;
    nextValueOfSortedColumn: string | null = null;
    lastSortedColumn: string | null= null;
    hasMorePages = true;
    where: EventFilter | null = null;
    events: Event[] = [];
    eventFeatures: EventFeatureType[] = [];
    reloadedEvent: Event | null = null;
    filter: any = null;
    errorDialogOpen: boolean = false;
    errorTitle: string = "Error";
    errorDescription: string = "Hubo un error";
    selectedEvent: Event | undefined = undefined;
    eventsForMap: any;
    lastChangedCount = 0;

    constructor(rootStore: RootStore, instanceId: string) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.storeId = instanceId;
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate()-90);
        lastWeek.setUTCHours(0, 0, 0, 0);

        this.grid = rootStore.eventGridStore;

        this.filter = [
            ["confidence", "<>",
                {id: "RAW",
                    label_en: "Raw entry", label_es: "Entrada cruda", label_pt: "Entrada bruta"}
            ],
            "and",
            ["confidence", "<>",
                {id: "REMOVED",
                    label_en:'Removed by trusted source', label_es: "Removido por fuente confiable", label_pt: "Removido por fonte confiável"}
            ],
            "and",
            ["eventTime", ">", lastWeek],
        ];
        this.where = mapFilter(this.filter);
    }

    destroy() {
    }

    setAnchorMenu(anchorMenu: null | HTMLElement) {
      this.anchorMenu = anchorMenu;
    }

    setStatus(status: StoreStatus) {
      this.status = status;
    }

    setCallbackUpdateParams(callbackUpdatedParams: any) {
      this.callbackUpdatedParams = callbackUpdatedParams;
    }

    async returnedData(params: ReturnDataParams) {
      const index = this.events.findIndex(x => x.id === params.id);
      if (index >= 0) {
          runInAction(() => {
            this.reloadEvent(index, params.id);
          })
      }
  }

  * reloadEvent(index: number, id: string): any {
      try {
          this.status = "processing";
          const api = getApi(this.rootStore.authStore);
          const data = yield api.GetEvent({id});
          if (this.events[index].id === id) {
              this.events[index] = data.eventOne as Event;
              this.reloadedEvent = this.events[index];
          }
          this.status = "ok";
      } catch (error) {
          if (error instanceof Error) {
              const apiError = getApiError(error, this.rootStore.langStore);
              let message = apiError.message;
              this.setErrorDialogOpen(true, apiError.title, message);
          } else {
              this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
          }
          this.status = "error";
      }
  }

    // Pagination

    setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    setHasMorePages(hasMorePages: boolean) {
        this.hasMorePages = hasMorePages
    }

    setFirstPage(rows: Event[]) {
        this.events = rows;
        this.eventFeatures = getFeatures(rows);
        this.setEventsForMap();
    }

    setEventsForMap() {
      this.eventsForMap = getFeatureCollection(this.eventFeatures);
    }

    selectFeature(id: string) {
      this.selectedEvent = this.events.find(x => x.id === id);
    }

    appendPage(newRows: Event[]) {
      if (newRows.length > 0) {
          this.events = this.events.concat(newRows);
          this.eventFeatures.push(...getFeatures(newRows));
          this.setEventsForMap();
      }
    }

    * loadFirstPage(): any {
      this.status = "processing";
      try {
        this.nextValueOfSortedColumn = null;
        this.lastSortedColumn = null;
        this.currentPage = 1;
        const rows = yield this.loadData(1);
        this.setFirstPage(rows);
        this.onPageChange(1);
        this.status = "ok";
      } catch (error) {
          if (error instanceof Error) {
              const apiError = getApiError(error, this.rootStore.langStore);
              let message = apiError.message;
              this.setErrorDialogOpen(true, apiError.title, message);
          } else {
              this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
          }
          this.status = "error";
      }
    }

    * getNextPage(): any {
      this.status = "processing";
      try {
        const newRows = yield this.loadData(this.currentPage + 1);
        this.appendPage(newRows);
        this.status = "ok";
      } catch (error) {
          if (error instanceof Error) {
              const apiError = getApiError(error, this.rootStore.langStore);
              let message = apiError.message;
              this.setErrorDialogOpen(true, apiError.title, message);
          } else {
              this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
          }
          this.status = "error";
      }
    }

    * onPageChange(newPageNumber: number) {
        if (newPageNumber > this.currentPage && this.hasMorePages) {
            if (newPageNumber * this.pageSize > this.events.length) {
                yield this.getNextPage();
            }
        }
        this.currentPage = newPageNumber;
    }

    * onPageSizeChange(pageSize: number) {
        if (this.pageSize !== pageSize) {
            this.pageSize = pageSize;
            yield this.loadFirstPage()
        }
    }

    get eventsOfCurrentPage() {
        const start = (this.currentPage-1) * this.pageSize;
        return this.events.slice(start, start + this.pageSize);
    }

    setLastFoundKey(keyName: "id") {
        if (this.lastSortedColumn !== keyName) {
            this.lastSortedColumn = keyName;
            this.nextValueOfSortedColumn = null;
        }
    }

    // Filtering

    setFilterValue(filter: any): any {
        if (JSON.stringify(filter) !== JSON.stringify(this.filter)) {
            this.filter = filter;
        }
    }

    * applyFilter() {
        this.where = mapFilter(this.filter);
        yield this.loadFirstPage();
    }

    // Grid

    get columns() {
        return [{name: "__action_view", title: ""}, {name: "__action_edit", title: ""}, ...this.grid.columns];
    }

    get hiddenColumns() {
        return this.grid.hiddenColumns;
    }

    get columnOrder() {
        return this.grid.columnOrder;
    }

    get columnExtensions() {
        return this.grid.columnExtensions;
    }

    get sorting() {
        return this.grid.sorting;
    }

    get columnWidths() {
        return this.grid.columnWidths;
    }

    onHiddenColumnChange(names: string[]) {
        this.grid.setHiddenColumns(names);
    }

    onColumnOrderChange(columnOrder: string[]) {
        this.grid.setColumnOrder(columnOrder);
    }

    onColumnWidthsChange(columnWidths: ColumnWidthInfo[]) {
        this.grid.setColumnWidths(columnWidths);
    }

    buildWhere(): Maybe<EventFilter> {
        return this.where;
    }

    setFeaturesLayer(featureLayer: VectorLayer<any>) {

    }

    * onSortingChange(sorting: Sorting[]) {
        this.grid.setSorting(sorting);
        this.setLastFoundKey(this.sorting[0].columnName as "id");
        yield this.loadFirstPage();
    }

    onSelectionChange(rowsSelection: (string | number)[]) {
        this.rowsSelection = rowsSelection;
    }

    * saveProfile() {
        yield this.rootStore.authStore.saveListProfile(this.grid.gridState);
    }

    * toExcel() {
        yield excelExport(this.grid.xlsColumns, this.events.map(e => this.grid.eventFormat(e)), "events.xls", "Events");
    }

    editRow(id: string) {
      this.rootStore.breadcrumbsStore.push(`/event/update`, false,{
          id,
          storeId: this.rootStore.getStoreId(),
          routeName: "/event/list",
      });
    }

    * viewRow(id: string) {
        yield this.rootStore.breadcrumbsStore.push(`/event/view`, false, {id})
    }

    deleteRow(id: string) {
        this.idToDelete = id;
        this.deleteDialogOpen = true;
    }

    // Api callings

    get processingTitle() {
        return this.rootStore.langStore.processingTitle;
    }

    get processingDescription() {
        return this.rootStore.langStore.processingDescription;
    }

    setErrorDialogOpen(value: boolean, title?: string, description?: string) {
        if (title) {
            this.errorTitle = title;
        }
        if (description) {
            this.errorDescription = description;
        }
        this.errorDialogOpen = value;
    }

    * loadData(pageNumber: number): any {
        const api = getApi(this.rootStore.authStore);
        const data = yield api.EventList({
            where: this.buildWhere(),
            pageSize: this.pageSize,
            pageNumber,
            orderBy: this.grid.OrderBy,
            nextValueOfSortedColumn: this.nextValueOfSortedColumn,
            lastSortedColumn: this.lastSortedColumn,
        });

        this.lastSortedColumn = Object.keys(this.grid.OrderBy)[0];
        if (this.lastSortedColumn && data.eventList.length > 0) {
            this.nextValueOfSortedColumn = data.eventList[data.eventList.length - 1][this.lastSortedColumn];
        } else {
            this.nextValueOfSortedColumn = null;
        }
        const rows = data.eventList as Event[];

        if (rows.length > this.pageSize) {
            this.setHasMorePages(true);
            rows.pop();
        } else {
            this.setHasMorePages(false);
        }
        return rows
    }

    * initialize(): any {
        try {
            this.status = "processing";
            const {eventTypeStore, impactTypeStore, impactStore, confidenceStore, informationSourceStore, stateStore} = this.rootStore;
            yield confidenceStore.initialize();
            yield impactTypeStore.initialize();
            yield informationSourceStore.initialize();
            yield eventTypeStore.initialize();
            yield impactStore.initialize();
            yield stateStore.initialize();

            if (this.rootStore.authStore.me?.profile?.listProfile) {
                this.grid.setGridState(this.rootStore.authStore.me.profile.listProfile);
            }

            yield this.loadFirstPage();

            this.status = "ok";
        } catch (error) {
            this.status = "error";
            const message = (error instanceof Error) ? error.message : undefined;
            this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
        }
    }

  onCloseErrorDialog(): void {
      this.errorDialogOpen = false;
  }
}