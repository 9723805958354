import {makeAutoObservable, observe} from 'mobx';
import { RootStore } from "../stores";
import {getApi, login} from "../api/apiClient";
import {ListProfile, User} from "../api/graphql";
import {RouteNameType} from "../stores/RoutesStore";
import {flatten} from "lodash";
import * as _ from "lodash";
import { AuthStoreInterface } from "./AuthStoreInterface";

const authErrors =  ["emailAndPasswordRequiredError", "badEmailOrPasswordError"] as const;
type errorKeys =  typeof authErrors[number];


export class AuthStore implements AuthStoreInterface {
  private readonly rootStore: RootStore | null;
  token: string | null = null;
  status: "anonymous" | "logged" = "anonymous";
  errorKey: errorKeys | null = null;
  email = "";
  password = "";
  newPassword = "";
  secondPassword = "";
  showPassword = false;
  errorMessage = "";
  me: User | null = null;
  logInRedirect: RouteNameType = "/";
  permissions: Set<string> = new Set();

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    const self = this;
    authErrors.forEach((err) => {
      observe(rootStore.langStore, err, change => {
        if (self.errorKey !== null && self.errorKey === err) {
          self.setErrorMessage(change.newValue as string);
        }
      })});
  }

  hasPermissions(...permissions: string[]): boolean {
    for (let perm of permissions) {
      if (!this.permissions.has(perm)) {
        return false;
      }
    }
    return true;
  }

  * saveListProfile(profile: ListProfile) {
    if (this.me) {
      if (!this.me.profile) {
        this.me.profile = {listProfile: undefined, reviewProfile: undefined};
      }
      this.me.profile.listProfile = profile;
      const api = getApi(this);
      yield api.UpdateProfile({profile: this.me.profile});
    }
  }

  * saveReviewProfile(profile: ListProfile) {
    if (this.me) {
      if (!this.me.profile) {
        this.me.profile = {listProfile: undefined, reviewProfile: undefined};
      }
      this.me.profile.reviewProfile = profile;
      const api = getApi(this);
      yield api.UpdateProfile({profile: this.me.profile});
    }
  }

  *saveProfile(key: string[], value: any): any {
    const api = getApi(this);
    console.log("save", key, value)
    const result = yield api.saveProfile({ key, value });
    console.log("Result", result);
  }

  getProfile(key: string[]) {
    if (this.me && this.me.profile) {
      return _.get(this.me.profile, key.join("."));
    }
    return undefined;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  setToken(token: string | null) {
    this.token = token;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setPassword(password: string) {
    this.password = password;
  }

  setNewPassword(password: string) {
    this.newPassword = password;
  }

  setSecondPassword(password: string) {
    this.secondPassword = password;
  }

  get passwordsAreOk() {
    return this.secondPassword === this.newPassword && this.newPassword.length >= 4;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  private setAnonymous() {
    this.status = "anonymous";
    this.errorMessage = "";
    this.errorKey = null;
    this.token = null;
  }

  private setErrorKey(errorKey: errorKeys) {
    if (this.rootStore !== null) {
      this.errorKey = errorKey;
      const lang = this.rootStore.langStore;
      if (lang !== null && this.errorKey in lang) {
        this.errorMessage = lang[this.errorKey as keyof typeof lang] as string;
      }
    }
  }

  private setMe(me: User) {
    this.me = me;
    if (me.organizations[0].country.id === "BR") {
      this.rootStore?.langStore.setLanguage('pt');
    } else {
      this.rootStore?.langStore.setLanguage('es');
    }
    const permissionsPerRole = me.roles.map((role) => role.permissions ? role.permissions.map(x=>x.id) : []);
    this.permissions = new Set(flatten(permissionsPerRole));
  }

  private async getMe() {
      const api = getApi(this);
      const data = await api.Me();
      this.setMe(data.me as User);
  }

  logout() {
    this.setAnonymous();
  }

  // setLogInRedirect = (routerState: RouterState) => {
  //   // this.logInRedirect = routerState.routeName;
  // };

  setLogIn = (redirectPath: RouteNameType) => {
    this.logInRedirect = redirectPath;
  };

  * login() {
    if (this.email !== "" && this.password !== "") {
      this.setAnonymous();
      try {
        yield login(this);
        if (this.token !== null) {
          this.email = "";
          this.password = "";
          yield this.getMe();
          this.status = "logged";
          if (this.rootStore !== null) {
            yield this.rootStore.breadcrumbsStore.push(this.logInRedirect);
          }
        } else {
          this.setErrorKey("badEmailOrPasswordError");
        }
      } catch (error) {
          this.setErrorKey("badEmailOrPasswordError");
      }
    } else {
      this.setErrorKey("emailAndPasswordRequiredError");
    }
  }

  * changePassword(): any {
    if (this.password !== "" && this.newPassword !== "" && this.secondPassword !== "" && this.passwordsAreOk) {
      const api = getApi(this);
      const data = yield api.ChangePassword({oldPassword: this.password, newPassword: this.newPassword});
      if (data.userChangePassword.id !== this.me?.id) {
        throw new Error("Error in password change");
      }
    }
  }
}