import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {
    TextareaAutosize,
} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventDetailedLocation = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
      <EventNewCard title={langStore.detailedLocationTitle} description={langStore.detailedLocationDescription}>
            <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={langStore.detailedLocationLabel}
                  value={eventNewStore.detailedLocation}
                  onChange={(event) => eventNewStore.onChangeDetailedLocation(event.target.value)}
                  style={{ width: 500, height:100, margin: 5 }}
            />
      </EventNewCard>
  );
});
