/**
 * @author Fido García <garciafido@gmail.com>
 */
import React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export type RoleMiniProps = {
  editedRow?: any;
}

export const RoleMiniComponent: React.FC<RoleMiniProps> = observer(({
  editedRow,
}) => {
  const rootStore = useRootStore();
  const {
    roleStore,
    userOneStore,
    langStore
  } = rootStore;

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{langStore.labelRoles}</InputLabel>
      <Select
        labelId="role-simple-select-label"
        id="role-simple-select"
        value={userOneStore.selectedRoleId}
        label={langStore.labelRoles}
        onChange={(event) => userOneStore.setSelectedRoleId(event.target.value)}
      >
        {
        roleStore.roleList.map(x=>{
          return (<MenuItem key={x.id} value={x.id}>{x[langStore.fieldNameLang]}</MenuItem>);
        })
        }
      </Select>
    </FormControl>
  );
});