import React from "react";
import {observer} from "mobx-react";
import {CancelButton, SaveButton} from "./buttons";
import {Grid} from "@mui/material";

type Props = {
    save?: () => void,
    cancel?: () => void,
}

export const EditToolbox: React.FC<Props> = observer(({
    save,
    cancel,
}) => {
    let saveFormat;
    if (save) {
        saveFormat = <SaveButton onClick={() => save()}/>
    }

    let cancelFormat;
    if (cancel) {
        cancelFormat = <CancelButton onClick={() => cancel()}/>
    }

    return (
        <Grid container>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {saveFormat}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {cancelFormat}
            </Grid>
        </Grid>
    );
});