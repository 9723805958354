import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {Impact} from "../../api/graphql";
import {RelatedOption} from "../../types";
import {StoreStatus} from "../../stores/types";

export class ImpactStore {
  private rootStore: RootStore;
  private impactAll: Impact[] | null = null;
  status: StoreStatus = "uninitialized";


  // Coordenadas desde google maps
  // https://www.tutsmake.com/get-latitude-and-longitude-from-address-google-geocode-api-jquery-javascript/

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get impactOptions(): RelatedOption[] {
    if (this.impactAll !== null) {
      return this.impactAll
          .map((x) => {
            return {id: x.id, label_es: x.name_es, label_en: x.name_en, label_pt: x.name_pt, parentId: x.impactType.id};
          })
          .sort((a: RelatedOption, b: RelatedOption) => (parseInt(a.id.substr(1)) > parseInt(b.id.substr(1))) ? 1 : -1);
    }
    return [];
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";
        let data;
        const api = getApi(this.rootStore.authStore);
        data = yield api.Impacts();
        this.impactAll = data.impactAll;
        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }
}