/**
 * @author Fido García <garciafido@gmail.com>
 */
import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {ColumnWidthInfo, Sorting} from "../common/types";
import {UserFilter, User, SortOrder} from "../../api/graphql";
import {ReturnDataParams, StoreStatus} from "../../stores/types";
import {ColumnInfo} from "../event/EventGridStore";
import {ChangeSet, TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {LangStore} from "../../stores/LangStore";
import {excelExport} from "../../stores/excelUtils";
import {reloadOneEntityOnList} from "../common/services/listStoreServices";
import {userService} from "./services/userService";
import {ListEntityStore} from "../common/types";

export class UserListStore implements ListEntityStore<User> {
  private uniqueCounter = 1;
  dummyRestoredObservable: number = 0;
  rootStore: RootStore;
  status: StoreStatus = "uninitialized";
  service = userService;
  callbackUpdatedParams: any;
  entityList: User[] = [];
  reloadedUser: User | null = null;
  storeId: string = "";
  pageSize = 5;
  currentPage = 1;
  nextValueOfSortedColumn: string | null = null;
  lastSortedColumn: string | null = null;
  hasMorePages = true;
  where: UserFilter | null = null;
  sorting: Sorting[] = [{columnName: "email", direction: SortOrder.Asc}];
  rowsSelection: (string | number)[] = [];
  sortExpression: Record<string, (direction: SortOrder, lang: LangStore) => any> = {
    "name": (direction: SortOrder) => {return {name: direction}},
    "email": (direction: SortOrder) => {return {email: direction}},
  }
  columnsInfo: ColumnInfo = {
    "name": {sortingEnabled: true, width: 250, isHidden: false,},
    "email": {sortingEnabled: true, width: 250, isHidden: false},
    "active": {sortingEnabled: true, width: 250, isHidden: false},
    "organizations": {sortingEnabled: false, width: 250, isHidden: false},
    "roles": {sortingEnabled: false, width: 250, isHidden: true},
    "id": {sortingEnabled: true, width: 260, isHidden: true,},
  }
  columnOrder = [
    "name",
    "email",
    "active",
    "organizations",
    "roles",
    "id",
  ];
  errorDialogOpen: boolean = false;
  errorDescription: string = "Hubo un error";
  errorTitle: string = "";
  originalError: any;
  resetPasswordDialogOpen: boolean = false;
  passwordToResetId: string = "";
  anchorMenu: null | HTMLElement = null;

  constructor(rootStore: RootStore, instanceId: string) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.storeId = instanceId;
  }

  destroy() {
  }

  setStatus(status: StoreStatus) {
    this.status = status;
  }

  setAnchorMenu(anchorMenu: null | HTMLElement) {
    this.anchorMenu = anchorMenu;
  }

  async returnedData(params: ReturnDataParams) {
      const index = this.entityList.findIndex(x => x.id === params.id);
      if (index >= 0) {
          await reloadOneEntityOnList<User>(this, index, params.id);
      }
  }

  setCallbackUpdateParams(callbackUpdatedParams: any) {
    this.callbackUpdatedParams = callbackUpdatedParams;
  }

  setEntity(user: User, index: number) {
    this.entityList[index] = user;
    this.reloadedUser = user;
  }

  * onCommitChanges(changes: ChangeSet) {
    if (changes.changed || changes.added) {
      const store = this.rootStore.userOneStore;
      yield store.save();
      if (store.status === "error") {
        this.setErrorDialogOpen(true, store.originalError, store.errorTitle, store.errorDescription);
      } else {
        if (store.entity) {
          const id = store.entity.id;
          const index = this.entityList.findIndex(x => x.id === id);
          if (index >= 0) {
              yield reloadOneEntityOnList<User>(this, index, id);
          }
        }
      }
    }
  }

  // ------------------------------- Custom --->

  get usersOfCurrentPage() {
      const start = (this.currentPage-1) * this.pageSize;
      return this.entityList.slice(start, start + this.pageSize);
  }

  get columnExtensions() {
    return Object.keys(this.columnsInfo).map(key => {
      return {columnName: key, sortingEnabled: this.columnsInfo[key].sortingEnabled}
    });
  }

  get columnWidths(): TableColumnWidthInfo[] {
    return Object.keys(this.columnsInfo).map(key => {
      return {columnName: key, width: this.columnsInfo[key].width}
    });
  }

  get hiddenColumns(): string[] {
      return Object.keys(this.columnsInfo).filter(key => this.columnsInfo[key].isHidden);
  }

  get columnTitles(): Record<string, string> {
    return {
      "name": this.rootStore.langStore.labelForName,
      "email": "Email",
      "active": this.rootStore.langStore.isUserActiveLabel,
      "organizations": this.rootStore.langStore.labelOrganizations,
      "roles": this.rootStore.langStore.labelRoles,
      "id": "ID",
    };
  }

  get columns() {
    return [
      {name: "__action_edit", title: ""},
      ...Object.entries(this.columnTitles).map(([key, title])=>{return {name: key, title: title}}),
      {name: "__action_reset_password", title: ""},
    ];
  }

  userFormat(user: User) {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active,
      organizations: user.organizations?.map((x) => {
        return x.id
      }),
      roles: user.roles?.map((x) => {
        return x[this.rootStore.langStore.fieldNameLang]
      }),
    }
  }

  editRow(id: string) {
    this.rootStore.breadcrumbsStore.push(`/user/update`, false, {
      id,
      storeId: this.rootStore.getStoreId(),
      routeName: "/user/list",
    });
  }

  cancelResetPassword() {
    this.resetPasswordDialogOpen = false;
  }

  setResetPasswordId(id: string) {
    this.passwordToResetId = id;
    this.resetPasswordDialogOpen = true;
  }

  * resetPassword(): any {
      try {
        this.status = "processing";
        const api = getApi(this.rootStore.authStore);
        const data = yield api.UserResetPassword({id: this.passwordToResetId});
        this.passwordToResetId = "";
        this.resetPasswordDialogOpen = false;
        this.status = "ok";
      } catch(error) {
        this.status = "error";
      }
  }

  createNewUser() {
    this.rootStore.breadcrumbsStore.push(`/user/new`, false, {
      storeId: this.rootStore.getStoreId(),
      routeName: "/user/list",
    });
  }

  * viewRow(id: string) {
    yield this.rootStore.breadcrumbsStore.push(`/user/view`, false, {id})
  }

  onColumnOrderChange(columnOrder: string[]) {
     this.columnOrder = columnOrder;
  }

  get OrderBy() {
      let orderBy = [];
      for (const s of this.sorting) {
          const sortExpression = this.sortExpression[s.columnName];
          if (sortExpression) {
              const ob = sortExpression(s.direction === "desc" ? SortOrder.Desc : SortOrder.Asc, this.rootStore.langStore);
              orderBy.push(ob);
          } else {
              orderBy.push({[s.columnName]: s.direction === "desc" ? SortOrder.Desc : SortOrder.Asc});
          }
      }
      return orderBy;
  }

  // Pagination

  setPageSize(pageSize: number) {
      this.pageSize = pageSize;
  }

  setHasMorePages(hasMorePages: boolean) {
      this.hasMorePages = hasMorePages
  }

  setFirstPage(rows: User[]) {
    this.entityList = rows;
  }

  appendPage(newRows: User[]) {
      if (newRows.length > 0) {
          this.entityList = this.entityList.concat(newRows);
      }
  }

  * loadFirstPage(): any {
    this.nextValueOfSortedColumn = null;
    this.lastSortedColumn = null;
    this.currentPage = 1;
    const rows = yield this.loadData(1);
    this.setFirstPage(rows);
    this.onPageChange(1);
  }

  * getNextPage(): any {
      const newRows = yield this.loadData(this.currentPage + 1);
      this.appendPage(newRows);
  }

  * onPageChange(newPageNumber: number) {
      if (newPageNumber > this.currentPage && this.hasMorePages) {
          if (newPageNumber * this.pageSize > this.entityList.length) {
              yield this.getNextPage();
          }
      }
      this.currentPage = newPageNumber;
  }

  * onPageSizeChange(pageSize: number) {
      if (this.pageSize !== pageSize) {
          this.pageSize = pageSize;
          yield this.loadFirstPage()
      }
  }

  viewUser(id: string) {
    this.rootStore.breadcrumbsStore.push(`/user/view`, false, {id})
  }

  setLastFoundKey(keyName: "id") {
      if (this.lastSortedColumn !== keyName) {
          this.lastSortedColumn = keyName;
          this.nextValueOfSortedColumn = null;
      }
  }

  * onSortingChange(sorting: Sorting[]) {
      this.sorting = sorting;
      this.setLastFoundKey(this.sorting[0].columnName as "id");
      yield this.loadFirstPage();
  }

  onSelectionChange(rowsSelection: (string | number)[]) {
      this.rowsSelection = rowsSelection;
  }

  setHiddenColumns(hiddenColumns: string[]) {
      for (const key of Object.keys(this.columnsInfo)) {
          this.columnsInfo[key].isHidden = !!hiddenColumns.find(h => h === key);
      }
  };

  onHiddenColumnChange(names: string[]) {
      this.setHiddenColumns(names);
  }

  setColumnWidths(columnWidths: TableColumnWidthInfo[]) {
      for (const cw of columnWidths) {
          this.columnsInfo[cw.columnName].width = Number(cw.width);
      }
  }

  onColumnWidthsChange(columnWidths: ColumnWidthInfo[]) {
      this.setColumnWidths(columnWidths);
  }

  buildWhere() {
    return undefined;
  }

  setErrorDialogOpen(value: boolean, originalError?: any, title?: string, description?: string) {
    this.originalError = originalError;
    if (title) {
      this.errorTitle = title;
    }
    if (description) {
      this.errorDescription = description;
    }
    this.errorDialogOpen = value;
  }

  get processingTitle() {
      return this.rootStore.langStore.processingTitle;
  }

  get processingDescription() {
      return this.rootStore.langStore.processingDescription;
  }

  get xlsColumns(): {header: string, key: string, width: number}[] {
      const columns = [];
      for (const column of this.columnOrder) {
          if (!this.columnsInfo[column].isHidden) {
              const width = this.columnsInfo[column].width / 10 * 1.33;
              columns.push({
                  header: this.columnTitles[column],
                  key: column,
                  width,
              });
          }
      }
      return columns;
  }

  get columnChooserInfo(): {name: string, isHidden: boolean, label: string}[] {
      const result = [];
      for (const key of this.columnOrder) {
          result.push({name: key, isHidden: this.columnsInfo[key].isHidden, label: this.columnTitles[key]});
      }
      return result;
  }

  toggleColumnHidden(name: string) {
      this.columnsInfo[name].isHidden = !this.columnsInfo[name].isHidden;
  }

  * toExcel() {
    const excelFormat = (user: any) => {
      return {...user, roles: user.roles.join("\n"), organizations: user.organizations.join("\n")}
    }
    yield excelExport(this.xlsColumns, this.entityList.map(x => excelFormat(this.userFormat(x))), "users.xls", "Users");
  }

  * loadData(pageNumber: number): any {
    const api = getApi(this.rootStore.authStore);
    const data = yield api.UserList({
      where: this.buildWhere(),
      pageSize: this.pageSize,
      pageNumber,
      orderBy: this.OrderBy,
      nextValueOfSortedColumn: this.nextValueOfSortedColumn,
      lastSortedColumn: this.lastSortedColumn,
    });

    this.lastSortedColumn = Object.keys(this.OrderBy)[0];
    if (this.lastSortedColumn && data.userList.length > 0) {
      this.nextValueOfSortedColumn = data.userList[data.userList.length - 1][this.lastSortedColumn];
    } else {
      this.nextValueOfSortedColumn = null;
    }
    const rows = data.userList as User[];

    if (rows.length > this.pageSize) {
      this.setHasMorePages(true);
      rows.pop();
    } else {
      this.setHasMorePages(false);
    }
    return rows;
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";

        yield this.loadFirstPage();

        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }

  onCloseErrorDialog(): void {
    this.errorDialogOpen = false;
  }
}