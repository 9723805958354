import * as React from 'react';
import {
    MenuItem, Select, Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";

export const EventSpatialPrecision = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  const options = langStore.spatialPrecision.map((option, index) => {
      return (
        <MenuItem key={index} value={option.meters}>
            <Typography sx={{m:1, display: "inline"}}>{option.label}</Typography>
        </MenuItem>
      );
  });

  return (
      <EventNewCard title={langStore.spatialPrecisionTitle}
                    description={langStore.spatialPrecisionDescription}>
        <Select
          id="drop-down-precision"
          value={eventNewStore.spacialPrecisionMeters}
          onChange={
              (event) =>
                  eventNewStore.onSelectSpatialPrecision(event.target.value)}
          sx={{ m:1, width: 500 }}
        >
            {options}
        </Select>
      </EventNewCard>
  );
});
