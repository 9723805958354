import {ElevationScroll} from "./ElevationScroll";
import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import {Box, Breadcrumbs, Link, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {useResizeDetector} from "react-resize-detector";
import {getAppBar} from "./appBar";
import {useRootStore} from "../../stores/contexts";
import {useTheme} from "@mui/material/styles";

type Props = {
    drawerWidth: number,
    breadcrumbsBarTop: number,
    closedDrawerWidth: number,
    sideBarOpen: boolean,
    updateHeight: (size: number | undefined) => void,
}

export const BreadcrumbsBar: React.FC<Props> = observer(({
    drawerWidth,
    sideBarOpen,
    breadcrumbsBarTop,
    closedDrawerWidth,
    updateHeight,
}) => {
    const [barHeight, setBarHeight] = useState<number|undefined>(undefined);

    useLayoutEffect(() => {
        if (barHeight) {
            updateHeight(barHeight);
        }
    }, [barHeight, updateHeight]);

    const theme = useTheme();
    const bgColor = `${theme.palette.primary.light}`;
    const fgColor = `${theme.palette.primary.contrastText}`;

    const onResize = useCallback((width: number | undefined, height: number | undefined) => {
      if (height !== barHeight) {
        setBarHeight(height);
      }
    }, [barHeight]);

    const barRef = useRef<HTMLDivElement | null>(null);
    useResizeDetector({
        targetRef: barRef,
        // setParentState can't be called here as parent is still rendering
        onResize,
        refreshMode: "debounce",
        handleWidth: false,
        refreshRate: 100,
        refreshOptions: { trailing: true, leading: false },
    });

    const rootStore = useRootStore();
    const {breadcrumbsStore} = rootStore;
    const breadcrumbs = breadcrumbsStore.getBreadcrumbs();
    if (breadcrumbs.length === 0) {
        return <div/>;
    }

    const currentHistoryPosition = breadcrumbs.findIndex(x => x.isCurrentPage);
    const buttons = breadcrumbs.map((x, index) => {
        const go = currentHistoryPosition - index;
        if (x.isCurrentPage) {
            return <Typography variant={"h6"}  key={index} color={fgColor}>
                {x.label}
            </Typography>;
        } else {
            return <Link
                key={index}
                underline={"hover"}
                color={"inherit"}
                onClick={() => breadcrumbsStore.goHistory(go)}>
                <div style={{marginTop: 0.7}}>{x.label}</div>
            </Link>;
        }
    });

    const AppBar = getAppBar(closedDrawerWidth, drawerWidth);

    return (
      <ElevationScroll>
        <AppBar
            ref={barRef}
            style={{marginTop: breadcrumbsBarTop, backgroundColor: bgColor}}
            position="fixed"
            open={sideBarOpen}
            color={"default"}
        >
            <Box sx={{pl: 3}}>
                <Breadcrumbs maxItems={8} separator="›" aria-label="breadcrumb">
                    {buttons}
                </Breadcrumbs>
            </Box>
        </AppBar>
      </ElevationScroll>
    );
});