import * as React from 'react';

import {observer} from "mobx-react";
import {useRootStore} from "../../../stores/contexts";
import 'devextreme/dist/css/dx.light.css';
import "./FilteredEventList.css";
import FilterBuilder1, { IFieldProps } from 'devextreme-react/filter-builder';
import {Button} from "@mui/material";
import {groupOperations} from "../filterUtils";
import FilterIcon from "@mui/icons-material/FilterAlt";

const FilterBuilder: any = FilterBuilder1;

type Props = {
    setFilterValue: (filter: any) => void,
    applyFilter: () => void,
    filter: any,
};

export const EventFilter: React.FC<Props> = observer(({
    setFilterValue,
    applyFilter,
    filter,
}) => {
    const rootStore = useRootStore();
    const {
        langStore,
        confidenceStore,
        informationSourceStore,
        impactStore,
        eventTypeStore,
        impactTypeStore,
        prefStore,
        eventReviewStore,
    } = rootStore;

    const booleanOperations = {filterOperations: ['=']};
    const lookupOperations = {filterOperations: ['=', '<>']};
    const lookupManyOperations = {filterOperations: ['=', '<>', 'isblank', 'isnotblank']};
    const numberOperations = {filterOperations: ['=', '<>', '<', '<=', '>', '>=', 'between']};
    const dateOperations = {filterOperations: ['=', '<>', '<', '<=', '>', '>=', 'between']};
    const stringOperations = {filterOperations: ['contains', 'endswith', 'notcontains', 'startswith', '=']};

    const columnFilter: IFieldProps[] = [
        {dataField: "email", dataType: "string", ...stringOperations, caption: "Email"},
        {dataField: "name", dataType: "string", ...stringOperations, caption: "Reporter"},
        {dataField: "confidence", dataType: "string", ...lookupOperations, caption: langStore.qualityControlLabel,
            lookup: {
                dataSource: confidenceStore.confidenceOptions,
                displayExpr: (x: any) => `${x.id}: ${x[langStore.labelNameLang]}`,
            }
        },
        {dataField: "source", dataType: 'string', ...lookupOperations, caption: langStore.sourceInformationLabel,
            lookup: {
                dataSource: informationSourceStore.informationSourceOptions,
                displayExpr: (x: any) => `${x.id}: ${x[langStore.labelNameLang]}`
            }
        },
        {dataField: "hasMedia", dataType: 'boolean',...booleanOperations, caption: langStore.eventHasMediaLabel},
        {dataField: "references", dataType: 'string', ...stringOperations, caption: langStore.eventReferencesLabel},

        {dataField: "country", dataType: 'string', ...stringOperations, caption: langStore.countryLabel},
        {dataField: "state", dataType: 'string', ...stringOperations, caption: langStore.allStateLabel},
        {dataField: "city", dataType: 'string', ...stringOperations, caption: langStore.cityLabel},
        {dataField: "location", dataType: 'string', ...stringOperations, caption: langStore.eventLocationLabel},
        {dataField: "detailedLocation", dataType: 'string', ...stringOperations, caption: langStore.detailedLocationLabel},
        {dataField: "latitude", dataType: 'number', ...numberOperations, caption: langStore.eventLatitudeLabel},
        {dataField: "longitude", dataType: 'number', ...numberOperations, caption: langStore.eventLongitudeLabel},
        {dataField: "spacialPrecisionMeters", dataType: 'number', ...numberOperations, caption: langStore.spatialPrecisionMetersLabel},

        {dataField: "eventTime", dataType: 'datetime', ...dateOperations, caption: langStore.eventTimeLabel},
        {dataField: "temporalPrecisionSeg", dataType: 'number', ...numberOperations, caption: langStore.temporalPrecisionLabel},
        {dataField: "totalDurationSeg", dataType: 'number', ...numberOperations, caption: langStore.eventDurationLabel},

        {dataField: "eventTypes", dataType: 'string', ...lookupOperations, caption: langStore.eventTypeTitle,
            lookup: {dataSource: eventTypeStore.eventTypeOptions, displayExpr: (x: any) => `${x.id}: ${x[langStore.labelNameLang]}`}
        },
        {dataField: "hailMaxDiameterMM", dataType: 'number', ...numberOperations, caption: langStore.eventHailDiameterMMLabel},
        {dataField: "windSpeedKmH", dataType: 'number', ...numberOperations, caption: langStore.eventWindSpeedLabel},
        {dataField: "windSpeedMaxKmH", dataType: 'number', ...numberOperations, caption: langStore.eventWindMaxSpeedLabel},
        {dataField: "windDirectionDegrees", dataType: 'number', ...numberOperations, caption: langStore.eventWindDirectionLabel},
        {dataField: "precipitationAccumMM", dataType: 'number', ...numberOperations, caption: langStore.spatialPrecisionLabel},
        {dataField: "snowAccumMM", dataType: 'number', ...numberOperations, caption: langStore.eventSnowAccumMMLabel},

        {dataField: "impactTypes", dataType: 'string', ...lookupOperations, caption: langStore.eventImpactTypeLabel,
            lookup: {dataSource: impactTypeStore.ImpactTypes, displayExpr: (x: any) => `${x.id}: ${x[langStore.fieldNameLang]}`}
        },
        {dataField: "impacts", dataType: 'string', ...lookupManyOperations, caption: langStore.eventImpactLabel,
            lookup: {dataSource: impactStore.impactOptions, displayExpr: (x: any) => `${x.id}: ${x[langStore.labelNameLang]}`}
        },
        {dataField: "description", dataType: 'string', ...stringOperations, caption: langStore.descriptionLabel},
        {dataField: "id", dataType: "string", ...stringOperations, caption: "ID"},
    ];

    return (
        <div>
            <div>
                <FilterBuilder
                    fields={columnFilter}
                    maxGroupLevel={3}
                    groupOperations={groupOperations}
                    value={filter}
                    onValueChanged={(event: any) => {setFilterValue(event.component.getFilterExpression())}}
                />
                <Button startIcon={<FilterIcon/>}
                    variant="outlined"
                    size={prefStore.toolbarSize}
                    onClick={() => {applyFilter()}}
                >
                    {langStore.applyFilterLabel}
                </Button>
            </div>
        </div>
    );
});
