// @ts-nocheck
import React from 'react';
import {
  Plugin,
  Template as Template1,
  TemplateConnector,
  TemplatePlaceholder as TemplatePlaceholder1,
} from '@devexpress/dx-react-core';

const Template: any = Template1;
const TemplatePlaceholder: any = TemplatePlaceholder1;

export type PopupProps = {
  row: any,
  onChange: (event: object) => void,
  onCancelChanges: (event: object) => void,
  onApplyChanges: (event: object) => void,
  open: any,
}

export type Props = {
  popupDialog: (props: PopupProps) => JSX.Element,
}

export const PopupEditing: React.FC<Props> = React.memo(({ popupDialog }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          {
            rows,
            getRowId,
            addedRows,
            editingRowIds,
            createRowChange,
            rowChanges,
          },
          {
            changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
            stopEditRows, cancelAddedRows, cancelChangedRows,
          },
        ) => {
          if (!addedRows || !editingRowIds || !rowChanges) {
            return (<div/>);
          }
          const isNew = addedRows.length > 0;
          let row: any;
          let rowId: any;
          if (isNew) {
            rowId = 0;
            row = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row: any) => getRowId(row) === rowId)[0];
            row = { ...targetRow, ...rowChanges[rowId] };
          }

          const onChange = ({ target: { name, value } }: any) => {
            const changeArgs = {
              rowId,
              change: createRowChange(row, value, name),
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const onApplyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const onCancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return popupDialog({
              open,
              row,
              onChange,
              onApplyChanges,
              onCancelChanges
            });
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));
