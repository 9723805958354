import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {RouteNameType} from "./RoutesStore";
import {createBrowserHistory, Location} from 'history';
import {ReturnDataParams} from "./types";

export type Breadcrumb = {label: string, key: string, pathname: string, isCurrentPage: boolean};

export class BreadcrumbsStore {
  private rootStore: RootStore;
  groupsCounter: number;
  groups: Record<number, {key: string, pathname: string}[]>;
  pageKeys: Record<string, {group: number, pathname: string}>;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.groupsCounter = 1;
    this.groups = {};
    this.pageKeys = {};
  }

  incrementGroupsCounter() {
    this.groupsCounter = this.groupsCounter + 1;
  }

  onChangeBrowserPath(location: Location) {
    const key = location.key;
    const state = location.state as { createInNewGroup?: boolean };
    if (state !== null && !(key in this.pageKeys)) {
      const pathname = location.pathname;
      if (state.createInNewGroup) {
        this.incrementGroupsCounter();
      }
      if (!(this.groupsCounter in this.groups)) {
        this.groups[this.groupsCounter] = [];
      }
      this.pageKeys[key] = {group: this.groupsCounter, pathname};
      this.groups[this.groupsCounter].push({key, pathname});
    }
  }

  getBreadcrumbs(): Breadcrumb[] {
    const key = this.rootStore.history.location.key;
    if (key in this.pageKeys) {
      const currentPathname = this.rootStore.history.location.pathname;
      const group = this.groups[this.pageKeys[key].group];
      return group.map(x => {return {...x, label: this.rootStore.routesStore.routeTitle[x.pathname], isCurrentPage: x.pathname === currentPathname}});
    }
    return [];
  }

  get currentLabel() {
    const history = createBrowserHistory();
    const location = history.location;
    return this.rootStore.routesStore.routeTitle[location.pathname];
  }

  goHistory(count: number) {
    this.rootStore.history.go(-count);
  }

  push(name: RouteNameType, createInNewGroup: boolean=true, payload: ReturnDataParams | {} = {}) {
    const key = this.rootStore.history.location.key;
    // Remove non usable tail
    if (key in this.pageKeys) {
      const group = this.pageKeys[key].group;
      const groups = this.groups[group];
      let currentPagePosition: number;
      for (currentPagePosition=groups.length-1; currentPagePosition >= 0 ; currentPagePosition--) {
        if (groups[currentPagePosition].key !== key) {
          const popped = groups.pop();
          if (popped !== undefined) {
            this.rootStore.removeStores(popped.key);
          }
        } else {
          break;
        }
      }
    }
    return this.rootStore.router.goTo(this.rootStore.routesStore.routes[name], {createInNewGroup, ...payload});
  }
}
