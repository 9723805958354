import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onClick: () => void;
    disabled?: boolean;
    label?: string;
}

export const CreateButton: React.FC<Props> = observer(({onClick, disabled, label}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
    } = rootStore;

    return (
        <Button
            size={prefStore.toolbarSize}
            variant="outlined" startIcon={<AddIcon />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onClick}>
            {label === undefined ? "Crear uno nuevo" : label}
        </Button>
    );
});
