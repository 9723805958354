import * as React from 'react';
import {observer} from "mobx-react";
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Checkbox, FormControlLabel, FormGroup, styled} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useRootStore} from "../../../stores/contexts";

export type ColumnChooserProps = {
    columns: {name: string, isHidden: boolean, label: string}[];
    onChoose: (name: string) => void;
    anchorMenu: null | HTMLElement;
    setAnchorMenu: (element: null | HTMLElement) => void;
 }

export const ColumnChooser: React.FC<ColumnChooserProps> = observer((
    {
        columns,
        onChoose,
        anchorMenu,
        setAnchorMenu,
    }) => {
  const open = Boolean(anchorMenu);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorMenu(null);
  };
  const rootStore = useRootStore();
  const {
    langStore,
  } = rootStore;


  let options;
  if (columns) {
    options = columns.map((x, index) => {
        return <FormControlLabel key={index} onClick={() => onChoose(x.name)} control={<Checkbox defaultChecked={!x.isHidden} />} label={x.label} />
      });
  } else {
    options = <div/>;
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
         variant="outlined" startIcon={<VisibilityOffIcon />}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
         {langStore.columnsChooserLabel}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorMenu}
        open={open}
        onClose={handleClose}
      >
        <FormGroup>
            {options}
         </FormGroup>
      </StyledMenu>
    </div>
  );
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

