import React from "react";
import {observer} from "mobx-react";
import {PaginatorProps, Paginator} from "./controls/Paginator";
import {SearchBox, SearchBoxProps} from "./controls/SearchBox";
import {ColumnChooser, ColumnChooserProps} from "./controls/ColumnChooser";
import {CreateButton, ExportButton} from "./buttons";
import {Grid} from "@mui/material";
import {SaveProfile} from "./buttons/SaveProfile";

type Props = {
    paginatorProps?: PaginatorProps,
    searchBoxProps?: SearchBoxProps,
    columnChooserProps?: ColumnChooserProps,
    createNewElement?: () => void,
    exportToExcel?: () => void,
    saveProfile?: () => void,
}

export const ListToolbox: React.FC<Props> = observer(({
    paginatorProps,
    searchBoxProps,
    columnChooserProps,
    createNewElement,
    exportToExcel,
    saveProfile,
}) => {
    let searchBox;
    if (searchBoxProps) {
        searchBox = <SearchBox {...searchBoxProps}/>
    }

    let paginator;
    if (paginatorProps) {
        paginator =  <Paginator {...paginatorProps} />
    }

    let columnChooser;
    if (columnChooserProps) {
        columnChooser =  <ColumnChooser {...columnChooserProps} />
    }

    let toExcel;
    if (exportToExcel) {
        toExcel = <ExportButton onClick={() => exportToExcel()}/>
    }

    let createNew;
    if (createNewElement) {
        createNew = <CreateButton onClick={() => createNewElement()}/>
    }

    let saveFormat;
    if (saveProfile) {
        saveFormat = <SaveProfile onClick={() => saveProfile()}/>
    }

    return (
        <Grid container>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {columnChooser}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {saveFormat}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {createNew}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {toExcel}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {searchBox}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                {paginator}
            </Grid>
        </Grid>
    );
});