import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";
import {FileUploadButton} from "../../../../components";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import {Button} from "@mui/material";

export const EventImages = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  const updateEvent = eventNewStore.currentEventId && eventNewStore.event;

  return (
      <EventNewCard title={langStore.eventImagesTitle}>
        <div>
            {
                updateEvent &&
                <div>
                    <List dense sx={{width: '100%', maxWidth: 460, bgcolor: 'background.paper'}}>
                        {
                            updateEvent.images?.map((image, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            image.id in eventNewStore.deletedImages ?
                                            <IconButton edge="end" aria-label="restore"
                                                        onClick={() => eventNewStore.restoreFromDeletedImages(image)}>
                                                <RestoreIcon/>
                                            </IconButton>
                                            :
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={() => eventNewStore.addToDeletedImages(image)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }>
                                        <Button color={image.id in eventNewStore.deletedImages ? "error" : "success"}
                                            onClick={() => eventNewStore.downloadImage(updateEvent.id, image.id, image.name)}>
                                            {image.name}
                                        </Button>
                                    </ListItem>
                                );
                            })}
                    </List>
                    <img src={eventNewStore.currentImage} alt=""/>
                </div>
            }
            <FileUploadButton onChangeFile={(file: any)=>eventNewStore.onUploadFile(file)} />
            <List dense sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}>
              {Object.keys(eventNewStore.uploadedFiles).map((key, index) => {
                  return (
                      <ListItem
                          key={index}
                          secondaryAction={
                              <IconButton edge="end" aria-label="delete" onClick={() => eventNewStore.deleteFile(eventNewStore.uploadedFiles[key])}>
                                  <DeleteIcon />
                              </IconButton>
                          }>
                          {eventNewStore.uploadedFiles[key].filename}
                      </ListItem>
                  );
              })}
            </List>
        </div>
      </EventNewCard>
  );
});
