import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {ProcessingDialog} from "../../../common";
import {ErrorDialog} from "../../../common";
import {OneUser} from "./OneUser";
import {EditToolbox} from "../../../common/EditToolbox";

export const UserNewPage = observer(() => {
    const rootStore = useRootStore();
    const {userOneStore, langStore, router} = rootStore;

    if (userOneStore.status === "uninitialized") {
        userOneStore.setCallbackUpdateParams(router?.params);
        userOneStore.initialize();
    }

    let processing = false;
    if (userOneStore.status === "processing") {
        processing = true;
    }

    const editToolbox = <EditToolbox
            save={() => userOneStore.save()}
            cancel={() => userOneStore.close()}
        />;

    return (
        <div>
            <PageLayout
                secondaryToolbox={editToolbox}
                navigationElements={{
                    links: [
                        {id: langStore.userDataTitle, label: langStore.userDataTitle},
                        {id: langStore.userRolesTitle, label: langStore.userRolesTitle},
                        {id: langStore.userOrganizationsTitle, label: langStore.userOrganizationsTitle},
                    ],
                    navigateTo: userOneStore.navigateTo,
                    navigateToHash: userOneStore.navigateToHash,
                  }}
            >
                <OneUser />
            </PageLayout>
            <ProcessingDialog open={processing && !userOneStore.errorDialogOpen} title={userOneStore.processingTitle} description={userOneStore.processingDescription}/>
            <ErrorDialog open={userOneStore.errorDialogOpen} title={userOneStore.errorTitle} description={userOneStore.errorDescription} onClose={() => userOneStore.setErrorDialogOpen(false)} />
        </div>
    );
});
