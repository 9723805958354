import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {TextareaAutosize} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventDescription = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
      <EventNewCard title={langStore.eventDescriptionTitle}
                    description={langStore.eventDescriptionDescription}>
        <TextareaAutosize
              aria-label="empty textarea"
              placeholder={langStore.descriptionLabel}
              value={eventNewStore.description}
              onChange={(event) => eventNewStore.onChangeDescription(event.target.value)}
              style={{ width: 500, height:100, margin: 5 }}
        />
      </EventNewCard>
  );
});
