import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {ViewEvent} from "../";
import {ErrorDialog, ProcessingDialog} from "../../../common";


export const EventViewPage = observer(() => {
    const rootStore = useRootStore();
    const {eventViewStore, langStore, router} = rootStore;

    if (eventViewStore.status === "uninitialized") {
        const id = router?.params?.id;
        if (id) {
            eventViewStore.setEventId(id as string);
        }
        eventViewStore.initialize();
    }

    let processing = false;
    if (eventViewStore.status === "processing") {
        processing = true;
    }
    return (
        <div>
            <PageLayout
                navigationElements={{
                    links: [
                        {id: "TopOfContent", label: langStore.dataSourceSectionTitle},
                        {id: langStore.eventLocalizationSectionTitle, label: langStore.eventLocalizationSectionTitle},
                        {id: langStore.eventTimeSectionTitle, label: langStore.eventTimeSectionTitle},
                        {id: langStore.eventMagnitudeSectionTitle, label: langStore.eventMagnitudeSectionTitle},
                        {id: langStore.eventImpactSectionTitle, label: langStore.eventImpactSectionTitle},
                    ]}}
            >
            <ViewEvent />
        </PageLayout>
        <ProcessingDialog open={processing && !eventViewStore.errorDialogOpen} title={eventViewStore.processingTitle} description={eventViewStore.processingDescription}/>
        <ErrorDialog open={eventViewStore.errorDialogOpen} title={eventViewStore.errorTitle} description={eventViewStore.errorDescription} onClose={() => eventViewStore.setErrorDialogOpen(false)} />
        </div>
    );
});
