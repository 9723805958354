import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, TextField} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventWindSpeed = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;


  return (
      <EventNewCard title={langStore.eventWindSpeedTitle}
                    description={langStore.eventWindSpeedDescription}
                    disabled={eventNewStore.isDisableWind}>
        <FormControl sx={{display: "inline"}}>
            <TextField
                error={!eventNewStore.isInteger(eventNewStore.windSpeedKmH)}
                sx={{p: 2, display:"inline", width: 30}}
                label={langStore.eventWindSpeedLabel}
                value={eventNewStore.windSpeedKmH}
                onChange={(event) => eventNewStore.onChangeWindSpeedKmH(event.target.value)}
            />
        </FormControl>
      </EventNewCard>
  );
});
