import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {Box, Button, Grid, ListItem, ListItemIcon, Typography} from "@mui/material";
import {SatelliteImage} from "../../../../components/satelliteImage";
import List from "@mui/material/List";
import {FilteredEventList} from "../FilteredEventList";
import {Sorting} from "../../../common/types";
import {useTheme} from "@mui/material/styles";
import CircleIcon from '@mui/icons-material/Circle';
import {ConfirmCancelDialog} from "../../../common/dialogs/ConfirmCancelDialog";
import {RootStore} from "../../../../stores";
import {Event} from "../../../../api/graphql";
import {getInfoBlocks} from "../eventInfo";

export const ReviewEvent = observer(() => {
    const rootStore = useRootStore();
    const {
        langStore,
        eventReviewStore,
    } = rootStore;

    const theme = useTheme();

    const boxesColor = `${theme.palette.primary.main}30`;

    const tableColumnWidth = "360px";
    let event = eventReviewStore.currentEvent;
    if (eventReviewStore.reloadedEvent && event && eventReviewStore.reloadedEvent.id === event.id) {
        event = eventReviewStore.reloadedEvent;
    }

    const {mainInfo, impactInfo, referencesInfo} = getInfoBlocks({rootStore, event, boxesColor});

    const confidence = eventReviewStore.currentEvent?.confidence?.id;

    return (
        <div id={"SatelliteImage"}>
            <Typography variant={"h6"}>{langStore.satelliteImagesTitle}</Typography>
            <Box  sx={{
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 2,
                  mb: 4
                }}>

            <Grid container spacing={1} direction={"row"}>
                <Grid item style={{width: tableColumnWidth}}>
                    {mainInfo}
                </Grid>
                <Grid item style={{width: tableColumnWidth}}>
                    {eventReviewStore.currentEvent && <SatelliteImage
                        dates={eventReviewStore.arrayOfDates}
                        imageIndex={eventReviewStore.currentImageIndex}
                        setImageIndex={(imageIndex) => eventReviewStore.setCurrentImageIndex(imageIndex)}
                        previousEvent={() => eventReviewStore.previousEvent()}
                        disabledPreviousEvent={eventReviewStore.disablePreviousEvent}
                        nextEvent={() => eventReviewStore.nextEvent()}
                        disabledNextEvent={eventReviewStore.disableNextEvent}
                        latLon={eventReviewStore.coordinates}
                        scale={eventReviewStore.scaleImage}
                        playing={eventReviewStore.playing}
                        setPlaying={(value) => eventReviewStore.setPlaying(value)}
                        setScale={(scale) => eventReviewStore.setScaleImage(scale)}
                        doAction={(status) => eventReviewStore.doAction(status)}
                        disabledEdit={false}
                        disabledOk={confidence === "VERIFIED"}
                        disabledDelete={confidence !== "RAW"}
                        canRemove={eventReviewStore.canRemoveEvent}
                    />}
                </Grid>
                <Grid item style={{width: tableColumnWidth}}>
                    {impactInfo}
                </Grid>
                <Grid item style={{width: tableColumnWidth}}>
                    {referencesInfo}
                </Grid>
            </Grid>
            </Box>

            <FilteredEventList
                componentId={"EventsFiltering"}
                events={eventReviewStore.eventsOfCurrentPage}
                reloadedEvent={eventReviewStore.reloadedEvent}
                filter={eventReviewStore.filter}
                setFilterValue={(filter) => eventReviewStore.setFilterValue(filter)}
                applyFilter={() => eventReviewStore.applyFilter()}
                selectEvent={(rowClickEvent) => eventReviewStore.selectEvent(rowClickEvent.data.event)}
                pageSize={eventReviewStore.pageSize}
                hasMorePages={eventReviewStore.hasMorePages}
                nextPage={() => eventReviewStore.getNextPage()}
                setPageSize={(pageSize) => eventReviewStore.setPageSize(pageSize)}
                onColumnWidthsChange={(widths) => eventReviewStore.onColumnWidthsChange(widths)}
                columnWidths={eventReviewStore.columnWidths}
                onColumnOrderChange={(order) => eventReviewStore.onColumnOrderChange(order)}
                columnOrder={eventReviewStore.columnOrder}
                viewRow={(id) => eventReviewStore.viewRow(id)}
                editRow={(id) => eventReviewStore.editRow(id)}
                deleteRow={(id) => eventReviewStore.deleteRow(id)}
                columns={eventReviewStore.columns}
                onHiddenColumnNamesChange={(columns) => eventReviewStore.onHiddenColumnChange(columns)}
                hiddenColumnsNames={eventReviewStore.hiddenColumns}
                onSortingChange={(columns) => eventReviewStore.onSortingChange(columns as Sorting[])}
                sorting={eventReviewStore.sorting}
                columnExtensions={eventReviewStore.columnExtensions}
                onSelectionChange={(selection) => eventReviewStore.onSelectionChange(selection)}
                eventFormat={(e) => eventReviewStore.grid.eventFormat(e)}
                currentPage={eventReviewStore.currentPage}
            />
        <div id={"EndOfListing"} />
          <ConfirmCancelDialog
              title={"Confirme el borrado"}
              description={"¿Confirma que quiere borrar el registro?"}
              open={eventReviewStore.deleteDialogOpen}
              onConfirm={() => eventReviewStore.deleteEvent()}
              onCancel={() => eventReviewStore.cancelDelete()}
          />
        </div>
    );
});
