export async function downloadBlob(url: string) {
  const response = await fetch(url, {
      method: 'GET',
      headers: {
          "Content-Type": "image/jpeg",
      }
  });
  return await response.blob();
}

export async function downloadImage(uri: string) {
  const blob = await downloadBlob(uri);
  return getImageFromBlob(blob);
}

export function downloadFromBlob(blob: Blob, name: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
}

export async function getImageFromBlob(blob: Blob) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
    });
}

export async function getUrlFromBlob(blob: Blob) {
    return URL.createObjectURL(blob);
}

