import React from "react";
import {Point} from "./types";
import {observer} from "mobx-react";

type Props = {
    points: Point[];
    width: string | number;
    height: string | number;
    xFactor: number;
    yFactor: number;
    radius: number;
    color: string;
};

export const DrawLocations: React.FC<Props> = observer(({
    points,
    width,
    height,
    xFactor=1,
    yFactor=1,
    radius,
    color,
}) => {
        const svgCircles = points.map((p: Point, index: number) => (
                      <circle
                        cx={p.x / xFactor}
                        cy={p.y / yFactor}
                        r={radius / xFactor}
                        stroke={"white"}
                        strokeWidth="2"
                        key={index}
                        fill={color}
                        strokeDasharray={"black"}
                      />
            ));

        return (
                <svg style={{
                position: "absolute",
                top: 0,
                left: 0,
            }}
                  xmlns={"http://www.w3.org/2000/svg"}
                  version={"1.1"}
                  width={width}
                  height={height}
                >
                  {svgCircles}
                </svg>
            );
    }
);
