/**
 * Severe Weather Reporting Web App
 * CIMA - Centro de Investigaciones del Mar y la Atmósfera
 * Argentina
 * @author Fido García <garciafido@gmail.com>
 */
import {AuthStore} from '../auth';
import {EventTypeStore} from '../entities/eventType';
import {ImpactTypeStore} from '../entities/impactType';
import {ImpactStore} from '../entities/impact/ImpactStore';
import {ConfidenceStore} from '../entities/confidence';
import {LangStore} from './LangStore';
import {PrefStore} from "./PrefStore";
import {InformationSourceStore} from "../entities/informationSource";
import {StateStore} from "../entities/state";
import {CityStore} from "../entities/city";
import {RoleStore} from "../entities/role";
import {BreadcrumbsStore} from "./BreadcrumbsStore";
import {ReturnDataParams, DynamicStore} from "./types";
import {EventListStore} from "../entities/event/EventListStore";
import {EventReviewStore} from "../entities/event/EventReviewStore";
import {EventViewStore} from "../entities/event/EventViewStore";
import {EventNewStore} from '../entities/event/EventNewStore';
import {EventGridStore} from "../entities/event/EventGridStore";
import {RouterStore, startRouter} from '../components/my-mobx-router/src';
import {pageNotFoundPath} from "./RoutesStore";
import {createBrowserHistory, History, Location} from "history";
import {runInAction} from "mobx";
import {RoutesStore} from "./RoutesStore";
import {UserListStore} from "../entities/user";
import {UserOneStore} from "../entities/user";
import {OrganizationStore} from "../entities/organization";

type Constructor<T> = new (...args: any[]) => T;

export class RootStore {
  private dynamicStores: Record<string, Record<string, DynamicStore>> = {};

  /*
   * Global Stores
   */
  langStore: LangStore;
  authStore: AuthStore;
  prefStore: PrefStore;

  // Router store
  history: History;
  routesStore: RoutesStore;
  router: RouterStore<RootStore>;
  breadcrumbsStore: BreadcrumbsStore;

  // Singleton entities
  eventTypeStore: EventTypeStore;
  impactTypeStore: ImpactTypeStore;
  impactStore: ImpactStore;
  confidenceStore: ConfidenceStore;
  informationSourceStore: InformationSourceStore;
  stateStore: StateStore;
  cityStore: CityStore;
  roleStore: RoleStore;
  organizationStore: OrganizationStore;


  constructor() {
    this.langStore = new LangStore(this);
    this.authStore = new AuthStore(this);
    this.prefStore = new PrefStore(this);

    this.eventTypeStore = new EventTypeStore(this);
    this.impactTypeStore = new ImpactTypeStore(this);
    this.impactStore = new ImpactStore(this);
    this.confidenceStore = new ConfidenceStore(this);
    this.informationSourceStore = new InformationSourceStore(this);
    this.stateStore = new StateStore(this);
    this.cityStore = new CityStore(this);
    this.roleStore = new RoleStore(this);
    this.organizationStore = new OrganizationStore(this);

    this.routesStore = new RoutesStore(this);
    this.history = createBrowserHistory();
    this.router = new RouterStore<RootStore>(this);
    this.breadcrumbsStore = new BreadcrumbsStore(this);
    startRouter<RootStore>(
        this.routesStore.routes,
        this,
        this.history,
        (location: Location) => this.onChangeBrowserPath(location),
        {
            html5history: true, // false if you want to use hash based routing
            notfound: () => this.router.goTo(this.routesStore.routes[pageNotFoundPath]),
        }
    );
  }

  /*
   * Dynamic per page Stores
   */

  get eventNewStore(): EventNewStore {
    return this.getOrCreateDynamicStore<EventNewStore>(EventNewStore);
  }

  get userListStore(): UserListStore {
    return this.getOrCreateDynamicStore<UserListStore>(UserListStore);
  }

  get userOneStore(): UserOneStore {
    return this.getOrCreateDynamicStore<UserOneStore>(UserOneStore);
  }

  get eventListStore(): EventListStore {
    return this.getOrCreateDynamicStore<EventListStore>(EventListStore);
  }

  get eventReviewStore(): EventReviewStore {
    return this.getOrCreateDynamicStore<EventReviewStore>(EventReviewStore);
  }

  get eventViewStore(): EventViewStore {
    return this.getOrCreateDynamicStore<EventViewStore>(EventViewStore);
  }

  get eventGridStore(): EventGridStore {
    return this.getOrCreateDynamicStore<EventGridStore>(EventGridStore);
  }

  /*
   * Utility methods
   */

  getStoreId() {
    return this.history.location.key;
  }

  removeStores(id: string) {
    if (id in this.dynamicStores) {
      Object.values(this.dynamicStores[id]).forEach(store => store.destroy());
      delete this.dynamicStores[id];
    }
  }

  async returnedData(params: ReturnDataParams) {
    if (params.storeId) {
      if (params.storeId in this.dynamicStores) {
        Object.values(this.dynamicStores[params.storeId]).forEach((store) => {
          if (store.returnedData) {
            store.returnedData(params);
          }
        });
      }
    }
  }

  private onChangeBrowserPath(location: Location) {
    runInAction(() => {
      this.breadcrumbsStore.onChangeBrowserPath(location);
    });
  }

  private getOrCreateDynamicStore<T extends DynamicStore>(creator: Constructor<T>): T {
    const storeId = this.getStoreId();
    if (storeId === null) {
      throw new Error(`Dynamic store id "${storeId}" does not exist`);
    }
    const storeName = creator.name;
    if (!(storeId in this.dynamicStores)) {
      this.dynamicStores[storeId] = {};
    }
    if (!(storeName in this.dynamicStores[storeId])) {
      this.dynamicStores[storeId][storeName] = new creator(this, storeId);
    }
    return this.dynamicStores[storeId][storeName] as T;
  }

}
