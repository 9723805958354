import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onClick: () => void;
    disabled?: boolean;
}

export const SaveProfile: React.FC<Props> = observer(({onClick, disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
        langStore,
    } = rootStore;

    return (
        <Button
            size={prefStore.toolbarSize}
            variant="outlined" startIcon={<FolderSharedIcon />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onClick}>
            {langStore.saveProfile}
        </Button>
    );
});
