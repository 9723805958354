import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {EventViewCard} from "./EventViewCard";

export const ViewEvent = observer(() => {
    const rootStore = useRootStore();
    const {
        langStore,
        eventViewStore,
    } = rootStore;

    if (eventViewStore.event) {
        const sxCards = {minWidth: 275, m: 1, backgroundColor: "darkgray"};
        const event = eventViewStore.event;

    const labeledText = (label: string, text: string | undefined | null) =>  {
            return (
                <div>
                    <Typography variant="body2" color="text.secondary" display="inline">
                        {label + ": "}
                    </Typography>
                    <Typography display="inline">
                        {text}
                    </Typography>
                </div>
            );
        }

    const organizationsWebpages = event.author.organizations.map( (org, index) =>
        <div key={index} style={{marginBottom: 5}}>
            <a href={org.webpage} target='_blank' rel='noopener noreferrer'>
                {org.name}
            </a>
        </div>
    )

    return (
            <Grid
                id={"TopOfContent"}
                container
                spacing={0}
                direction="column"
                bgcolor="lightgray"
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={{pt: 1}}
                     bgcolor="lightgray"
                     alignItems="center"
                     justifyContent="center">
                    <Card id={langStore.dataSourceSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.dataSourceSectionTitle}
                            </Typography>
                            <EventViewCard title={""}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {event.author.name}
                                </Typography>
                                <Typography gutterBottom component="div">
                                      <div style={{marginBottom: 5}}>
                                        <a href={`mailto:${event.author.email}`}>{event.author.email}</a>
                                      </div>
                                </Typography>
                                {/*<Typography gutterBottom component="div">*/}
                                {/*    {event.author.roles.map((role) => role[langStore.fieldName]).join(", ")}*/}
                                {/*</Typography>*/}
                                <Typography gutterBottom component="div">
                                    {organizationsWebpages}
                                </Typography>
                            </EventViewCard>

                            <EventViewCard id={langStore.qualityControlTitle} title={langStore.qualityControlTitle}>
                                <Typography display="inline">
                                    {event.confidence ? event.confidence[langStore.fieldNameLang] : ""}
                                </Typography>
                            </EventViewCard>

                            <EventViewCard id={langStore.sourceInformationTitle} title={langStore.sourceInformationTitle}>
                                <Typography display="inline">
                                    {event.source ? event.source[langStore.fieldNameLang] : ""}
                                </Typography>
                            </EventViewCard>
                            <EventViewCard title={langStore.eventReferencesTitle}>
                                {
                                  event.references?.map((m, index) => {
                                    const queryStringIndex = m.link.indexOf('?');
                                    return (
                                      <div key={index} style={{marginBottom: 5}}>
                                        <a href={m.link} target='_blank' rel='noopener noreferrer'>
                                            {m.link.substring(0, queryStringIndex > 0 ? queryStringIndex : undefined)}
                                        </a>
                                      </div>);
                                  })
                                }
                            </EventViewCard>
                            <EventViewCard title={langStore.eventImagesTitle}>
                                <div>
                                    {
                                      event.images?.map((image, index) => {
                                        return (
                                          <div key={index} style={{marginBottom: 5}}>
                                            <Button onClick={() => eventViewStore.downloadImage(event.id, image.id, image.name)}>
                                                {image.name}
                                            </Button>
                                          </div>);
                                      })
                                    }
                                    <img src={eventViewStore.currentImage} alt="" />
                                </div>
                            </EventViewCard>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventLocalizationSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventLocalizationSectionTitle}
                            </Typography>
                            <EventViewCard id={langStore.locationTitle} title={langStore.locationTitle}>
                                {labeledText(langStore.countryLabel, event.country ? event.country.name : "")}
                                {labeledText(langStore.stateLabel("AR"), event.state ? event.state.name : "")}
                                {labeledText(langStore.cityLabel, event.city ? event.city.name : "")}
                                {labeledText(langStore.eventLocationLabel, event.location)}
                            </EventViewCard>
                            <EventViewCard title={langStore.detailedLocationTitle}>
                                <Typography maxWidth={500} gutterBottom variant="h6" component="div">
                                    {event.detailedLocation}
                                </Typography>
                            </EventViewCard>
                            <EventViewCard title={`${langStore.eventLatitudeLongitudeTitle}/${langStore.eventLongitudeTitle}`}>
                                {labeledText(langStore.eventLatitudeLabel, event.latitude)}
                                {labeledText(langStore.eventLongitudeLabel, event.longitude)}
                            </EventViewCard>
                            <EventViewCard title={langStore.spatialPrecisionTitle}>
                                <Typography display="inline">
                                    {event.spacialPrecisionMeters ? event.spacialPrecisionMeters.toString() + " m" : ""}
                                </Typography>
                            </EventViewCard>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventTimeSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventTimeSectionTitle}
                            </Typography>
                            <EventViewCard id={langStore.eventDateTimeTitle} title={langStore.eventDateTimeTitle}>
                                <Typography display="inline">
                                    {event.eventTime}
                                </Typography>
                            </EventViewCard>
                            <EventViewCard id={langStore.temporalPrecisionTitle} title={langStore.temporalPrecisionTitle}>
                                <Typography display="inline">
                                    {event.temporalPrecisionSeg ? `${event.temporalPrecisionSeg} ${langStore.seconds}` : ""}
                                </Typography>
                            </EventViewCard>
                            <EventViewCard title={langStore.eventDurationTitle}>
                                <Typography display="inline">
                                    {event.totalDurationSeg ? `${event.totalDurationSeg} ${langStore.seconds}` : ""}
                                </Typography>
                            </EventViewCard>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventMagnitudeSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventMagnitudeSectionTitle}
                            </Typography>
                            <EventViewCard id={langStore.eventTypeTitle} title={langStore.eventTypeTitle}>
                                 {
                                     event.eventTypes?.map((i, index) => {
                                        return (<Typography key={index}>
                                          {i[langStore.fieldNameLang]}
                                        </Typography>);
                                     })
                                 }
                            </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("HAIL")} title={langStore.eventHailDiameterMMLabel}>
                                <Typography display="inline">
                                    {event.hailMaxDiameterMM ? event.hailMaxDiameterMM.toString() + " mm" : ""}
                                </Typography>
                             </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("WIND")} title={langStore.eventWindSpeedLabel}>
                                <Typography display="inline">
                                    {event.windSpeedKmH ? event.windSpeedKmH.toString() + " km/h" : ""}
                                </Typography>
                             </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("WIND")} title={langStore.eventWindMaxSpeedLabel}>
                                <Typography display="inline">
                                    {event.windSpeedMaxKmH ? event.windSpeedMaxKmH.toString() + " km/h" : ""}
                                </Typography>
                             </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("WIND")} title={langStore.eventWindDirectionTitle}>
                                <Typography display="inline">
                                    {event.windDirectionDegrees ? event.windDirectionDegrees.toString() + " º" : ""}
                                </Typography>
                             </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("RAIN")} title={langStore.eventPrecipitationAccumTitle}>
                                <Typography display="inline">
                                    {event.precipitationAccumMM ? event.precipitationAccumMM.toString() + " mm" : ""}
                                </Typography>
                             </EventViewCard>
                             <EventViewCard disabled={!event.eventTypes.map(x=>x.id).includes("SNOW")} title={langStore.eventSnowAccumMMLabel}>
                                <Typography display="inline">
                                    {event.snowAccumMM ? event.snowAccumMM.toString() + " mm" : ""}
                                </Typography>
                             </EventViewCard>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventImpactSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventImpactSectionTitle}
                            </Typography>
                             <EventViewCard title={langStore.eventImpactLabel}>
                                 {
                                     event.impacts?.map((i, index) => {
                                        return (<Typography key={index}>
                                          {i[langStore.fieldNameLang]}
                                        </Typography>);
                                     })
                                 }
                             </EventViewCard>
                             <EventViewCard title={langStore.descriptionLabel}>
                                <Typography maxWidth={500}>
                                    {event.description}
                                </Typography>
                             </EventViewCard>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        );
    } else {
        return (
            <div>{eventViewStore.eventId}</div>
        );
    }
});
