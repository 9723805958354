import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../layout";
import {useRootStore} from "../../stores/contexts";

export const NotFoundPage = observer(() => {
    const rootStore = useRootStore();
    return (
        <PageLayout>
            <h1>{rootStore.langStore.pageNotFound}</h1>
        </PageLayout>
    );
});