import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, TextField,} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventPrecipitationAccum = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
      <EventNewCard title={langStore.eventPrecipitationAccumTitle}
                    description={langStore.eventPrecipitationAccumDescription}
                    disabled={eventNewStore.isDisabledPrecipitation}>
        <FormControl sx={{display: "inline"}}>
            <TextField
                error={!eventNewStore.isInteger(eventNewStore.precipitationAccumMM)}
                sx={{p: 2, display:"inline", width: 30}}
                label={langStore.eventPrecipitationAccumLabel}
                value={eventNewStore.precipitationAccumMM}
                onChange={(event) => eventNewStore.onChangePrecipitationAccum(event.target.value)}
            />
        </FormControl>
      </EventNewCard>
  );
});
