import {Sorting} from "../common/types";

import {makeAutoObservable} from "mobx"
import {DynamicStore, StoreStatus} from "../../stores/types";
import {RootStore} from "../../stores";
import {LangStore} from "../../stores/LangStore";
import {SortingState, TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {Event, ListProfile, SortOrder} from "../../api/graphql";

export type ColumnInfo = Record<string, {
    sortingEnabled: boolean,
    width: number,
    isHidden: boolean,
}>;

const VERSION = "1.0.0";

export class EventGridStore implements DynamicStore {
    readonly langStore: LangStore;
    errorDescription: string = "";
    errorDialogOpen: boolean = false;
    status: StoreStatus = "uninitialized";

    storeId: string = "";
    columnsInfo: ColumnInfo = {
            "id": {sortingEnabled: true, width: 300, isHidden: true},
            "name": {sortingEnabled: true, width: 250, isHidden: false,},
            "email": {sortingEnabled: true, width: 250, isHidden: true},
            "confidence": {sortingEnabled: true, width: 100, isHidden: false},
            "source": {sortingEnabled: true, width: 150, isHidden: false},
            "references": {sortingEnabled: true, width: 300, isHidden: true},
            "eventTypes": {sortingEnabled: true, width: 300, isHidden: false},
            "hasMedia": {sortingEnabled: true, width: 100, isHidden: true},
            "country": {sortingEnabled: true, width: 100, isHidden: true},
            "state": {sortingEnabled: true, width: 100, isHidden: true},
            "city": {sortingEnabled: true, width: 200, isHidden: true},
            "location": {sortingEnabled: true, width: 200, isHidden: true},
            "detailedLocation": {sortingEnabled: false, width: 200, isHidden: true},
            "latitude": {sortingEnabled: true, width: 100, isHidden: true},
            "longitude": {sortingEnabled: true, width: 100, isHidden: true},
            "spacialPrecisionMeters": {sortingEnabled: true, width: 100, isHidden: true},
            "eventTime": {sortingEnabled: true, width: 200, isHidden: false},
            "temporalPrecisionSeg": {sortingEnabled: true, width: 100, isHidden: true},
            "totalDurationSeg": {sortingEnabled: true, width: 100, isHidden: true},
            "hailMaxDiameterMM": {sortingEnabled: true, width: 100, isHidden: true},
            "windSpeedKmH": {sortingEnabled: true, width: 100, isHidden: true},
            "windSpeedMaxKmH": {sortingEnabled: true, width: 100, isHidden: true},
            "precipitationAccumMM": {sortingEnabled: true, width: 100, isHidden: true},
            "snowAccumMM": {sortingEnabled: true, width: 100, isHidden: true},
            "impacts": {sortingEnabled: true, width: 100, isHidden: true},
            "impactsCodes": {sortingEnabled: true, width: 100, isHidden: true},
            "description": {sortingEnabled: true, width: 300, isHidden: true}
            };

    sortExpression: Record<string, (direction: SortOrder, lang: LangStore) => any> = {
            "name": (direction: SortOrder, lang: LangStore) => {return {author: {name: direction}}},
            "email": (direction: SortOrder, lang: LangStore) => {return {author: {email: direction}}},
            "hasMedia": (direction: SortOrder, lang: LangStore) => {return {images: {_count: direction}}},
            "confidence": (direction: SortOrder, lang: LangStore) => {return {confidence: {[lang.fieldNameLang]: direction}}},
            "source": (direction: SortOrder, lang: LangStore) => {return {source: {[lang.fieldNameLang]: direction}}},
            "references": (direction: SortOrder, lang: LangStore) => {return {references: {_count: direction}}},
            "eventTypes": (direction: SortOrder, lang: LangStore) => {return {eventTypes: {_count: direction}}},
            "country": (direction: SortOrder, lang: LangStore) => {return {country: {name: direction}}},
            "state": (direction: SortOrder, lang: LangStore) => {return {state: {name: direction}}},
            "city": (direction: SortOrder, lang: LangStore) => {return {city: {name: direction}}},
            "impacts": (direction: SortOrder, lang: LangStore) => {return {impacts: {_count: direction}}},
            "impactsCodes": (direction: SortOrder, lang: LangStore) => {return {impacts: {_count: direction}}},
    }

    columnOrder = [
        "id",
        "name",
        "email",
        "confidence",
        "references",
        "eventTypes",
        "hasMedia",
        "source",
        "country",
        "state",
        "city",
        "location",
        "detailedLocation",
        "latitude",
        "longitude",
        "spacialPrecisionMeters",
        "eventTime",
        "temporalPrecisionSeg",
        "totalDurationSeg",
        "hailMaxDiameterMM",
        "windSpeedKmH",
        "windSpeedMaxKmH",
        "precipitationAccumMM",
        "snowAccumMM",
        "impacts",
        "impactsCodes",
        "description",
    ];

    sorting: Sorting[] = [{columnName: "eventTime", direction: SortOrder.Desc}];

    constructor(rootStore: RootStore, instanceId: string) {
        makeAutoObservable(this);
        this.langStore = rootStore.langStore;
        this.storeId = instanceId;
    }

    destroy() {
        // this object will be unusable
    }

    get hiddenColumns(): string[] {
        return Object.keys(this.columnsInfo).filter(key => this.columnsInfo[key].isHidden);
    }

    get columnChooserInfo(): {name: string, isHidden: boolean, label: string}[] {
        const result = [];
        for (const key of this.columnOrder) {
            result.push({name: key, isHidden: this.columnsInfo[key].isHidden, label: this.columnTitles[key]});
        }
        return result;
    }

    toggleColumnHidden(name: string) {
        this.columnsInfo[name].isHidden = !this.columnsInfo[name].isHidden;
    }

    setHiddenColumns(hiddenColumns: string[]) {
        for (const key of Object.keys(this.columnsInfo)) {
            this.columnsInfo[key].isHidden = !!hiddenColumns.find(h => h === key);
        }
    };

    setShownColumns(shownColumns: string[]) {
        for (const key of Object.keys(this.columnsInfo)) {
            this.columnsInfo[key].isHidden = !shownColumns.find(h => h === key);
        }
    };

    get columnWidths(): TableColumnWidthInfo[]{
        return Object.keys(this.columnsInfo).map(key => {return {columnName: key, width: this.columnsInfo[key].width}});
    }

    setColumnWidths(columnWidths: TableColumnWidthInfo[]) {
        for (const cw of columnWidths) {
            this.columnsInfo[cw.columnName].width = Number(cw.width);
        }
    }

    get columnExtensions(): SortingState.ColumnExtension[] {
        return Object.keys(this.columnsInfo).map(key => {return {columnName: key, sortingEnabled: this.columnsInfo[key].sortingEnabled}});
    }
    
    setColumnExtensions(columnExtensions: SortingState.ColumnExtension[]) {
        for (const ce of columnExtensions) {
            this.columnsInfo[ce.columnName].sortingEnabled = ce.sortingEnabled;
        }
    }

    setColumnOrder(columnOrder: string[]) {
        this.columnOrder = columnOrder;
    }

    setSorting(sorting: Sorting[]) {
        this.sorting = sorting;
    };

    get xlsColumns(): {header: string, key: string, width: number}[] {
        const columns = [];
        for (const column of this.columnOrder) {
            if (!this.columnsInfo[column].isHidden) {
                const width = this.columnsInfo[column].width / 10 * 1.33;
                columns.push({
                    header: this.columnTitles[column],
                    key: column,
                    width,
                });
            }
        }
        // columns.push({
        //     header: this.columnTitles["impactsCodes"],
        //     key: "impactsCodes",
        //     width: 80 / 10 * 1.33,
        // });
        return columns;
    }

    get OrderBy() {
        let orderBy = [];
        for (const s of this.sorting) {
            const sortExpression = this.sortExpression[s.columnName];
            if (sortExpression) {
                const ob = sortExpression(s.direction === "desc" ? SortOrder.Desc : SortOrder.Asc, this.langStore);
                orderBy.push(ob);
            } else {
                orderBy.push({[s.columnName]: s.direction === "desc" ? SortOrder.Desc : SortOrder.Asc});
            }
        }
        return orderBy;
    }

    get columnTitles(): Record<string, string> {
        return {
            "id": "ID",
            "name": this.langStore.reporterLabel,
            "email": "email",
            "confidence": this.langStore.qualityControlLabel,
            "source": this.langStore.sourceInformationLabel,
            "references": this.langStore.eventReferencesLabel,
            "eventTypes": this.langStore.eventTypeTitle,
            "hasMedia": this.langStore.eventHasMediaLabel,
            "country": this.langStore.countryLabel,
            "state": this.langStore.allStateLabel,
            "city": this.langStore.cityLabel,
            "location": this.langStore.eventLocationLabel,
            "detailedLocation": this.langStore.detailedLocationLabel,
            "latitude": this.langStore.eventLatitudeLabel,
            "longitude": this.langStore.eventLongitudeLabel,
            "spacialPrecisionMeters": this.langStore.spatialPrecisionMetersLabel,
            "eventTime": this.langStore.eventTimeLabel,
            "temporalPrecisionSeg": this.langStore.temporalPrecisionLabel,
            "totalDurationSeg": this.langStore.eventDurationLabel,
            "hailMaxDiameterMM": this.langStore.eventHailDiameterMMLabel,
            "windSpeedKmH": this.langStore.eventWindSpeedLabel,
            "windSpeedMaxKmH": this.langStore.eventWindMaxSpeedLabel,
            "precipitationAccumMM": this.langStore.eventPrecipitationAccumLabel,
            "snowAccumMM": this.langStore.eventSnowAccumMMLabel,
            "impacts": this.langStore.eventImpactLabel,
            "impactsCodes": this.langStore.eventImpactLabel + ` (${this.langStore.codeLabel})`,
            "description": this.langStore.descriptionLabel,
            };
    }
    
    get columns() {
        const result = [];
        for (const [key, value] of Object.entries(this.columnTitles)) {
            result.push( {name: key, title: value});
        }
        return result;
    }

    get gridState(): ListProfile {
        return {
            columnOrder: this.columnOrder,
            columnsInfo: Object.entries(this.columnsInfo).map(([key, values]) => {return {columnName: key, ...values}}),
            sorting: this.sorting,
            VERSION,
        }
    }

    setGridState(gridState: ListProfile) {
        if (gridState.VERSION <= VERSION) {
            if (gridState.columnsInfo) {
                gridState.columnsInfo.forEach(x => {
                    this.columnsInfo[x.columnName] = {sortingEnabled: x.sortingEnabled, width: x.width, isHidden: x.isHidden};
                })
            }
            if (gridState.columnOrder) {
                this.columnOrder = gridState.columnOrder;
            }
            if (gridState.sorting) {
                this.sorting = gridState.sorting;
            }
        }
    }

    eventFormat (e: Event) {
        return {
            id: e.id,
            eventTime: e.eventTime,
            eventTypes: e.eventTypes.map(x => x.id).join(" "),
            temporalPrecisionSeg: e.temporalPrecisionSeg,
            totalDurationSeg: e.totalDurationSeg,
            description: e.description,
            references: e.references?.map((x) => {
                return x.link
            }),
            impacts: e.impacts?.map((x) => {
                return x[this.langStore.fieldNameLang];
            }),
            impactsCodes: e.impacts?.map((x) => {
                return x.id;
            }),
            source: e.source.id,
            confidence: e.confidence?.id,
            email: e.author.email,
            hasMedia: e.images && e.images.length,
            name: e.author.name,
            latitude: e.latitude ? e.latitude : e.city?.latitude,
            longitude: e.longitude ? e.longitude : e.city?.longitude,
            city: e.city?.name,
            state: e.state?.name,
            country: e.country?.name,
            spacialPrecisionMeters: e.spacialPrecisionMeters,
            location: e.location,
            detailedLocation: e.detailedLocation,
            hailMaxDiameterMM: e.hailMaxDiameterMM,
            windSpeedKmH: e.windSpeedKmH,
            windSpeedMaxKmH: e.windSpeedMaxKmH,
            windDirectionDegrees: e.windDirectionDegrees,
            precipitationAccumMM: e.precipitationAccumMM,
            snowAccumMM: e.snowAccumMM,
            event: e,
        };
    }
}
