/**
 * @author Fido García <garciafido@gmail.com>
 */
import React from 'react';
import {observer} from "mobx-react";
import {PageLayout, PageLayoutProps} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {ErrorDialog, ProcessingDialog} from "../../../common";
import {Entity, ListEntityStore} from "../../types";

export interface ListEntityPageProps<T extends Entity> extends PageLayoutProps {
  store: ListEntityStore<T>;
  children: JSX.Element | JSX.Element[];
}

export const ListEntityPage = observer(<T extends Entity>(props: ListEntityPageProps<T>) => {
    const rootStore = useRootStore();
    const {langStore, router} = rootStore;
    const {store, children, navigationElements, secondaryToolbox} = props;

    if (store.status === "uninitialized") {
        store.setCallbackUpdateParams(router?.params);
        store.initialize();
    }

    let processing = false;
    if (store.status === "processing") {
        processing = true;
    }

    return (
      <div>
        <PageLayout
            navigationElements={navigationElements}
            secondaryToolbox={secondaryToolbox}
        >
            {children}
        </PageLayout>

        <ProcessingDialog
          open={processing && !store.errorDialogOpen}
          title={store.processingTitle || langStore.processingTitle}
          description={store.processingDescription || langStore.processingDescription}
        />

        <ErrorDialog
          open={store.errorDialogOpen}
          title={store.errorTitle || langStore.pageErrorTitle}
          description={store.errorDescription}
          onClose={() => store.onCloseErrorDialog()}
        />
      </div>
    );
});
