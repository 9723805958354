import {
  NewUserMutationVariables,
  UpdateUserMutationVariables,
} from "../../../api/graphql";
import {getApi} from "../../../api/apiClient";
import {
  buildCreateOne,
  buildUpdateOne,
} from "../../common/services/crudServices";
import {RootStore} from "../../../stores";

export const userService = {
  createOne: buildCreateOne(
    (newUserVariables: NewUserMutationVariables) =>
      async (rootStore: RootStore) => {
        const api = getApi(rootStore.authStore);
        const result = await api.NewUser(newUserVariables);
        return result.userCreate.id;
      }
  ),

  updateOne: buildUpdateOne(
    (updateUserVariables: UpdateUserMutationVariables) =>
      async (rootStore: RootStore) => {
        const api = getApi(rootStore.authStore);
        const result = await api.UpdateUser(updateUserVariables);
        return result.userUpdate.id;
      }
  ),

  getOne: async (rootStore: RootStore, id: string) => {
    const api = getApi(rootStore.authStore);
    const result = await api.GetUser({id});
    return result.userOne;
  },
}


// export class UserServices implements EntityService<User> {
//   private readonly rootStore: RootStore;
//
//   constructor(rootStore: RootStore) {
//     this.rootStore = rootStore;
//   }
//
//
//   async getOneEmpty (params: QueryParams) {
//     return {
//       active: true,
//       email: "",
//       id: "",
//       name: "",
//       organizations: [],
//       roles: [],
//     };
//   }
//
//   validateBeforeCreate (entity: Entity) {
//   }
//
//   validateBeforeUpdate (entity: Entity) {
//
//   }
// }
