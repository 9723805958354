import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {Organization} from "../../api/graphql";
import {StoreStatus} from "../../stores/types";

export class OrganizationStore {
  private rootStore: RootStore;
  organizationList: Organization[] = [];
  status: StoreStatus = "uninitialized";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  * loadOrganizations(): any {
      let data;
      const api = getApi(this.rootStore.authStore);
      data = yield api.OrganizationList();
      this.organizationList = data.organizationList;
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";
        yield this.loadOrganizations();
        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }
}