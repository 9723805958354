import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
    InputAdornment,
    TextField,
} from "@mui/material";
import {observer} from "mobx-react";
import {useRootStore} from "../../../stores/contexts";

export type SearchBoxProps = {
    value: string;
    onChange?: (value: string) => void;
    label?: string;
    id?: string;
    readOnly?: boolean;
    autoComplete?: string;
}

export const SearchBox: React.FC<SearchBoxProps> = observer((
    {
        label,
        value,
        onChange,
        id,
        autoComplete,
    }) => {
  const rootStore = useRootStore();
  const {
        prefStore,
  } = rootStore;

  const handleOnChange = onChange ? (value: string) => onChange(value) : () => {};

  return (
    <TextField
        id={!id ? "search-field" : id}
        type={"search"}
        variant={"standard"}
        sx={{marginTop: "5px"}}
        size={prefStore.toolbarSize}
        placeholder={!label ? "Buscar..." : label}
        autoComplete={autoComplete ? autoComplete : prefStore.autoComplete}
        value={value}
        onChange={(event) => handleOnChange(event.target.value)}
        InputProps={{
          autoFocus: true,
          startAdornment: (
           <InputAdornment position="start">
              <SearchIcon />
           </InputAdornment>
          ),
         }}
      />
  );
});
