import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import UploadFile from '@mui/icons-material/Upload';
import {useRootStore} from "../stores/contexts";

type Props = {
    onChangeFile: (file: any) => void;
    acceptFileTypes?: string;
    disabled?: boolean;
}

export const FileUploadButton: React.FC<Props> = observer(({onChangeFile, acceptFileTypes,disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
        langStore,
    } = rootStore;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0)
         onChangeFile(event.target.files[0]);
    }

    return (
        <div>
            <label>
                <input
                    style={{ display: "none" }}
                    id="file-upload"
                    name="upload-photo"
                    accept={acceptFileTypes}
                    onChange={handleFileChange}
                    type="file"
                />
                <Button
                    size={prefStore.toolbarSize}
                    variant="outlined" startIcon={<UploadFile />}
                    disabled={disabled === undefined ? false : disabled}
                    component="span">
                    {langStore.uploadFileLabel}
                </Button>{" "}
            </label>
        </div>
    );
});
