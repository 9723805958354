import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/FormatListNumbered';
import GradingIcon from '@mui/icons-material/Grading';
import {observer} from "mobx-react";
import {useRootStore} from "../../stores/contexts";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type Props = {
    open: boolean,
    onClose: () => void,
    headerHeight?: number,
}

export const DrawerHeader = styled('div')(({ theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const SideBar: React.FC<Props> =  observer(({open, onClose, headerHeight= undefined}) => {
  const theme = useTheme();
    const rootStore = useRootStore();
    const { breadcrumbsStore, langStore, authStore } = rootStore;

  return (
      <Drawer variant="permanent" open={open}>
          <DrawerHeader style={{height: headerHeight, minHeight: headerHeight}}>
              <IconButton onClick={onClose}>
                {open ? theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon /> : <div/>}
              </IconButton>
        </DrawerHeader>

        <Divider />

        {authStore.hasPermissions("user_view") && <ListItem button key={"Usuarios"} onClick={() => breadcrumbsStore.push("/user/list", true)}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Usuarios"} />
        </ListItem>}

        <Divider />

        <List>
          {authStore.hasPermissions("event_create") &&
            <ListItem button key={langStore.newReportLabel} onClick={() => breadcrumbsStore.push("/event/new", true)}>
              <ListItemIcon>
                <PostAddIcon />
              </ListItemIcon>
              <ListItemText primary={langStore.newReportLabel} />
            </ListItem>}

          {authStore.hasPermissions("event_view") &&
          <ListItem button key={langStore.listReportLabel} onClick={() => breadcrumbsStore.push("/event/list", true)}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={langStore.listReportLabel} />
          </ListItem>}

          {authStore.hasPermissions("event_view", "event_update") &&
          <ListItem button key={langStore.eventReviewLabel} onClick={() => breadcrumbsStore.push("/event/review", true)}>
            <ListItemIcon>
              <GradingIcon />
            </ListItemIcon>
            <ListItemText primary={langStore.eventReviewLabel} />
          </ListItem>}
        </List>
      </Drawer>
  );
});