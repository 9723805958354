import {EventFilter} from "../../api/graphql";

export const groupOperations: ('and' | 'or' | 'notAnd' | 'notOr')[] | undefined = ['and', 'or', 'notAnd', 'notOr'];

export function mapFilterOperatorValue(fieldName: string, filter: any) {
    const operator = Object.keys(filter)[0];

    if (operator === "contains") {
      return {[fieldName]: {contains: filter[operator], mode: "insensitive"}};
    }
    if (operator === ">") {
      return {[fieldName]: {gt: filter[operator]}};
    }
    if (operator === "<") {
      return {[fieldName]: {lt: filter[operator]}};
    }
    if (operator === ">=") {
      return {[fieldName]: {gte: filter[operator]}};
    }
    if (operator === "<=") {
      return {[fieldName]: {lte: filter[operator]}};
    }
    if (operator === "startswith") {
      return {[fieldName]: {startsWith: filter[operator], mode: "insensitive"}};
    }
    if (operator === "endswith") {
      return {[fieldName]: {endsWith: filter[operator], mode: "insensitive"}};
    }
    if (operator === "=") {
      // TODO Filter by null
      if (filter[operator] === "") {
        return {};
      }
      if (filter[operator] === null) {
        return {};
      }
      return {[fieldName]: {equals: filter[operator]}};
    }
    if (operator === "<>") {
      // TODO Filter by null
      if (filter[operator] === "") {
        return {};
      }
      if (filter[operator] === null) {
        return {};
      }
      return {[fieldName]: {not: filter[operator]}};
    }
    if (operator === "notcontains") {
      return {NOT: {[fieldName]: {contains: filter[operator], mode: "insensitive"}}};
    }
    return {[fieldName]: filter};
}

export function mapFieldFilter(filter: any): any {
    const fieldName = Object.keys(filter)[0];
    if (["email", "name"].includes(fieldName)) {
      const filterExpression = mapFilterOperatorValue(fieldName, filter[fieldName]);
      return {author: filterExpression};
    }
    if (["city", "state", "country"].includes(fieldName)) {
      const filterExpression = mapFilterOperatorValue("name", filter[fieldName]);
      return {[fieldName]: filterExpression};
    }
    if (["references"].includes(fieldName)) {
      const filterExpression = mapFilterOperatorValue("link", filter[fieldName]);
      return {[fieldName]: {some: filterExpression}};
    }
    if (["hasMedia"].includes(fieldName)) {
      if (filter[fieldName]["="]) {
        return {images: {some: {}}};
      }
      return {images: {none: {}}};
    }
    if (["source", "confidence"].includes(fieldName)) {
      const operation = filter[fieldName];
      const operator = Object.keys(operation)[0];
      operation[operator] = operation[operator].id
      const filterExpression = mapFilterOperatorValue("id", filter[fieldName]);
      return {[fieldName]: filterExpression};
    }
    if (["impacts", "eventTypes"].includes(fieldName)) {
      const operation = filter[fieldName];
      const operator = Object.keys(operation)[0];
      if (operation[operator] === null || operation[operator] === "") {
        if (operator === '=') {
          return {[fieldName]: {none: {}}};
        }
        return {[fieldName]: {some: {}}};
      }
      operation[operator] = operation[operator].id
      const filterExpression = mapFilterOperatorValue("id", filter[fieldName]);
      return {[fieldName]: {some: filterExpression}};
    }

    if (["impactTypes"].includes(fieldName)) {
      const operation = filter[fieldName];
      const operator = Object.keys(operation)[0];
      operation[operator] = operation[operator].id
      if (operator === "=") {
        return {impacts: {some: {impactType: {id: {equals: operation[operator]}}}}};
      } else {
        return {impacts: {none: {impactType: {id: {equals: operation[operator]}}}}};
      }
    }
    return mapFilterOperatorValue(fieldName, filter[fieldName]);
  }

export function mapFilter(filter: any): EventFilter {
    if (filter === null) {
      return {};
    }
    if (filter[0] === "!") {
      return {NOT: [mapFilter(filter[1])]}
    }
    if (filter[1] === "and") {
      return {AND: [mapFilter(filter[0])].concat(filter.filter((x: any) => x !== "and")).slice(1).map((x: any) => mapFilter(x))}
    }
    if (filter[1] === "or") {
      return {OR: [mapFilter(filter[0])].concat(filter.filter((x: any) => x !== "or")).slice(1).map((x: any) => mapFilter(x))}
    }
    return mapFieldFilter({[filter[0]]: {[filter[1]]: filter[2]}});
}

