export interface RelatedOption {
  id: string;
  label_es: string;
  label_en: string;
  label_pt: string;
  parentId: string | null;
}

export type CountryCodes = "AR" | "BR" | "UY" | "CL";
export const stateLabelByCountry = {"AR": "Provincia", "BR": "Estado", "UY": "Departamento", "CL": "Provincia"}


export type LanguageCodes = "en" | "es" | "pt";

export type ErrorMessageItem = { name: string, error: string };

export type ServerErrorMessage = {message_en: string, message_es: string, message_pt: string, code: string};

export type UploadedFileType = {
  ticket: string
  filename: string,
  mimetype: string,
  encoding: string,
};
