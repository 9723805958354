import React from 'react';
import {observer} from "mobx-react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
    Paper,
} from "@mui/material";
import {EventImpacts} from "./EventImpacts";
import {EventInformationSource} from "./EventInformationSource";
import {EventType} from "./EventType";
import {EventLocation} from "./EventLocation";
import {useRootStore} from "../../../../stores/contexts";
import {EventTime} from "./EventTime";
import {EventDuration} from "./EventDuration";
import {EventTemporalPrecision} from "./EventTemporalPrecision";
import {EventDetailedLocation} from "./EventDetailedLocation";
import {EventLatitudeLongitude} from "./EventLatitudeLongitude";
import {EventSpatialPrecision} from "./EventSpatialPrecision";
import {EventHailDiameter} from "./EventHailDiameter";
import {EventWindSpeed} from "./EventWindSpeed";
import {EventMaxWindSpeed} from "./EventMaxWindSpeed";
import {EventWindDirection} from "./EventWindDirection";
import {EventSnowAccum} from "./EventSnowAccum";
import {EventPrecipitationAccum} from "./EventPrecipitationAccum";
import {Alert} from "@mui/material";
import {EventReferences} from "./EventReferences";
import {EventDescription} from "./EventDescription";
import {ErrorMessageItem} from "../../../../types";
import {EventNewCard} from "./EventNewCard";
import {EventImages} from "./EventImages";

export const NewEvent = observer(() => {
    const rootStore = useRootStore();
    const {langStore, authStore, eventNewStore} = rootStore;

    const sxCards = {minWidth: 275, m: 2, backgroundColor: "darkgray"};

    const errors = eventNewStore.formErrors.map((e: ErrorMessageItem, index) => {
        return <Alert key={index} severity="error" action={
            <Button color="inherit" size="small" onClick={() => eventNewStore.navigateToError(e.name)}>
                {e.name}
            </Button>
        }>
            {`${e.error}`}
        </Alert>
    })

    let editInformation;
    if (eventNewStore.event) {
        editInformation = (
            <div>
                <EventNewCard title={langStore.reviewerTitle}>
                    <Typography variant="h6" gutterBottom component="div">
                        {authStore.me?.name}
                    </Typography>
                    <Typography gutterBottom component="div">
                        {eventNewStore.event.author.organizations.map(x => x.name).join(", ")}
                    </Typography>
                    <div>
                        <Typography variant="body2" color="text.secondary" display="inline">
                            {langStore.eventIdLabel + ": "}
                        </Typography>
                        <Typography display="inline">
                            {eventNewStore.event.id}
                        </Typography>
                    </div>
                </EventNewCard>
                <EventNewCard title={langStore.reporterLabel}>
                    <Typography variant="h6" gutterBottom component="div">
                        {eventNewStore.event.author.name}
                    </Typography>
                    <Typography gutterBottom component="div">
                        {eventNewStore.event.author.organizations.map(x => x.name).join(", ")}
                    </Typography>
                </EventNewCard>
            </div>
        );
    } else {
        editInformation = (
            <EventNewCard title={langStore.reporterLabel}>
                <Typography variant="h6" gutterBottom component="div">
                    {authStore.me?.name}
                </Typography>
                <Typography gutterBottom component="div">
                    {authStore.me?.email}
                </Typography>
                <Typography gutterBottom component="div">
                    {authStore.me?.organizations.map(x => x.name).join(", ")}
                </Typography>
            </EventNewCard>
        );
    }

    return (
            <Grid
                id={"TopOfContent"}
                container
                spacing={0}
                direction="column"
                bgcolor="lightgray"
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={{pt: 1}}
                     bgcolor="lightgray"
                     alignItems="center"
                     justifyContent="center">

                    <Card id={langStore.dataSourceSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.dataSourceSectionTitle}
                            </Typography>
                            {editInformation}
                            <EventInformationSource/>
                            <EventReferences/>
                            <EventImages/>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventLocalizationSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventLocalizationSectionTitle}
                            </Typography>
                            <EventLocation/>
                            <EventLatitudeLongitude/>
                            <EventDetailedLocation/>
                            <EventSpatialPrecision/>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventTimeSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventTimeSectionTitle}
                            </Typography>
                            <EventTime/>
                            <EventTemporalPrecision/>
                            <EventDuration/>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventMagnitudeSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventMagnitudeSectionTitle}
                            </Typography>
                            <EventType/>
                            <EventHailDiameter/>
                            <EventWindSpeed/>
                            <EventMaxWindSpeed/>
                            <EventWindDirection/>
                            <EventPrecipitationAccum/>
                            <EventSnowAccum/>
                        </CardContent>
                    </Card>

                    <Card id={langStore.eventImpactSectionTitle} sx={{...sxCards}} square={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {langStore.eventImpactSectionTitle}
                            </Typography>
                            <EventImpacts/>
                            <EventDescription/>
                        </CardContent>
                    </Card>
                </Box>

                <EventNewCard id={langStore.sendReportLabel} title={""}>
                    <Box>
                        {errors}
                    </Box>
                    <Paper sx={{m: 1, p: 2}} elevation={10}>
                        <Button disabled={!eventNewStore.canSendReport}
                                onClick={() => eventNewStore.sendReport()}>{langStore.sendReportLabel}</Button>
                        <Typography color={'darkred'} gutterBottom variant="h6" component="div">
                            {eventNewStore.saveErrorMessage[langStore.errorMessageField]}
                        </Typography>
                        <Typography hidden={eventNewStore.savedEventId === ""} gutterBottom component="div">
                            {eventNewStore.event ? `${langStore.updateEventAction} ${eventNewStore.event.id}` : `${langStore.newEventRegistered}. ID: ${eventNewStore.savedEventId}`}
                        </Typography>
                    </Paper>
                    <Paper style={{paddingLeft: 4, marginTop: 10}} elevation={0}>
                        <Button onClick={() => eventNewStore.clearReport()}>{langStore.clearReportLabel}</Button>
                    </Paper>
                </EventNewCard>
            </Grid>
    );
});