import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {ImpactType} from "../../api/graphql";
import {StoreStatus} from "../../stores/types";

export class ImpactTypeStore {
  private rootStore: RootStore;
  private impactTypeAll: ImpactType[] | null = null;
  status: StoreStatus = "uninitialized";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get ImpactTypes() {
    if (this.impactTypeAll !== null) {
      return this.impactTypeAll;
    }
    return [];
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";
        let data;
        const api = getApi(this.rootStore.authStore);
        data = yield api.ImpactTypes();
        this.impactTypeAll = data.impactTypeAll;
        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }
}