import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {InformationSource} from "../../api/graphql";
import {RelatedOption} from "../../types";
import {StoreStatus} from "../../stores/types";

export class InformationSourceStore {
  private rootStore: RootStore;
  private informationSourceAll: InformationSource[] | null = null;
  status: StoreStatus = "uninitialized";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get informationSourceOptions(): RelatedOption[] {
    if (this.informationSourceAll !== null) {
      return this.informationSourceAll
          .map((x) => {
            return {id: x.id, label_es: x.name_es, label_en: x.name_en, label_pt: x.name_pt, parentId: null};
          })
          .sort((a: RelatedOption, b: RelatedOption) => (a.id > b.id) ? 1 : -1);
    }
    return [];
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";
        let data;
        const api = getApi(this.rootStore.authStore);
        data = yield api.InformationSources();
        this.informationSourceAll = data.informationSourceAll;
        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }
}