import * as React from "react";
import {observer} from "mobx-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    PaperProps
} from "@mui/material";
import Draggable1 from 'react-draggable';
const Draggable: any = Draggable1;

type Props = {
    title: string,
    description: string,
    open: boolean,
    onConfirm: () => void,
    onCancel?: () => void,
    onClose?: () => void,
}

export const ConfirmCancelDialog: React.FC<Props> = observer(({title, description, open, onConfirm, onCancel, onClose}) => {
    const PaperComponent = (props: PaperProps) => {
      const nodeRef = React.useRef(null);
      return (
        <Draggable
          nodeRef={nodeRef}
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper ref={nodeRef} {...props} />
        </Draggable>
      );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose ? onClose : onCancel}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {onCancel && <Button autoFocus onClick={onCancel}>
                    Cancela
                </Button>}
                <Button onClick={onConfirm}>Confirma</Button>
            </DialogActions>
        </Dialog>
    );
});