import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {ListEvent} from "../";
import {ProcessingDialog} from "../../../common";
import {ErrorDialog} from "../../../common";
import {ListToolbox} from "../../../common/ListToolbox";

export const EventListPage = observer(() => {
    const rootStore = useRootStore();
    const {eventListStore, langStore} = rootStore;

    if (eventListStore.status === "uninitialized") {
        eventListStore.initialize();
    }

    let processing = false;
    if (eventListStore.status === "processing") {
        processing = true;
    }

    const listToolbox = <ListToolbox
            paginatorProps={{
                page: eventListStore.currentPage,
                totalRows: eventListStore.events.length,
                rowsPerPage: eventListStore.pageSize,
                hasMorePages: eventListStore.hasMorePages,
                onPageChange: (newPage: number) => eventListStore.onPageChange(newPage),
                onPageSizeChange: (pageSize: number) => eventListStore.onPageSizeChange(pageSize),
            }}
            exportToExcel={() => eventListStore.toExcel()}
            saveProfile={() => eventListStore.saveProfile()}
            columnChooserProps={{
                columns: eventListStore.grid.columnChooserInfo,
                onChoose: (name: string) => eventListStore.grid.toggleColumnHidden(name),
                anchorMenu: eventListStore.anchorMenu,
                setAnchorMenu: (element) => eventListStore.setAnchorMenu(element),
            }}
        />;

    return (
        <div>
            <PageLayout
                navigationElements={{
                    links: [
                        {id: langStore.reportingMap, label: langStore.reportingMap},
                        {id: "EventsFiltering", label: langStore.reportsFilterTitle},
                        {id: "EndOfListing", label: langStore.endOfListing},
                    ],
                }}
                secondaryToolbox={listToolbox}
            >
                <ListEvent />
            </PageLayout>
            <ProcessingDialog open={processing && !eventListStore.errorDialogOpen} title={eventListStore.processingTitle} description={eventListStore.processingDescription}/>
            <ErrorDialog open={eventListStore.errorDialogOpen} title={langStore.errorTitle} description={eventListStore.errorDescription} onClose={() => eventListStore.setErrorDialogOpen(false)} />
        </div>
    );
});
