import {PageLayout} from "../layout";
import {LoginForm} from "../../auth";
import React from "react";
import {observer} from "mobx-react";

export const LoginPage = observer(() => {
    return (
        <PageLayout>
            <LoginForm />
        </PageLayout>
    );
});
