import React from 'react'
import {
    Grid,
    Paper,
    Avatar,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton, FormControl
} from "@mui/material";
import {LockOutlined as LockOutlinedIcon} from "@mui/icons-material";
import {observer} from "mobx-react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useRootStore} from "../../stores/contexts";

export const LoginForm = observer(() => {
    const rootStore = useRootStore();

    const paperStyle={padding :20, height:'300px', width:280, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}

    const auth = rootStore.authStore;
    const lang = rootStore.langStore;

    const handleLogInClick = async () => {
        await auth.login();
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        auth.setEmail(event.target.value);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        auth.setPassword(event.target.value);
    }

    const handleClickShowPassword = () => {
        auth.toggleShowPassword();
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid alignItems="center">
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <h2>{lang.logInLabel}</h2>
                </Grid>
                    <FormControl>
                        <TextField
                            autoComplete={"email"}
                            type={"email"}
                            name={"email"}
                            id={"email"}
                            sx={{m:1}}
                            label='Email'
                            onChange={handleChangeEmail}
                            placeholder='Enter username'
                            fullWidth
                            required>
                            {auth.email}
                        </TextField>

                        <TextField
                            autoComplete={"current-password"}
                            name={"password"}
                            id={"password"}
                            sx={{m:1}}
                            label={lang.passwordLabel}
                            onChange={handleChangePassword}
                            placeholder='Enter password'
                            type={auth.showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={"end"}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {auth.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>),
                            }}
                            fullWidth
                            required>
                                {auth.password}
                        </TextField>
                        <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth onClick={handleLogInClick}>{lang.logInLabel}</Button>
                        <Typography color={"red"}>
                            {auth.errorMessage}
                        </Typography>
                    </FormControl>
            </Paper>
        </Grid>
    )
});
