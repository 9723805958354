import {addedStatusList, originalDeletedStatusList} from "../controls/ItemsCRUD";

export type RowCrudStatus = "added" | "added-deleted" | "added-updated"
        | "original-updated" | "original-updated-deleted"
        | "original" | "original-deleted";

export type OneRowCrud<T> = {
    id: string,
    __status__: RowCrudStatus,
} & T;

export type RowsCrud<T> = {
    [name: string]: OneRowCrud<T>;
}

export const removeAttrs = (x: any, attrs: string[]) => {
    for (const a of attrs) {
      delete x[a];
    }
    return x;
}

export const buildRelatedUpdateRows = <T>(relatedCrudRows: RowsCrud<T>) => {
    return {
        remove: Object.values(relatedCrudRows).filter(x => originalDeletedStatusList.includes(x.__status__)).map(x => x.id),
        add: Object.values(relatedCrudRows).filter(x => addedStatusList.includes(x.__status__)).map(x => x.id)
    };
};

export const buildChildrenUpdateRows = <T>(relatedCrudRows: RowsCrud<T>) => {
    return {
        remove: Object.values(relatedCrudRows).filter(x => originalDeletedStatusList.includes(x.__status__)).map(x => x.id),
        add: Object.values(relatedCrudRows).filter(x => addedStatusList.includes(x.__status__)).map(x => {
          return removeAttrs({...x}, ["__status__", "id"])
        })
    };
};

export type GetOne<T, A extends any[]> = (...args: A) => T;
export const buildGetOne = <T, A extends any[]>(getOne: GetOne<T, A>) => function (...args: A) {
  return getOne(...args);
}

export type CreateOne<T, A extends any[]> = (...args: A) => T;
export const buildCreateOne = <T, A extends any[]>(createOne: CreateOne<T, A>) => function (...args: A) {
  return createOne(...args);
}

export type UpdateOne<T, A extends any[]> = (...args: A) => T;
export const buildUpdateOne = <T, A extends any[]>(updateOne: UpdateOne<T, A>) => function (...args: A) {
  return updateOne(...args);
}

export type EntityService<T> = {
    getOne: (...args: any[]) => any;
    // getOneEmpty: (params: QueryParams) => Promise<T>;
    createOne: (...args: any[]) => any;
    updateOne: (...args: any[]) => any;
    // validateBeforeCreate: (entity: T) => null | Promise<Record<string, string>>;
    // validateBeforeUpdate: (entity: T) => null | Promise<Record<string, string>>;
}
