import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, TextField} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventLatitudeLongitude = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
        <EventNewCard title={langStore.eventLatitudeLongitudeTitle}
                      id={langStore.eventLatitudeLongitudeLabel}
                      description={langStore.eventLatitudeLongitudeDescription}
                      errorText={eventNewStore.cityOrLatLonErrorMessage}>
            <FormControl>
                <TextField
                    error={!eventNewStore.isLatitudeValid}
                    helperText={eventNewStore.latitudeErrorMessage}
                    id={langStore.eventLatitudeTitle}
                    sx={{mt:1, p: 1, display:"inline", width: 100}}
                    label={langStore.eventLatitudeLabel}
                    value={eventNewStore.latitude}
                    onChange={(event) => eventNewStore.onChangeEventLatitude(event.target.value)}
                />
                <TextField
                    error={!eventNewStore.isLongitudeValid}
                    helperText={eventNewStore.longitudeErrorMessage}
                    id={langStore.eventLongitudeTitle}
                    sx={{mt:1, p: 1, display:"inline", width: 100}}
                    label={langStore.eventLongitudeLabel}
                    value={eventNewStore.longitude}
                    onChange={(event) => eventNewStore.onChangeEventLongitude(event.target.value)}
                />
            </FormControl>
            <TextField
                error={!eventNewStore.isLatitudeLongitudeValid}
                helperText={eventNewStore.latitudeLongitudeErrorMessage}
                sx={{mt:4, p:2, minWidth: 370}}
                label={langStore.eventLatitudeLongitudeLabel}
                value={eventNewStore.latitudeLongitude}
                onChange={(event) => eventNewStore.onChangeEventLatitudeLongitude(event.target.value)}
            />
        </EventNewCard>
  );
});
