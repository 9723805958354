import * as React from 'react';
import {
    TablePagination,
} from "@mui/material";
import {observer} from "mobx-react";

export type PaginatorProps = {
    page: number,
    totalRows: number,
    rowsPerPage: number,
    hasMorePages: boolean,
    onPageChange: (newPage: number) => void,
    onPageSizeChange: (value: number) => void,
}

export const Paginator: React.FC<PaginatorProps> = observer((
    {
        page,
        hasMorePages,
        rowsPerPage,
        totalRows,
        onPageChange,
        onPageSizeChange,
    }) => {

  return (
    <TablePagination
      component="div"
      style={{marginTop: 0, paddingTop: 0}}
      labelDisplayedRows={() => `${page}`}
      labelRowsPerPage={"Por página:"}
      backIconButtonProps={{disabled: !(page > 1)}}
      nextIconButtonProps={{disabled: !(hasMorePages || page*rowsPerPage < totalRows), color: !(page*rowsPerPage < totalRows) ? "info" : "default"}}
      count={totalRows}
      rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
      page={page-1}
      rowsPerPage={rowsPerPage}
      onPageChange={(event, newPage) => onPageChange(newPage+1)}
      onRowsPerPageChange={(event) => onPageSizeChange(parseInt(event.target.value, 10))}
   />
  );
});
