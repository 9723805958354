import { configure } from 'mobx';
import {RootStore} from "./RootStore";

function initMobX() {
    // Enable strict mode for MobX.
    // This disallows state changes outside of an action.
    configure({ enforceActions: 'observed' });
}

function initStores() {
    return new RootStore();
}

export function initApp() {
    initMobX();
    return initStores();
}