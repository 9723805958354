export const nearestIndex = (coordinate: number, arrayOfCoordinates: number[]) => {
   let curr = arrayOfCoordinates[0], diff = Math.abs(coordinate - curr);
   let index = 0;
   for (let val = 0; val < arrayOfCoordinates.length; val++) {
      let newDiff = Math.abs(coordinate - arrayOfCoordinates[val]);
      if (newDiff < diff) {
         diff = newDiff;
         curr = arrayOfCoordinates[val];
         index = val;
      }
   }
   return index;
};

export const nearestValue = (value: number, arrayOfValues: number[]) => {
   const minArray = arrayOfValues.map(x => Math.abs(x-value));
    const minimum = Math.min(...minArray);
    return arrayOfValues[minArray.indexOf(minimum)];
}
