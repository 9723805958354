import * as React from 'react';

import {observer} from "mobx-react";
import {useRootStore} from "../../../stores/contexts";
import {Event} from "../../../api/graphql";
//import 'devextreme/dist/css/dx.light.css';
import "./FilteredEventList.css";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import {
    SortingState,
    Sorting,
    Column,
} from "@devexpress/dx-react-grid";
import {
    Table,
} from "@devexpress/dx-react-grid-material-ui";
import {TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {EventFilter} from "./EventFilter";
import {Box, Typography} from "@mui/material";
import {GridExpress} from "../../../components";

const TableCell: any = Table.Cell;

type Props = {
    events: Event[],
    reloadedEvent?: Event | null,
    setFilterValue: (filter: any) => void,
    applyFilter: () => void,
    filter: any,
    selectEvent: (rowClickEvent: any) => void,
    pageSize: number,
    currentPage: number,
    setPageSize: (size: number) => void,
    hasMorePages: boolean,
    nextPage: () => void,

    onColumnWidthsChange: (nextColumnWidths: TableColumnWidthInfo[]) => void;
    columnWidths: TableColumnWidthInfo[];
    onColumnOrderChange: (nextOrder: string[]) => void;
    columnOrder: string[];
    viewRow: (id: string) => void;
    editRow: (id: string) => void;
    deleteRow: (id: string) => void;
    columns: Column[];
    onHiddenColumnNamesChange: (hiddenColumnNames: string[]) => void;
    hiddenColumnsNames: string[];
    onSortingChange: (sorting: Sorting[]) => void;
    sorting: Sorting[] | undefined;
    columnExtensions: SortingState.ColumnExtension[] | undefined;
    onSelectionChange: (selection: (number | string)[]) => void;
    eventFormat: (event: Event) => Record<string, any>;
    componentId: string;
};

export const FilteredEventList: React.FC<Props> = observer(({
    events,
    reloadedEvent,
    setFilterValue,
    applyFilter,
    filter,
    selectEvent,
    pageSize,
    currentPage,
    setPageSize,
    hasMorePages,
    nextPage,
    onColumnWidthsChange,
    columnWidths,
    onColumnOrderChange,
    columnOrder,
    viewRow,
    editRow,
    deleteRow,
    columns,
    onHiddenColumnNamesChange,
    hiddenColumnsNames,
    onSortingChange,
    sorting,
    columnExtensions,
    onSelectionChange,
    eventFormat,
    componentId,
}) => {
    const rootStore = useRootStore();
    const {
        langStore,
    } = rootStore;

    let rows: any = [];
    if (events) {
        rows = events.map(e => eventFormat(e));
    }

    const actionColumns = [
        {
          columnName: "__action_view",
          // label: "Ver",
          onClick: (props: any) => viewRow(props.id),
          icon: <ViewIcon />
        },
        {
          columnName: "__action_edit",
          // label: "Editar",
          onClick: (props: any) => editRow(props.id),
          icon: <EditIcon />
        },
        {
          columnName: "__action_delete",
          // label: "Borrar",
          onClick: (props: any) => deleteRow(props.id),
          icon: <DeleteIcon />
        }
      ];

    const bgColor = (confidence: string) => confidence === "VERIFIED" ? "#caefc2" : confidence === "RAW" ? "" : "#e5acac";
    const fontWeight = (id: string) =>  reloadedEvent && id === reloadedEvent.id ? "bold" : "regular";

    const renderLinksCell = (props: any) => {
        const links = props.value;
        if (links) {
            const elements = links.map((link: string, index: any) => {
                return (
                    <a key={index} href={link} target='_blank' rel='noopener noreferrer'>
                        {link}
                    </a>
                )});
            return <TableCell {...props} style={{backgroundColor: bgColor(props.row.confidence), fontWeight: fontWeight(props.row.id)}}>
                <Box border={1} sx={{m: 0, p: 1.5}}>
                    {elements}
                </Box>
            </TableCell>;
        }
        return <div />;
    }

    const renderReferencedNameCell = (props: any) => {
        const ids = props.value;
        if (ids) {
            const labels = ids.map((id: string, index: any) => {
                return (
                      <Typography key={index}>
                        {id}
                      </Typography>
                )});
            return <TableCell {...props} style={{backgroundColor: bgColor(props.row.confidence), fontWeight: fontWeight(props.row.id)}}>
                <Box border={1} sx={{m: 0, p: 1.5}}>
                    {labels}
                </Box>
            </TableCell>
        }
        return <div />;
    }

    const cellComponent = (props:any) => {
        if (["references"].includes(props.column.name)) {
            return renderLinksCell(props);
        } else if (["impacts"].includes(props.column.name)) {
            return renderReferencedNameCell(props);
        }
        return <TableCell {...props} style={{backgroundColor: bgColor(props.row.confidence), fontWeight: fontWeight(props.row.id)}} />;
    }

    return (
        <div id={componentId}>
            <Typography variant={"h6"}>{langStore.reportsFilterTitle}</Typography>
            <Box  sx={{
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 2,
                  mb: 4
                }}>
                <EventFilter filter={filter} setFilterValue={setFilterValue} applyFilter={applyFilter} />
            </Box>

            <Typography variant={"h6"}>{langStore.listReportLabel}</Typography>

            <GridExpress
                    columns={columns}
                    rows={rows}
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnExtensions={columnExtensions}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onSelectionChange={onSelectionChange}
                    cellComponent={cellComponent}
                    actionColumns={actionColumns}
                    columnOrder={columnOrder}
                    onColumnOrderChange={onColumnOrderChange}
                    columnWidths={columnWidths}
                    onColumnWidthsChange={onColumnWidthsChange}
                    onHiddenColumnNamesChange={onHiddenColumnNamesChange}
                    hiddenColumnNames={hiddenColumnsNames}
                />
        </div>
    );
});
