import { Storage } from '@react-force/web-utils';
import {makeAutoObservable} from 'mobx';
import { LsKeys } from './LsKeys';
import { RootStore } from './RootStore';
import {createTheme} from "@mui/material/styles";
import {pink, red} from "@mui/material/colors";
import type {} from '@mui/x-date-pickers/themeAugmentation';

export class PrefStore {
    rootStore: RootStore;
    paletteType = 'light';
    sideBarOpen = false;
    toolbarSize: "small" | "medium" = "medium";
    thousandSeparator: "," | "." = ".";
    decimalSeparator: "," | "." = ",";
    autoComplete = "off";
    controlWidth = 500;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setSideBarOpen(sideBarOpen: boolean) {
        this.sideBarOpen = sideBarOpen;
    }

    // ----- Load from storage -----
    loadFromStorage() {
        this.paletteType = Storage.get(LsKeys.paletteType, 'light');
    }

    // ----- Actions -----
    toggleTheme = () => {
        this.paletteType = this.paletteType === 'light' ? 'dark' : 'light';
        Storage.set(LsKeys.paletteType, this.paletteType);
    };

    // ----- Computed -----
    get theme() {
        const palette = {
            primary: {
                main: '#378672',
            },
            secondary: {
                main: pink.A400,
            },
            error: {
                main: red.A700,
            },
            type: this.paletteType,
            // Initialize background to white (default is #fafafa)
            // This allows pictures with white background to blend in.
            background: {
                default: this.paletteType === 'light' ? '#ffffff' : '#303030',
            },
            // components: {
            //     MuiDatePicker: {
            //       styleOverrides: {
            //         root: {
            //           backgroundColor: 'red',
            //         },
            //       },
            //     },
            // },
        };
        return createTheme({ palette });
    }
}