/**
 * @author Fido García <garciafido@gmail.com>
 */
import {Event} from "../../api/graphql";

export type EventFeatureType = {
  type: "Feature";
  properties: {
    id: string;
  };
  geometry: {
    type: "Point";
    coordinates: [number, number];
  }
}

export const getFeatures = (rows: Event[]): EventFeatureType[] => {
		return rows.map(x=> { return {
			type : "Feature",
			properties: {
				id: x.id,
			  // style: {
				// 	color: "red",        // can be applied to any geometry type
				// 	fill: "red",         // can be applied to "Point/MultiPoint" with the "radius" property and "Polygon/MultiPolygon"
				// 	opacity: 0.5,     // can be applied to "Point/MultiPoint" with the "radius" property  in meters, "LineString/MultiLineString" and "Polygon/MultiPolygon" (only the filled part is affected, not the outline)
				// 	hoverColor: "green",   // the color of the feature on mouse hover; can be applied to any feature
				// 	hoverOpacity: 0.2 // the opacity of the feature on mouse hover; can be applied to any feature except for markers and "Point/MultiPoint" with the "radius" property in pixels
				// },
			},
			geometry: {
				type: "Point",
				coordinates: [x.longitude ? x.longitude : x.city?.longitude, x.latitude ? x.latitude : x.city?.latitude],
			}
		}
		});
}

export const getFeatureCollection = (features: EventFeatureType[]) => {
	return {
		type: "FeatureCollection",
		crs: {
			properties: {
				name: "EPSG:4326",
			},
			type: "name",
		},
		features,
	};
}
