import {makeAutoObservable, runInAction} from 'mobx';
import {RootStore} from '../../stores';
import {WebPageStore, ReturnDataParams, StoreStatus} from "../../stores/types";
import {download, getApi} from "../../api/apiClient";
import {Event, EventFilter, Maybe} from "../../api/graphql";
import {ColumnWidthInfo, Sorting} from "../common/types";
import {nearestValue} from "../../utils";
import {range} from "lodash";
import {mapFilter} from "./filterUtils";
import {excelExport} from "../../stores/excelUtils";
import {downloadFromBlob, getUrlFromBlob} from "./imageUtils";
import {getApiError} from "../../api/apiErrors";
import {ImagesDatesType} from "../../components/satelliteImage/SatelliteImage";

const filterKey = ["EventRevision","Filter"];

export class EventReviewStore implements WebPageStore {
  readonly rootStore: RootStore;
  private navigateToCounter = 0;
  status: StoreStatus = "uninitialized";
  callbackUpdatedParams: any;
  storeId: string = "";
  pageSize = 500;
  hasMorePages = true;
  where: EventFilter | null = null;
  nextValueOfSortedColumn: string | null = null;
  lastSortedColumn: string | null = null;
  events: Event[] = [];
  reloadedEvent: Event | null = null;
  currentEvent: Event | null = null;
  idToDelete: string = "";
  deleteDialogOpen: boolean = false;
  rowsSelection: (string | number)[] = [];
  currentPage = 1;
  filter: any = null;
  currentImageIndex: number = 0;
  errorDialogOpen: boolean = false;
  errorTitle: string = "Error";
  errorDescription: string = "Hubo un error";
  disablePreviousEvent = true;
  disableNextEvent = true;
  navigateTo = "";
  navigateToHash = "";
  scaleImage = 1;
  currentImage: string = "";
  playing = false;
  arrayOfDates: ImagesDatesType = [];
  anchorMenu: null | HTMLElement = null;

  constructor(rootStore: RootStore, instanceId: string) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.storeId = instanceId;
    this.filter = [
      ["confidence", "=", {id: "RAW", label_en: "Raw entry", label_es: "Entrada cruda", label_pt: "Entrada bruta"}],
      "and",
      ["country", "=", rootStore.authStore.me?.organizations[0].country.name]
    ];
    const revisionFilter = rootStore.authStore.getProfile(["reviewProfile", "filter"]);
    if (revisionFilter) {
      this.filter = revisionFilter;
    }
    this.where = mapFilter(this.filter);
    this.grid.setShownColumns(["name", "eventTime", "eventTypes"]);
  }

  destroy() {
  }

  setStatus(status: StoreStatus) {
    this.status = status;
  }

  setCallbackUpdateParams(callbackUpdatedParams: any) {
    this.callbackUpdatedParams = callbackUpdatedParams;
  }

  async returnedData(params: ReturnDataParams) {
    const index = this.events.findIndex(x => x.id === params.id);
    if (index >= 0) {
      runInAction(() => {
        this.reloadEvent(index, params.id);
      })
    }
  }

  setAnchorMenu(anchorMenu: null | HTMLElement) {
    this.anchorMenu = anchorMenu;
  }

  * reloadEvent(index: number, id: string): any {
    try {
      this.setPageStatus("processing");
      this.setPlaying(false);
      const api = getApi(this.rootStore.authStore);
      const data = yield api.GetEvent({id});
      if (this.events[index].id === id) {
        this.events[index] = data.eventOne as Event;
        this.reloadedEvent = this.events[index];
      }
      this.setPageStatus("ok");
    } catch (error) {
      if (error instanceof Error) {
        const apiError = getApiError(error, this.rootStore.langStore);
        let message = apiError.message;
        this.setErrorDialogOpen(true, apiError.title, message);
      } else {
        this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
      }
      this.setPageStatus("error");
    }
  }

  modifyEvent() {
    if (this.currentEvent) {
      this.rootStore.breadcrumbsStore.push("/event/update", false, {
        id: this.currentEvent.id,
        storeId: this.rootStore.getStoreId(),
        routeName: "/event/review",
      });
    }
  }

  setPlaying(value: boolean) {
    this.playing = value;
  }

  setDeleteDialogOpen(value: boolean) {
    this.deleteDialogOpen = value;
  }

  * downloadImage(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    downloadFromBlob(blob, name);
  }

  * showImage(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    this.currentImage = yield getUrlFromBlob(blob);
  }

  // Satellite image

  selectEvent(event: Event) {
    this.currentEvent = event;
    this.currentImage = "";
    const index = this.getCurrentEventIndex();
    this.disablePreviousEvent = index <= 0;
    this.disableNextEvent = index + 1 >= this.events.length;
    const result = this.buildArrayOfDates(new Date(this.currentEvent.eventTime),
      this.currentEvent.temporalPrecisionSeg || undefined);
    this.currentImageIndex = result.nearest;
    this.arrayOfDates = result.dates;
  }

  getCurrentEventIndex() {
    if (this.currentEvent) {
      const currentEvent = this.currentEvent;
      return this.events.findIndex(x => x.id === currentEvent.id);
    }
    return -1;
  }

  nextEvent() {
    if (this.currentEvent) {
      const index = this.getCurrentEventIndex();
      if (index + 1 < this.events.length) {
        this.selectEvent(this.events[index+1]);
      }
    }
  }

  previousEvent() {
    if (this.currentEvent) {
      const index = this.getCurrentEventIndex();
      if (index > 0) {
        this.selectEvent(this.events[index-1]);
      }
    }
  }

  private buildArrayOfDates(date: Date, temporalPrecision: number | undefined) {
    const stringsDate = (date: Date, nearest: boolean = false) => {
      const item = {
        year: date.getUTCFullYear().toString(),
        month: (date.getUTCMonth() + 1).toString().padStart(2, "0"),
        day: date.getUTCDate().toString().padStart(2, "0"),
        hours: date.getUTCHours().toString().padStart(2, "0"),
        minutes: date.getUTCMinutes().toString().padStart(2, "0"),
        uri: "",
        nearest
      };
      item.uri = `https://satelite.cptec.inpe.br/repositoriogoes/goes16/goes16_web/ams_ret_ch13_baixa/${item.year}/${item.month}/S11635388_${item.year}${item.month}${item.day}${item.hours}${item.minutes}.jpg`;
      return item;
    };

    const oldFrequencyDate = new Date (2019, 4, 2, 0, 0, 0, 0);
    const isOldFrequency = oldFrequencyDate.getTime() >= date.getTime();
    const frequency = isOldFrequency ? 15 : 10;

    const nearest = nearestValue(date.getUTCMinutes(), range(0, 60+1, frequency));
    date.setMinutes(nearest);

    let end = 4;
    if (temporalPrecision) {
      const totalFrames = (temporalPrecision / 60) / frequency;
      end = Math.max(Math.min(totalFrames / 2 + 1, 37), 4);
    }

    const baseDates = [
      ...range(1, end).map(i => stringsDate(new Date(date.getTime() - frequency * 60000 * (end-i)))),
      stringsDate(date, true),
      ...range(1, end).map(i => stringsDate(new Date(date.getTime() + frequency * 60000 * i))),
    ];

    return {dates: baseDates, nearest: end-1};
  }

  setCurrentImageIndex(imageIndex: number) {
    this.currentImageIndex = imageIndex;
  }

  setScaleImage(scale: number | undefined) {
    if (scale !== undefined) {
      this.scaleImage = scale;
    }
  }

  setPageStatus(value: StoreStatus) {
    this.status = value;
  }

  * deleteEvent(): any {
    if (this.currentEvent) {
      try {
        this.setPageStatus("processing");
        this.setPlaying(false);
        const api = getApi(this.rootStore.authStore);
        const data = yield api.DeleteEvent({id: this.currentEvent.id});
        this.currentEvent.confidence = data.eventDeleted.confidence;
        this.currentEvent.reviewer = data.eventDeleted.reviewer;
        this.setDeleteDialogOpen(false);
        this.setPageStatus("ok");
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
        }
        this.setPageStatus("error");
      }
    }
  }

  * verifyEvent(): any {
    if (this.currentEvent) {
      try {
        this.setPageStatus("processing");
        this.setPlaying(false);
        const api = getApi(this.rootStore.authStore);
        const data = yield api.VerifiedEvent({id: this.currentEvent.id});
        this.setPageStatus("ok");
        this.currentEvent.confidence = data.eventReviewed.confidence;
        this.currentEvent.reviewer = data.eventReviewed.reviewer;
        return true;
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
        }
        this.setPageStatus("error");
        return false;
      }
    }
  }

  cancelDelete() {
    this.setDeleteDialogOpen(false);
  }

  get canRemoveEvent() {
    return this.rootStore.authStore.hasPermissions("event_remove");
  }

  * doAction(status: "ok" | "edit" | "delete") {
    if (status === "delete") {
      this.setDeleteDialogOpen(true);
    } else if (status === "ok") {
      yield this.verifyEvent();
    } else if (status === "edit") {
      this.modifyEvent();
    }
  }

  get coordinates() {
    if (this.currentEvent) {
      if (this.currentEvent.latitude) {
        return {lat: parseFloat(this.currentEvent.latitude), lon: parseFloat(this.currentEvent.longitude)};
      } else if (this.currentEvent.city?.latitude && this.currentEvent.city?.longitude) {
        return {lat: parseFloat(this.currentEvent.city.latitude), lon: parseFloat(this.currentEvent.city.longitude)};
      }
    }
    return {lat: 35, lon: -115};
  }

  // Pagination

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  setHasMorePages(hasMorePages: boolean) {
    this.hasMorePages = hasMorePages
  }

  setFirstPage(rows: Event[]) {
    this.events = rows;
  }

  appendPage(newRows: Event[]) {
    if (newRows.length > 0) {
      this.events = this.events.concat(newRows);
    }
  }

  * loadFirstPage(): any {
    this.nextValueOfSortedColumn = null;
    this.lastSortedColumn = null;
    this.currentPage = 1;
    const rows = yield this.loadData(1);
    this.setFirstPage(rows);
    this.onPageChange(1);
  }

  * getNextPage(): any {
    const newRows = yield this.loadData(this.currentPage + 1);
    this.appendPage(newRows);
  }

  * onPageChange(newPageNumber: number) {
    if (newPageNumber > this.currentPage && this.hasMorePages) {
      if (newPageNumber * this.pageSize > this.events.length) {
        yield this.getNextPage();
      }
    }
    this.currentPage = newPageNumber;
    const firstIndex = (this.currentPage-1) * this.pageSize;
    if (this.events.length > firstIndex) {
      this.selectEvent(this.events[firstIndex]);
    }
  }

  * onPageSizeChange(pageSize: number) {
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
      yield this.loadFirstPage()
    }
  }

  get eventsOfCurrentPage() {
    const start = (this.currentPage-1) * this.pageSize;
    if (this.events.length > 0) {
      return this.events.slice(start, start + this.pageSize);
    }
    return [];
  }

  setLastFoundKey(keyName: "id") {
    if (this.lastSortedColumn !== keyName) {
      this.lastSortedColumn = keyName;
      this.nextValueOfSortedColumn = null;
    }
  }

  // Filtering

  setFilterValue(filter: any): any {
    if (JSON.stringify(filter) !== JSON.stringify(this.filter)) {
      this.filter = filter;
    }
  }

  * applyFilter() {
    this.where = mapFilter(this.filter);
    yield this.loadFirstPage();
  }

  // Grid

  get grid() {
    return this.rootStore.eventGridStore;
  }

  get columns() {
    return [{name: "__action_view", title: ""}, {name: "__action_edit", title: this.rootStore.langStore.eventReviewLabel}, ...this.grid.columns];
  }

  get hiddenColumns() {
    return this.grid.hiddenColumns;
  }

  get columnOrder() {
    return this.grid.columnOrder;
  }

  get columnExtensions() {
    return this.grid.columnExtensions;
  }

  get sorting() {
    return this.grid.sorting;
  }

  get columnWidths() {
    return this.grid.columnWidths;
  }

  onHiddenColumnChange(names: string[]) {
    this.grid.setHiddenColumns(names);
  }

  onColumnOrderChange(columnOrder: string[]) {
    this.grid.setColumnOrder(columnOrder);
  }

  onColumnWidthsChange(columnWidths: ColumnWidthInfo[]) {
    this.grid.setColumnWidths(columnWidths);
  }

  buildWhere(): Maybe<EventFilter> {
    return this.where;
  }

  * onSortingChange(sorting: Sorting[]) {
    this.grid.setSorting(sorting);
    this.setLastFoundKey(this.sorting[0].columnName as "id");
    yield this.loadFirstPage();
  }

  onSelectionChange(rowsSelection: (string | number)[]) {
    this.rowsSelection = rowsSelection;
  }

  * saveProfile() {
    yield this.rootStore.authStore.saveReviewProfile({...this.grid.gridState, filter: this.filter});
  }

  * toExcel() {
    yield excelExport(this.grid.xlsColumns, this.events.map(e => this.grid.eventFormat(e)), "events.xls", "Events");
  }

  * editRow(id: string) {
    yield this.rootStore.breadcrumbsStore.push(`/event/update`, false, {id: id})
  }

  viewRow(id: string) {
    this.selectEvent(this.events.filter((x) => x.id === id)[0]);
    this.navigateTo = "SatelliteImage";
    this.navigateToHash = (this.navigateToCounter++).toString();
  }

  deleteRow(id: string) {
    this.idToDelete = id;
    this.setDeleteDialogOpen(true);
  }

  // Api callings

  get processingTitle() {
    return this.rootStore.langStore.processingTitle;
  }

  get processingDescription() {
    return this.rootStore.langStore.processingDescription;
  }

  setErrorDialogOpen(value: boolean, title?: string, description?: string) {
    if (title) {
      this.errorTitle = title;
    }
    if (description) {
      this.errorDescription = description;
    }
    this.errorDialogOpen = value;
  }

  * loadData(pageNumber: number): any {
    try {
      this.setPageStatus("processing");
      const api = getApi(this.rootStore.authStore);
      const data = yield api.EventList({
        where: this.buildWhere(),
        pageSize: this.pageSize,
        pageNumber,
        orderBy: this.grid.OrderBy,
        nextValueOfSortedColumn: this.nextValueOfSortedColumn,
        lastSortedColumn: this.lastSortedColumn,
      });

      this.lastSortedColumn = Object.keys(this.grid.OrderBy)[0];
      if (this.lastSortedColumn && data.eventList.length > 0) {
        this.nextValueOfSortedColumn = data.eventList[data.eventList.length - 1][this.lastSortedColumn];
      } else {
        this.nextValueOfSortedColumn = null;
      }
      const rows = data.eventList as Event[];

      if (rows.length > this.pageSize) {
        this.setHasMorePages(true);
        rows.pop();
      } else {
        this.setHasMorePages(false);
      }
      this.setPageStatus("ok");
      return rows
    } catch (error) {
      if (error instanceof Error) {
        const apiError = getApiError(error, this.rootStore.langStore);
        let message = apiError.message;
        this.setErrorDialogOpen(true, apiError.title, message);
      } else {
        this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
      }
      this.setPageStatus("error");
      return false;
    }
  }

  * initialize(): any {
    try {
      this.setPageStatus("processing");
      const {eventTypeStore, impactTypeStore, impactStore, confidenceStore, informationSourceStore, stateStore} = this.rootStore;
      yield confidenceStore.initialize();
      yield impactTypeStore.initialize();
      yield informationSourceStore.initialize();
      yield eventTypeStore.initialize();
      yield impactStore.initialize();
      yield stateStore.initialize();

      if (this.rootStore.authStore.me?.profile?.reviewProfile) {
        this.grid.setGridState(this.rootStore.authStore.me.profile.reviewProfile);
      }

      yield this.loadFirstPage();

      this.setPageStatus("ok");
    } catch (error) {
      this.setPageStatus("error");
      const message = (error instanceof Error) ? error.message : undefined;
      this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
    }
  }

  onCloseErrorDialog(): void {
      this.errorDialogOpen = false;
  }
}
