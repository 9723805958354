import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {ReviewEvent} from "../";
import {ProcessingDialog} from "../../../common";
import {ErrorDialog} from "../../../common";
import {ListToolbox} from "../../../common/ListToolbox";

export const EventReviewPage = observer(() => {
    const rootStore = useRootStore();
    const {eventReviewStore, langStore} = rootStore;

    if (eventReviewStore.status === "uninitialized") {
        eventReviewStore.initialize();
    }

    let processing = false;
    if (eventReviewStore.status === "processing") {
        processing = true;
    }

    const listToolbox = <ListToolbox
            paginatorProps={{
                page: eventReviewStore.currentPage,
                totalRows: eventReviewStore.events.length,
                rowsPerPage: eventReviewStore.pageSize,
                hasMorePages: eventReviewStore.hasMorePages,
                onPageChange: (newPage: number) => eventReviewStore.onPageChange(newPage),
                onPageSizeChange: (pageSize: number) => eventReviewStore.onPageSizeChange(pageSize),
            }}
            exportToExcel={() => eventReviewStore.toExcel()}
            saveProfile={() => eventReviewStore.saveProfile()}
            columnChooserProps={{
                columns: eventReviewStore.grid.columnChooserInfo,
                onChoose: (name: string) => eventReviewStore.grid.toggleColumnHidden(name),
                anchorMenu: eventReviewStore.anchorMenu,
                setAnchorMenu: (element) => eventReviewStore.setAnchorMenu(element),
            }}
        />;

    return (
        <div>
            <PageLayout
                navigationElements={{
                    links: [
                        {id: "SatelliteImage", label: langStore.satelliteImagesTitle},
                        {id: "EventsFiltering", label: langStore.reportsFilterTitle},
                        {id: "EndOfListing", label: langStore.endOfListing},
                    ],
                    navigateTo: eventReviewStore.navigateTo,
                    navigateToHash: eventReviewStore.navigateToHash,
                }}
                secondaryToolbox={listToolbox}
            >
                <ReviewEvent />
            </PageLayout>
            <ProcessingDialog open={processing && !eventReviewStore.errorDialogOpen} title={eventReviewStore.processingTitle} description={eventReviewStore.processingDescription}/>
            <ErrorDialog open={eventReviewStore.errorDialogOpen} title={eventReviewStore.errorTitle} description={eventReviewStore.errorDescription} onClose={() => eventReviewStore.setErrorDialogOpen(false)} />
        </div>
    );
});
