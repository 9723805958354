import * as React from 'react';
import {
    MenuItem, Select, Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";

export const EventWindDirection = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  let windDegrees = "";
  if (eventNewStore.windDirectionDegrees !== "") {
      windDegrees = `${eventNewStore.windDirectionDegrees}º`;
  }

  const options = langStore.eventWindDirectionOptions.map((option, index) => {
      return (
        <MenuItem key={index} value={option.direction}>
            <Typography sx={{m:1, display: "inline"}}>{option.label}</Typography>
        </MenuItem>
      );
  });

  return (
      <EventNewCard title={langStore.eventWindDirectionTitle}
                    disabled={eventNewStore.isDisableWind}>
        <Select
          id="drop-down-precision"
          value={eventNewStore.windDirectionDegrees}
          label={langStore.eventWindDirectionLabel}
          onChange={
              (event) =>
                  eventNewStore.onSelectWindDirectionDegrees(event.target.value)}
          sx={{ m:1, width: 500 }}
        >
            {options}
        </Select>
        <Typography sx={{m:1, display: "inline"}} variant={"h6"}>{windDegrees}</Typography>
      </EventNewCard>
  );
});
