import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";
import {ItemsCRUD} from "../../../common/controls/ItemsCRUD";

export const EventReferences = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
      <EventNewCard title={langStore.eventReferencesTitle}
                    description={langStore.eventReferencesDescription}>
          <ItemsCRUD
              columns={[{name: "link", title: "Link"}, {name: "description", title: langStore.descriptionLabel}]}
              rows={eventNewStore.referencesCrudRows}
              onRestore={(id) => eventNewStore.onRestoreReference(id)}
              onCommitChanges={(changes) => eventNewStore.onReferencesCommitChanges(changes)}
              columnWidths={[{columnName: "link", width: 200}, {columnName: "description", width: 200}]}
              dummyRestoredObservable={eventNewStore.dummyRestoredObservable}
          />
      </EventNewCard>
  );
});
