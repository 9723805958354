import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export function getFloatingBar(closedDrawerWidth: number, drawerWidth: number, boxAppMargins: number) {
    const BoxBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        width: `calc(100% - ${closedDrawerWidth + boxAppMargins * 2}px)`,
        left: closedDrawerWidth + boxAppMargins,
        elevation: 4,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth + boxAppMargins * 2}px)`,
            elevation: 4,
            left: boxAppMargins,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));
    return BoxBar;
}

