/**
 * @author Fido García <garciafido@gmail.com>
 */
import React from "react";
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import {Edit, Cancel} from '@mui/icons-material';

export type ToggleCellProps = {
  style?: React.CSSProperties;
  expanded: boolean;
  onToggle: () => void;
  className?: string;
}

export const ToggleCell: React.FC<ToggleCellProps> = ({
  style,
  expanded,
  onToggle,
  className,
}) => {
  const handleClick = (event: any) => {
    event.stopPropagation();
    onToggle();
  };

  return (
    <TableCell
      className={className}
      sx={{
        textAlign: 'center',
        textOverflow: 'initial',
        paddingTop: 0,
        paddingBottom: 0,
        pl: 1,
      }}
      style={style}
    >
      <IconButton
        onClick={handleClick}
      >
        {
          expanded
            ? <Cancel />
            : <Edit />
        }
      </IconButton>
    </TableCell>
  );
};