//import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import {initApp} from './stores/init';
import {ThemeProvider} from "@mui/material";
import {observer} from "mobx-react-lite";
import { RootStoreContext } from "./stores/contexts";
import { MobxRouter } from './components/my-mobx-router/src';

const store = initApp();
const { prefStore } = store;

export const App = observer(() => {
    return (
        <ThemeProvider theme={prefStore.theme}>
            <RootStoreContext.Provider value={store}>
                  <MobxRouter store={store} />
            </RootStoreContext.Provider>
        </ThemeProvider>
    );
});
