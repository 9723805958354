import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {City} from "../../api/graphql";
import {CityOption} from "../event/EventNewStore";

export class CityStore {
  private rootStore: RootStore;
  private cityPage: City[] | null = null;
  stateId: string = "";
  nameFragment: string = "";
  pageSize = 20;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  * onSelectState(id: string) {
    this.stateId = id;
    yield this.queryCities();
  }

  * onSelectNameFragment(nameFragment: string) {
    this.nameFragment = nameFragment;
    yield this.queryCities();
  }

  setCityPage(cityPage: City[]) {
    this.cityPage = cityPage;
  }

  private async queryCities() {
    let data;
    const api = getApi(this.rootStore.authStore);
    const variables: any = {nameFragment: this.nameFragment, pageSize: this.pageSize};
    if (this.stateId !== "") {
      variables.stateId = this.stateId;
    }
    data = await api.Cities(variables);
    this.setCityPage(data.cityPage as City[]);
  }

  get cityOptions(): CityOption[] {
    if (this.cityPage !== null) {
      return this.cityPage
          .map((x: City) => {
            const label = `${x.name} ${x.type.toUpperCase().includes("LOCALIDAD") || x.type.toUpperCase().includes("CIDADE") ? "" : `[${x.type}]`} (${x.latitude}, ${x.longitude})`;
            return {value: x.id, label: label, stateId: x.state.id};
          })
          .sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);
    }
    return [];
  }
}