import React from 'react';
import {observer} from "mobx-react";
import {PageLayout} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {NewEvent} from "../";
import {ProcessingDialog} from "../../../common";
import {ErrorDialog} from "../../../common";
import {ConfirmCancelDialog} from "../../../common/dialogs/ConfirmCancelDialog";


export const EventNewPage = observer(() => {
    const rootStore = useRootStore();
    const {
        eventNewStore,
        langStore,
        router,
    } = rootStore;

    if (eventNewStore.status === "uninitialized") {
        eventNewStore.initialize();
    }

    let processing = false;
    if (eventNewStore.status === "processing") {
        processing = true;
    }

    return (
        <div>
            <PageLayout
                navigationElements={{
                    links: [
                        {id: "TopOfContent", label: langStore.dataSourceSectionTitle},
                        {id: langStore.eventLocalizationSectionTitle, label: langStore.eventLocalizationSectionTitle},
                        {id: langStore.eventTimeSectionTitle, label: langStore.eventTimeSectionTitle},
                        {id: langStore.eventMagnitudeSectionTitle, label: langStore.eventMagnitudeSectionTitle},
                        {id: langStore.eventImpactSectionTitle, label: langStore.eventImpactSectionTitle},
                        {id: langStore.sendReportLabel, label: langStore.eventFormStatus},
                    ],
                    navigateTo: eventNewStore.navigateTo,
                    navigateToHash: eventNewStore.navigateToHash,
                  }}
            >
                <NewEvent />
            </PageLayout>
            <ConfirmCancelDialog open={eventNewStore.confirmMessage !== ""} title={"Ok"} description={eventNewStore.confirmMessage} onConfirm={() => eventNewStore.onMessageConfirmed()}/>
            <ProcessingDialog open={processing && !eventNewStore.errorDialogOpen} title={eventNewStore.processingTitle} description={eventNewStore.processingDescription}/>
            <ErrorDialog open={eventNewStore.errorDialogOpen} title={eventNewStore.errorTitle} description={eventNewStore.errorDescription} onClose={() => eventNewStore.setErrorDialogOpen(false)} />
        </div>
    );
});
