import React, {useEffect, useRef, useState} from "react";
import {Map, View} from 'ol';
import {OSM} from 'ol/source';
import "ol/ol.css";
import {observer} from "mobx-react";
import GeoJSON from "ol/format/GeoJSON";
import './malvinas/malvinas.css';
import {boundingExtent} from "ol/extent";
import {Fill, Stroke, Style} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {nullToUndefined} from "../../utils";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import {Graticule} from "ol/layer";

const MalvinasLonLat =[-59.489674, -51.741640];
const centerLonLat = [-65, -35];

export type MapProps = {
  featureCollection: any;
  selectFeature: (featureId: string) => void;
  selectedId?: string;
}

const extent = boundingExtent([
  [-115.0, 35.0],
  [-25.0, -56.0],
]);

export const ReportsMap: React.FC<MapProps> = observer((
  {
    featureCollection,
    selectFeature,
    selectedId,
  }) => {
  const [map, setMap] = useState<Map>();
  const [featuresLayer, setFeaturesLayer] = useState<VectorLayer<any>>();
  const mapElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
      properties: {
        name: "PointsLayer",
      },
    });

    const initialMap = new Map({
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new Graticule({
            opacity: 0.6,
            strokeStyle: new Stroke({
              color: 'rgba(10,13,26,0.9)',
              width: 2,
              lineDash: [0.5, 4],
            }),
            showLabels: true,
            wrapX: false,
            intervals: [5, 5],
            targetSize: 12,
            lonLabelFormatter: (grades) => `${grades}`,
            latLabelFormatter: (grades) => `${grades}`,
          }),
          initialFeaturesLayer,
        ],
        target: nullToUndefined(mapElement.current),
        view: new View({
            center: centerLonLat,
            projection: 'EPSG:4326',
            zoom: 4,
            extent,
        }),
        controls: [],
    });

    initialMap.setTarget(nullToUndefined(mapElement.current));
    setMap(initialMap);
    setFeaturesLayer(initialFeaturesLayer);
    initialMap.on('click', (evt) => {
      const feature = initialMap.forEachFeatureAtPixel(evt.pixel,
        function (feature) {
          return feature;
        });
      if (feature) {
        selectFeature(feature.getProperties().id);
      }
    });

    return () => {
      if (!initialMap) return;
      initialMap.setTarget(undefined);
      // destroyMap();
    };
  }, []);

  useEffect(() => {
   // set features to map
    if (map && featuresLayer && featureCollection && featureCollection.features.length > 0) {
      featuresLayer.setSource(
        new VectorSource({
          features: new GeoJSON().readFeatures(featureCollection),
        })
      );
      featuresLayer.setStyle((feature: any, resolution: number) => {
        const isSelected = selectedId === feature.getProperties().id;
        return new Style({
          image: new CircleStyle({
            radius: isSelected ? 7 : 5,
            stroke: isSelected ? new Stroke({color: 'red', width: 3}) : undefined,
            fill: new Fill({color: "blue"}),
          }),
        })});
      map.getView().fit(featuresLayer.getSource().getExtent(), {
        padding: [100,100,100,100]
      })
    }
  },[featureCollection, selectedId]);

  return (
     <div style={{height:'700px',width:'700px'}} ref={mapElement} className="map-container" />
  );
});