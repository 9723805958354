import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, TextField} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventHailDiameter = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  return (
      <EventNewCard disabled={eventNewStore.isDisableHail}
                    title={langStore.eventHailDiameterTitle}
                    description={langStore.eventHailDiameterDescription}>
            <FormControl sx={{display: "inline"}}>
                <TextField
                    error={!eventNewStore.isHailDiameterValid(eventNewStore.hailMaxDiameterCM)}
                    sx={{p: 2, display:"inline", width: 30}}
                    label={langStore.eventHailDiameterLabel}
                    value={eventNewStore.hailMaxDiameterCM}
                    onChange={(event) => eventNewStore.onChangeHailDiameter(event.target.value)}
                />
            </FormControl>
    </EventNewCard>
  );
});
