import React, {useRef} from "react";
import {
    Box,
    IconButton,
    MenuItem, Popover,
    Toolbar,
    Typography
} from "@mui/material";
import {
  AccountCircle,
    LanguageRounded,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {observer} from "mobx-react";
import {useRootStore} from "../../stores/contexts";
import CssBaseline from '@mui/material/CssBaseline';
import {SideBar} from "./SideBar";
import {NavigationBar} from "./NavigationBar";
import {BreadcrumbsBar} from "./BreadcrumbsBar";
import {getAppBar} from "./appBar";
import {ToolBar} from "./ToolBar";

const drawerWidth = 240;
const closedDrawerWidth = 73;
const firstBarTop = 48;

export type NavigationElementsType = {
    links: Record<string, string>[],
    navigateTo?: string,
    navigateToHash?: string,
};

export interface PageLayoutProps {
    children?: JSX.Element | JSX.Element[];
    navigationElements?: NavigationElementsType,
    mainToolbox?: JSX.Element,
    secondaryToolbox?: JSX.Element,
}

export const PageLayout: React.FC<PageLayoutProps> = observer((
{
  children,
  navigationElements,
  mainToolbox,
  secondaryToolbox,
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null);

  const [breadcrumbsHeight, setBreadcrumbsHeight] =  React.useState<number>(0);
  const [mainToolboxHeight, setMainToolboxHeight] =  React.useState<number>(0);
  const [navigationHeight, setNavigationHeight] =  React.useState<number>(0);
  const [listToolboxHeight, setListToolboxHeight] =  React.useState<number>(0);

  const rootStore = useRootStore();
  const { langStore, authStore, prefStore } = rootStore;

  const userIconRef = useRef<SVGSVGElement | null>(null);
  const languageIconRef = useRef<SVGSVGElement | null>(null);
  const AppBar = getAppBar(closedDrawerWidth, drawerWidth);

  const breadcrumbsTop = firstBarTop;
  const mainToolboxTop = breadcrumbsTop + breadcrumbsHeight;
  const navigationTop = mainToolboxTop + mainToolboxHeight;
  const listToolboxTop = navigationTop + navigationHeight;
  const contentTop = listToolboxTop + listToolboxHeight;

  const handleSideBarOpen = () => {
      prefStore.setSideBarOpen(true);
  };

  const handleSideBarClose = () => {
      prefStore.setSideBarOpen(false);
  };

  const settings = authStore.status === "logged" ? [langStore.logOutLabel, langStore.changePasswordLabel] : [langStore.logInLabel];
  const languages =["Castellano", "Português", "English"];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickUserMenu = (itemName: string) => {
    if (itemName === langStore.logInLabel) {
      rootStore.breadcrumbsStore.push("/login");
    } else if (itemName === langStore.changePasswordLabel) {
      rootStore.breadcrumbsStore.push("/change-password", false);
    } else if (itemName === langStore.logOutLabel) {
      authStore.logout();
      rootStore.breadcrumbsStore.push("/");
    }
    else if (itemName === "Toggle theme") {
      handleCloseUserMenu();
      prefStore.toggleTheme();    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleClickLanguageMenu = (itemName: string) => {
    const languageKey = itemName === "English" ? "en" : itemName === "Português" ? "pt" : "es";
    langStore.setLanguage(languageKey);
    handleCloseLanguageMenu();
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const updateBreadcrumbsHeight = (height: number | undefined) => {
    if (height) {
        setBreadcrumbsHeight(height);
    }
  }

  const updateMainToolboxHeight = (height: number | undefined) => {
    if (height) {
        setMainToolboxHeight(height);
    }
  }

  const updateNavigationHeight = (height: number | undefined) => {
    if (height) {
        setNavigationHeight(height);
    }
  }

  const updateListToolboxHeight = (height: number | undefined) => {
    if (height) {
        setListToolboxHeight(height);
    }
  }

  const breadcrumbsBar = <BreadcrumbsBar
          updateHeight={updateBreadcrumbsHeight}
          drawerWidth={drawerWidth}
          breadcrumbsBarTop={breadcrumbsTop}
          closedDrawerWidth={closedDrawerWidth}
          sideBarOpen={prefStore.sideBarOpen}
        />

  let mainToolboxBar;
  if (mainToolbox) {
      mainToolboxBar = <ToolBar
        updateHeight={updateMainToolboxHeight}
        barTop={listToolboxTop}
        drawerWidth={drawerWidth}
        closedDrawerWidth={closedDrawerWidth}
        sideBarOpen={prefStore.sideBarOpen}
      >
        {mainToolbox}
      </ToolBar>;
  }

  let navigationBar;
  if (navigationElements) {
      navigationBar = <ToolBar
        updateHeight={updateNavigationHeight}
        barTop={navigationTop}
        drawerWidth={drawerWidth}
        closedDrawerWidth={closedDrawerWidth}
        sideBarOpen={prefStore.sideBarOpen}
      >
          <NavigationBar
              links={navigationElements.links}
              contentTop={contentTop}
              navigateTo={navigationElements.navigateTo}
              navigateToHash={navigationElements.navigateToHash} />
      </ToolBar>;
  }

  let listToolboxElement;
  if (secondaryToolbox) {
      listToolboxElement = <ToolBar
       updateHeight={updateListToolboxHeight}
       barTop={listToolboxTop}
       drawerWidth={drawerWidth}
       closedDrawerWidth={closedDrawerWidth}
       sideBarOpen={prefStore.sideBarOpen}
      >
          {secondaryToolbox}
      </ToolBar>;
  }

  return(
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={prefStore.sideBarOpen}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSideBarOpen}
            edge="start"
            sx={{
              ...(prefStore.sideBarOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {langStore.appTitle}
          </Typography>

          <div>
            <IconButton
                size={'large'}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenLanguageMenu}
                color={"inherit"}>
               <LanguageRounded  ref={languageIconRef}/>
            </IconButton>
            <Popover
              id="menu-appbar"
              anchorReference="anchorPosition"
              anchorPosition={{
                top: firstBarTop,
                left: window.innerWidth - 10 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguageMenu}
            >
              {languages.map((item) => (
                <MenuItem key={item} onClick={() => handleClickLanguageMenu(item)}>
                  <Typography textAlign="center">{item}</Typography>
                </MenuItem>
              ))}
            </Popover>
          </div>

          <div>
            <IconButton
                size={'large'}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color={"inherit"}>
               <AccountCircle  ref={userIconRef}/>
            </IconButton>
            <Popover
              id="menu-appbar"
              anchorReference="anchorPosition"
              anchorPosition={{
                top: firstBarTop,
                left: window.innerWidth - 10 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((item) => (
                <MenuItem key={item} onClick={() => handleClickUserMenu(item)}>
                  <Typography textAlign="center">{item}</Typography>
                </MenuItem>
              ))}
            </Popover>
          </div>

        </Toolbar>
      </AppBar>

      {breadcrumbsBar}
      {mainToolboxBar}
      {navigationBar}
      {listToolboxElement}

      <SideBar open={prefStore.sideBarOpen} onClose={handleSideBarClose} headerHeight={firstBarTop + breadcrumbsHeight}/>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{marginTop: contentTop}}>
        {children}
      </Box>

    </Box>
    )
});
