import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onClick: () => void;
    disabled?: boolean;
}

export const ExportButton: React.FC<Props> = observer(({onClick, disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
    } = rootStore;

    return (
        <Button
            size={prefStore.toolbarSize}
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={onClick}>
            Exportar
        </Button>
    );
});
