import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {ErrorMessageItem} from "../../types";
import {getApi} from "../../api/apiClient";
import {
  DependentRolesUpdate,
  NewUserMutationVariables,
  Organization,
  Role, UpdateUserMutationVariables,
  User, UserRole
} from "../../api/graphql";
import {StoreStatus} from "../../stores/types";
import {ChangeSet} from "@devexpress/dx-react-grid";
import {
  CrudRows,
} from "../common/controls/ItemsCRUD";
import {getApiError} from "../../api/apiErrors";
import {userService} from "./services/userService";
import {buildRelatedUpdateRows, RowsCrud} from "../common/services/crudServices";
import {OneEntityStore} from "../common/types";
import {emailOrNull, validateEmail} from "../common/utils";

export class UserOneStore implements OneEntityStore<User> {
  readonly rootStore: RootStore;
  private uniqueCounter = 1;
  service = userService;
  callbackUpdatedParams: any = undefined;
  dummyRestoredObservable: number = 0;

  email: string = "";
  name: string = "";
  active: boolean = true;
  roles: Role[] = [];
  selectedRoleId: string = "";
  selectedOrganizationId: string = "";
  rolesCrudRows: RowsCrud<Role> = {};
  organizations: Organization[] = [];
  organizationsCrudRows: RowsCrud<Organization> = {};

  status: StoreStatus = "uninitialized";
  storeId: string = "";
  entity: User | null = null;
  contentTop: number = 0;
  originalError: any;
  errorDialogOpen: boolean = false;
  errorTitle: string = "Error";
  errorDescription: string = "Hubo un error";
  navigateTo = "";
  navigateToHash = "";

  constructor(rootStore: RootStore, instanceId: string) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.storeId = instanceId;
  }

  destroy() {
    // this object will be unusable
  }

  setSelectedRoleId(id: string) {
    this.selectedRoleId = id;
  }

  setSelectedOrganizationId(id: string) {
    this.selectedOrganizationId = id;
  }

  * setEditedRow(row: any) {
    if (row && row.id) {
      yield this.initialize(row.id);
    } else {
      yield this.initialize();
    }
  }

  setStatus(status: StoreStatus) {
    this.status = status;
  }

  onCloseErrorDialog(): void {
    this.errorDialogOpen = false;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setName(value: string) {
    this.name = value;
  }

  setActive(value: boolean) {
    this.active = value;
  }

  navigateToError(id: string) {
    this.navigateTo = id;
    this.navigateToHash = (this.uniqueCounter++).toString();
  }

  setContentTop(contentTop: number) {
    this.contentTop = contentTop;
  }

  get emailIsValid() {
    const normalizedEmail = emailOrNull(this.email);
    if (normalizedEmail) {
      this.email = normalizedEmail;
    }
    return normalizedEmail !== null;
  }

  get canSaveUser() {
    return this.formErrors.length === 0;
  }

  onRolesCommitChanges(changes: ChangeSet) {
    if (changes.deleted) {
      this.onDeleteRole(changes.deleted[0].toString());
    } if (changes.added) {
      const role = this.rootStore.roleStore.roleList.find(x=>x.id === this.selectedRoleId);
      if (role) {
        this.rolesCrudRows[role.id] = {...role, __status__: "added"};
      }
    }
    this.selectedRoleId = "";
  }

  onOrganizationsCommitChanges(changes: ChangeSet) {
    if (changes.deleted) {
      this.onDeleteOrganization(changes.deleted[0].toString());
    } if (changes.added) {
      const organization = this.rootStore.organizationStore.organizationList.find(x=>x.id === this.selectedOrganizationId);
      if (organization) {
        this.organizationsCrudRows[organization.id] = {...organization, __status__: "added"};
      }
    }
    this.selectedOrganizationId = "";
  }

  onRestore(id: string, crudRows: CrudRows) {
    if (crudRows[id].__status__ === "original-deleted") {
       crudRows[id].__status__ = "original";
    } else if (crudRows[id].__status__ === "original-updated-deleted") {
       crudRows[id].__status__ = "original-updated";
    }
    this.dummyRestoredObservable = this.uniqueCounter++;
  }

  onDelete(id: string, crudRows: CrudRows) {
    if (crudRows[id].__status__ === "original") {
       crudRows[id].__status__ = "original-deleted";
    } else if (crudRows[id].__status__ === "original-updated") {
       crudRows[id].__status__ = "original-updated-deleted";
    } else if (["added", "added-updated"].includes(crudRows[id].__status__)) {
      delete crudRows[id];
    }
    this.dummyRestoredObservable = this.uniqueCounter++;
  }

  onDeleteRole(id: string) {
    this.onDelete(id, this.rolesCrudRows);
  }

  onRestoreRole(id: string) {
    this.onRestore(id, this.rolesCrudRows);
  }

  onDeleteOrganization(id: string) {
    this.onDelete(id, this.organizationsCrudRows);
  }

  onRestoreOrganization(id: string) {
    this.onRestore(id, this.organizationsCrudRows);
  }

  setCallbackUpdateParams(callbackUpdatedParams: any) {
    this.callbackUpdatedParams = callbackUpdatedParams;
  }

  * close() {
    if (this.callbackUpdatedParams) {
      yield this.rootStore.returnedData(this.callbackUpdatedParams);
      window.history.go(-1);
    }
  }

  setUser(user: User) {
    this.entity = user;
    this.copyFromUser(user);
  }

  initRolesCrud(roles: Role[]) {
    roles.forEach(x => {
      this.rolesCrudRows[x.id] = {__status__: "original", ...x};
    })
  }

  initOrganizationsCrud(organizations: Organization[]) {
    organizations.forEach(x => {
      this.organizationsCrudRows[x.id] = {__status__: "original", ...x};
    })
  }

  clearEntity() {
    this.name = "";
    this.email = "";
    this.active = true;
    this.entity = null;
    this.organizations = [];
    this.roles = [];
    this.rolesCrudRows = {};
    this.organizationsCrudRows = {};
  }

  copyFromUser(user: User) {
    this.email = user.email;
    this.name = user.name;
    this.active = user.active;
    this.roles = user.roles ? user.roles : [];
    this.organizations = user.organizations ? user.organizations : [];
    this.initRolesCrud(this.roles);
    this.initOrganizationsCrud(this.organizations);
  }

  get emailErrorMessage() {
    if (this.email === "") {
      return this.rootStore.langStore.requiredMessage;
    } else if (!this.emailIsValid) {
      return this.rootStore.langStore.invalidEmailMessage;
    }
    return "";
  }

  get nameErrorMessage() {
    if (this.name === "") {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  get rolesErrorMessage() {
    const crud = buildRelatedUpdateRows(this.rolesCrudRows);
    const add = crud.add.length + (this.entity ? this.entity.roles.length : 0);
    const remove = crud.remove.length;
    if (add - remove <= 0) {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  get organizationsErrorMessage() {
    const crud = buildRelatedUpdateRows(this.organizationsCrudRows);
    const add = crud.add.length + (this.entity ? this.entity.organizations.length : 0);
    const remove = crud.remove.length;
    if (add - remove <= 0) {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  get formErrors(): ErrorMessageItem[] {
    const errors = [];
    if (this.emailErrorMessage) {
      errors.push({name: "email", error: this.emailErrorMessage});
    }
    if (this.nameErrorMessage) {
      errors.push({name: "name", error: this.nameErrorMessage});
    }
    if (this.rolesErrorMessage) {
      errors.push({name: "roles", error: this.rolesErrorMessage});
    }
    if (this.organizationsErrorMessage) {
      errors.push({name: "organizations", error: this.organizationsErrorMessage});
    }
    return errors;
  }

  get processingTitle() {
    return  this.rootStore.langStore.processingTitle;
  }

  get processingDescription() {
    return this.rootStore.langStore.processingDescription;
  }

  setErrorDialogOpen(value: boolean, originalError?: any, title?: string, description?: string) {
    this.originalError = originalError;
    if (title) {
      this.errorTitle = title;
    }
    if (description) {
      this.errorDescription = description;
    }
    this.errorDialogOpen = value;
  }

  * save(): any {
    if (this.canSaveUser) {
      try {
        if (this.entity) {
          const updateVariables: UpdateUserMutationVariables = {
            id: this.entity.id,
            email: this.email,
            name: this.name,
            active: this.active,
            roles: buildRelatedUpdateRows<Role>(this.rolesCrudRows) as DependentRolesUpdate,
            organizations: buildRelatedUpdateRows<Organization>(this.organizationsCrudRows),
          };
          const updateOne = userService.updateOne(updateVariables);
          const updatedId = yield updateOne(this.rootStore);
          yield this.initialize(updatedId);
        } else {
          const createVariables: NewUserMutationVariables = {
            email: this.email,
            name: this.name,
            active: this.active,
            roles: Object.keys(this.rolesCrudRows) as UserRole[],
            organizations: Object.keys(this.organizationsCrudRows),
          };
          const createOne = userService.createOne(createVariables);
          const id = yield createOne(this.rootStore);
          yield this.initialize(id);
        }
        if (this.callbackUpdatedParams) {
          yield this.close();
        }
        this.status = "ok";
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, error, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, error, this.rootStore.langStore.serverError, undefined);
        }
        this.status = "error";
      }
    }
  }

  private async queryUser(userId: string): Promise<User> {
    let data;
    const api = getApi(this.rootStore.authStore);
    data = await api.GetUser({id: userId});
    return data.userOne as User;
  }

  * initialize(idToLoad?: string): any {
      try {
        this.status = "processing";
        yield this.rootStore.roleStore.initialize();
        yield this.rootStore.organizationStore.initialize();

        this.clearEntity();

        if (idToLoad) {
          const user = yield this.queryUser(idToLoad);
          this.setUser(user);
        }

        this.status = "ok";
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, error, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, error, this.rootStore.langStore.serverError, undefined);
        }
        this.status = "error";
      }
  }
}