import React from "react";
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById('root');
if (container) {
  ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
  );
}
reportWebVitals();
