import {makeAutoObservable} from 'mobx';
import {RootStore} from '../../stores';
import {getApi} from "../../api/apiClient";
import {State} from "../../api/graphql";
import {CountryCodes} from "../../types";
import {StoreStatus} from "../../stores/types";

export class StateStore {
  private rootStore: RootStore;
  private stateAll: State[] | null = null;
  status: StoreStatus = "uninitialized";
  countryId: CountryCodes = "AR";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setCountryId(id: CountryCodes) {
    this.countryId = id;
  }

  * onSelectCountry(id: CountryCodes) {
    this.countryId = id;
    yield this.loadStates();
  }

  get stateOptions() {
    if (this.stateAll !== null) {
      return this.stateAll
          .map((x) => {
            return {id: x.id, name: x.name, latitude: x.latitude, longitude: x.longitude, parentId: this.countryId};
          })
          .sort((a, b) => (a.name > b.name) ? 1 : -1);
    }
    return [];
  }

  * loadStates(): any {
      let data;
      const api = getApi(this.rootStore.authStore);
      data = yield api.States({countryId: this.countryId});
      this.stateAll = data.stateAll;
  }

  * initialize(): any {
    if (this.status === "uninitialized") {
      try {
        this.status = "processing";
        yield this.loadStates();
        this.status = "ok";
      } catch (error) {
        this.status = "error";
      }
    }
  }
}