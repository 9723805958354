import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {LanguageCodes, CountryCodes, stateLabelByCountry} from "../types";

export class LangStore {
  private rootStore: RootStore;

  language: LanguageCodes = "es";
  invalidEmailMessage = "";
  updateEventLabel = "";
  logInLabel = "";
  logOutLabel = "";
  changePasswordLabel = "";
  openLanguagesMenu = "";
  openUserMenu = "";
  newReportLabel = "";
  listReportLabel = "";
  reportingMap = "";
  listUserLabel = "";
  confirmRestePasswordTitle = "";
  confirmRestePasswordDescription = "";
  endOfListing = "";
  startOfListing = "";
  navigateTo = "";
  navigateToHash = "";
  reportsFilterTitle = "";
  eventReviewLabel = "";
  countryLabel = "";
  saveProfile = "";
  columnsChooserLabel = "";
  stateLabel = (countryCode: CountryCodes) => stateLabelByCountry[countryCode];
  allStateLabel = "Provincia/Estado/Departamento";
  cityLabel = "";
  welcomedText = (name: string) => `${name}`;
  labelForName = "";
  passwordLabel = "";
  newPasswordLabel = "";
  locationTitle = "";
  qualityControlTitle = "";
  qualityControlLabel = "";
  sourceInformationTitle = "";
  sourceInformationLabel = "";
  eventTypeTitle = "";
  eventImagesTitle = "";
  eventHasMediaLabel = "";
  badEmailOrPasswordError = "";
  emailAndPasswordRequiredError = "";
  appTitle = "";
  homeTitle = "";
  pageNotFound = "";
  eventDateTimeTitle = "";
  eventDateLabel = "";
  eventTimeLabel = "";
  eventDateTimeDescription = "";
  eventDurationTitle = "";
  eventDurationLabel = "";
  eventDurationDescription = "";
  detailedLocationDescription = "";
  detailedLocationTitle = "";
  detailedLocationLabel = "";
  eventLatitudeLongitudeDescription = "";
  eventLatitudeLongitudeTitle = "";
  eventLatitudeTitle = "";
  eventLatitudeLabel = "";
  eventLatitudeLongitudeLabel = "";
  eventLongitudeTitle = "";
  eventLongitudeLabel = "";
  hoursLabel = "";
  minutesLabel = "";
  secondsLabel = "";
  englishSelected = false;
  temporalPrecisionTitle = "";
  seconds = "";
  temporalPrecisionLabel = "";
  temporalPrecision: {label: string, seconds: string}[] = [];
  spatialPrecisionDescription = "";
  spatialPrecisionTitle = "";
  spatialPrecisionMetersLabel = "";
  spatialPrecisionLabel = "";
  eventHailDiameterDescription = "";
  eventHailDiameterTitle = "";
  eventHailDiameterMMLabel = "";
  eventHailDiameterLabel = "";
  eventWindSpeedDescription = "";
  eventWindSpeedTitle = "";
  eventWindSpeedLabel = "";
  eventWindMaxSpeedDescription = "";
  eventWindMaxSpeedTitle = "";
  eventWindMaxSpeedLabel = "";
  eventPrecipitationAccumDescription = "";
  eventPrecipitationAccumTitle = "";
  eventPrecipitationAccumLabel = "";
  eventSnowAccumDescription = "";
  eventSnowAccumTitle = "";
  eventSnowAccumMMLabel = "";
  eventSnowAccumLabel = "";
  eventReferencesTitle = "";
  eventReferencesDescription = "";
  eventReferencesLabel = "";
  eventDescriptionTitle = "";
  descriptionLabel = "";
  eventDescriptionDescription = "";
  spatialPrecision = [
      {label: "", meters: ""},
      {label: "100 m", meters: (100).toString()},
      {label: "1 km", meters: (1000).toString()},
      {label: "5 km", meters: (5 * 1000).toString()},
      {label: "10 km", meters: (10 * 1000).toString()},
      {label: "50 km", meters: (50 * 1000).toString()},
      {label: "100 km", meters: (100 * 1000).toString()},
  ];
  eventWindDirectionTitle = "";
  eventWindDirectionLabel = "";
  latitudeErrorMessage = "";
  latitudeLongitudeErrorMessage = "";
  longitudeErrorMessage = "";
  dataSourceSectionTitle = "";
  eventLocalizationSectionTitle = "";
  satelliteImagesTitle = ""
  eventTimeSectionTitle = "";
  eventMagnitudeSectionTitle = "";
  eventImpactSectionTitle = "";
  eventImpactLabel = "";
  eventImpactTypeLabel = "";
  requiredMessage = "";
  cityOrLatLonRequiredMessage = "";
  eventLocationLabel = "";
  sendReportLabel = "";
  eventFormStatus = "";
  newEventRegistered = "";
  updateEventAction = "";
  clearReportLabel = "";
  reporterLabel = "";
  applyFilterLabel = "";
  authorTitle = "";
  reviewerTitle = "";
  eventIdLabel = "";
  eventWindDirectionOptions: {label: string, direction: string}[] = [];
  breadcrumbsLabels: Record<string, string> = {
    "/": "",
    "/login": "",
    "/event/new": "",
    "/event/list": "",
    "/not-found": "",
  };
  nextPage: (pageSize: number) => string = () => "";
  exportToExcel = "";
  processingTitle = "";
  processingDescription = "";
  errorTitle = "";
  serverError = "";
  pageErrorTitle = "";
  fileSizeExceeded = "";
  duplicatedFilename = "";
  uploadFileLabel = "";
  nextReport = "";
  previousReport = "";
  eventConfidence = "";
  modifyAction = "";
  saveCORSException = "";
  removeAction = "";
  loginPathLabel = "";
  changePasswordPathLabel = "";
  homePathLabel = "";
  eventNewPathLabel = "";
  eventViewPathLabel = "";
  eventUpdatePathLabel = "";
  eventListPathLabel = "";
  eventReviewPathLabel = "";
  userNewPathLabel = "";
  userUpdatePathLabel = "";
  userListPathLabel = "";
  pageNotFoundPathLabel = "";
  sortingHint = "";
  noColumnMessage = "";
  noDataMessage = "";
  addCommand = "";
  editCommand = "";
  deleteCommand = "";
  commitCommand = "";
  cancelCommand = "";
  labelOrganizations = "";
  labelRoles = "";
  authorizationError = "";
  doNotHavePermission = "";
  userDataTitle = "";
  userRolesTitle = "";
  userOrganizationsTitle = "";
  isUserActiveLabel = "";
  reportSentOk = "";
  codeLabel = "";

  labelNameLang: "label_es" | "label_en" | "label_pt" = "label_es";
  fieldNameLang: "name_es" | "name_en" | "name_pt" = "name_es";
  errorMessageField: "message_es" | "message_en" | "message_pt" = "message_es";

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.setLanguage('es');
  }

  setLanguage(language: LanguageCodes) {
    this.language = language;
    this.labelNameLang = language === "es" ? "label_es" : language === "en" ? "label_en" : "label_pt";
    this.fieldNameLang = language === "es" ? "name_es" : language === "en" ? "name_en" : "name_pt";
    if (this.language === 'es') {
        this.codeLabel = "Código";
        this.reportSentOk = "El reporte ha sido guardado correctamente";
        this.saveCORSException = "Permita el acceso a las imágenes satelitales";
        this.invalidEmailMessage = "Email inválido";
        this.confirmRestePasswordTitle = "Reseteo de contraseña";
        this.confirmRestePasswordDescription = "¿Confirma el reseteo de la contraseña para el usuario?";
        this.isUserActiveLabel = "Activo";
        this.userDataTitle = "Datos del usuario";
        this.userRolesTitle = "Roles";
        this.userOrganizationsTitle = "Organizaciones a las que pertenece";
        this.loginPathLabel = "Inicio sesión";
        this.changePasswordPathLabel = "Cambio de contraseña";
        this.homePathLabel = "Página de inicio";
        this.eventNewPathLabel = "Nuevo evento";
        this.eventViewPathLabel = "Evento";
        this.eventUpdatePathLabel = "Modificación de un Evento";
        this.eventListPathLabel = "Lista de eventos";
        this.eventReviewPathLabel = "Revisión de eventos";
        this.userNewPathLabel = "Nuevo Usuario";
        this.userUpdatePathLabel = "Modificación de un Usuario";
        this.userListPathLabel = "Lista de Usuarios";
        this.pageNotFoundPathLabel = "Página no encontrada";
        this.modifyAction = "Modificar";
        this.removeAction = "Eliminar";
        this.eventConfidence = "Calidad";
        this.nextReport = "Siguiente";
        this.previousReport = "Anterior";
        this.saveProfile = "Guardar formato";
        this.satelliteImagesTitle = "Imágenes de satélite";
        this.columnsChooserLabel = "Columnas";
        this.labelForName = "Nombre";
        this.eventIdLabel = "ID de evento";
        this.authorTitle = "Reporter";
        this.reviewerTitle = "Revisor";
        this.updateEventLabel = "Modificar este evento";
        this.seconds = "segundos";
        this.logInLabel = "Iniciar sesión";
        this.logOutLabel = "Cerrar sesión";
        this.changePasswordLabel = "Cambiar la contraseña";
        this.welcomedText = (name: string) => `${name}, bienvenido al sistema de reportes de tiempo severo`;
        this.passwordLabel = "Contraseña";
        this.newPasswordLabel = "Contraseña nueva";
        this.qualityControlTitle = "Control de calidad";
        this.qualityControlLabel = "Calidad";
        this.sourceInformationTitle = "Fuente de Información";
        this.sourceInformationLabel = "Fuente";
        this.eventTypeTitle = "Tipo de Evento";
        this.appTitle = "Aplicación Web de Informes de Tiempo Severo";
        this.homeTitle = "Aplicación web de informes de tiempo severo";
        this.locationTitle = "Localidad";
        this.countryLabel = "País";
        this.cityLabel = "Ciudad";
        this.pageNotFound = "Página no encontrada";
        this.newReportLabel = "Nuevo reporte";
        this.listReportLabel = "Listado de reportes";
        this.reportingMap = "Mapa de reportes";
        this.listUserLabel = "Listado de usuarios";
        this.eventReviewLabel = "Revisión de reportes";
        this.badEmailOrPasswordError = "Email o Contraseña incorrectos";
        this.emailAndPasswordRequiredError ="Email y Password son obligatorios";
        this.eventImagesTitle = "Imágenes del fenómeno"
        this.eventHasMediaLabel = "¿Imágenes?"
        this.eventDateTimeTitle = "Fecha y hora local del evento";
        this.eventDateLabel = "Fecha";
        this.eventTimeLabel = "Hora";
        this.eventDateTimeDescription = "Fecha y hora local aproximada. Si no se cuenta con la información en minutos, seleccionar 00, de manera tal que sea la hora la que contenga la información.";
        this.eventDurationTitle = "Duración total del evento";
        this.eventDurationLabel = "Duración total";
        this.eventDurationDescription = "Duración aproximada del evento en segundos (horas + minutos + segundos)";
        this.hoursLabel = "Horas";
        this.minutesLabel = "Minutos";
        this.secondsLabel = "Segundos";
        this.temporalPrecisionTitle = "Precisión temporal";
        this.temporalPrecisionLabel = "Precisión temporal";
        this.temporalPrecision = [
          {label: "10 minutos antes o después", seconds: (60 * 10).toString()},
          {label: "30 minutos a 1 hora antes o después", seconds: (60 * 60).toString()},
          {label: "1 a 3 horas antes o después", seconds: (3 * 60 * 60).toString()},
          {label: "3 a 6 horas antes o después", seconds: (6 * 60 * 60).toString()},
          {label: "6 a 12 horas antes o después", seconds: (12 * 60 * 60).toString()},
          {label: "12 a 24 horas antes o después", seconds: (24 * 60 * 60).toString()},
        ];
        this.detailedLocationDescription = "Ubicación algo más precisa dentro de la localidad, por ejemplo: intersección de Av. Colón y Pringles, cerca del estadio El Progreso, etc.";
        this.detailedLocationTitle = "Localización detallada";
        this.detailedLocationLabel = "Localización";
        this.eventLatitudeLongitudeDescription = "En grados decimales, usando punto como separador de decimales, con signo menos para el Hemisferio Sur, por ejemplo: -38.475 y con signo menos para el Hemisferio Occidental, por ejemplo: -65.386 . Si no se cuenta con el dato de latitud, dejar en blanco.";
        this.eventLatitudeLongitudeTitle = "Latitud y Longitud";
        this.eventLatitudeTitle = "Latitud";
        this.eventLatitudeLabel = "Latitud";
        this.eventLatitudeLongitudeLabel = "Lat, Lon (Pegue desde Google maps)";
        this.eventLongitudeTitle = "Longitud";
        this.eventLongitudeLabel = "Longitud";
        this.spatialPrecisionDescription = "Error del orden de...";
        this.spatialPrecisionTitle = "Precisión espacial";
        this.spatialPrecisionMetersLabel = "Precisión espacial (m)";
        this.spatialPrecisionLabel = "Precisión espacial";
        this.eventHailDiameterDescription = "Cifra en cm con un decimal a lo sumo. Usar punto como separador decimal.";
        this.eventHailDiameterTitle = "Diámetro máximo del granizo (cm)";
        this.eventHailDiameterMMLabel = "Diámetro granizo (mm)";
        this.eventHailDiameterLabel = "Diámetro máximo";
        this.eventWindSpeedDescription = "Velocidad estimada del VIENTO SOSTENIDO. Cifra SIN DECIMALES en km/h";
        this.eventWindSpeedTitle = "Viento estimado (km/h)";
        this.eventWindSpeedLabel = "Viento estimado";
        this.eventWindMaxSpeedDescription = "Velocidad de las ráfagas. Cifra SIN DECIMALES en km/h.";
        this.eventWindMaxSpeedTitle = "Viento máximo estimado (km/h)";
        this.eventWindMaxSpeedLabel = "Viento máximo";
        this.eventWindDirectionTitle = "Dirección estimada del viento";
        this.eventWindDirectionLabel = "Dirección del viento";
        this.eventWindDirectionOptions = [
              {label: "", direction: ""},
              {label: "N", direction: (360).toString()},
              {label: "NNE", direction: (22.5).toString()},
              {label: "NE", direction: (45).toString()},
              {label: "ENE", direction: (67.5).toString()},
              {label: "E", direction: (90).toString()},
              {label: "ESE", direction: (112.5).toString()},
              {label: "SE", direction: (135).toString()},
              {label: "SSE", direction: (157.5).toString()},
              {label: "S", direction: (180).toString()},
              {label: "SSO", direction: (202.5).toString()},
              {label: "SO", direction: (225).toString()},
              {label: "OSO", direction: (247.5).toString()},
              {label: "O", direction: (270).toString()},
              {label: "ONO", direction: (292.5).toString()},
              {label: "NO", direction: (325).toString()},
              {label: "NNO", direction: (347.5).toString()},
          ];
        this.eventPrecipitationAccumDescription = "Cifra sin decimales en mm. Se asume como período acumulado al período completo del evento (duración total). Si este está transcurriendo, se contará desde el inicio del evento hasta el tiempo de carga.";
        this.eventPrecipitationAccumTitle = "Precipitación acumulada (mm)";
        this.eventPrecipitationAccumLabel = "Precipitación (mm)";
        this.eventSnowAccumDescription = "Cifra sin decimales en cm. Se asume como período acumulado al período completo del evento (duración total). Si este está transcurriendo, se contará desde el inicio del evento hasta el tiempo de carga.";
        this.eventSnowAccumTitle = "Nieve caída acumulada (cm)";
        this.eventSnowAccumMMLabel = "Nieve acumulada (mm)";
        this.eventSnowAccumLabel = "Nieve acumulada (cm)";
        this.latitudeErrorMessage = "Ingrese una latitud válida con a lo sumo 3 decimales";
        this.latitudeLongitudeErrorMessage = "Ingrese una latitud, longitud (como la obtiene de google maps)";
        this.longitudeErrorMessage = "Ingrese una longitud válida con a lo sumo 3 decimales";
        this.dataSourceSectionTitle = "Fuente de información";
        this.eventLocalizationSectionTitle = "Localización del evento";
        this.eventTimeSectionTitle = "Momento del evento";
        this.eventMagnitudeSectionTitle = "Magnitud del evento";
        this.eventImpactSectionTitle = "Impacto del evento";
        this.eventImpactLabel = "Impacto";
        this.eventImpactTypeLabel = "Tipo de impacto";
        this.requiredMessage = "Requerido";
        this.cityOrLatLonRequiredMessage = "O la ciudad o latitud y longitud son requeridos";
        this.eventReferencesTitle = "Referencias";
        this.eventReferencesDescription = "Links a las fuentes";
        this.eventReferencesLabel = "Referencias";
        this.eventDescriptionTitle = "Descripción del evento";
        this.descriptionLabel = "Descripción";
        this.eventDescriptionDescription = "Texto descriptivo libre. Puede utilizarse para ponderar aquella información cuya descripción en categorías estrictas resultó incómoda, brindar aclaraciones cuantitativas de los impactos (cantidad de...), etc.";
        this.eventLocationLabel = "Localidad";
        this.sendReportLabel = "Enviar reporte";
        this.eventFormStatus = "Estado del formulario";
        this.newEventRegistered = "Nuevo evento registrado";
        this.updateEventAction = "Modificar reporte";
        this.clearReportLabel = "Borrar el formulario actual";
        this.reporterLabel = "Reportero";
        this.breadcrumbsLabels = {
            "/": "Inicio",
            "/login": "Iniciar sesión",
            "/change-password": this.changePasswordLabel,
            "/event/new": "Nuevo reporte",
            "/event/view": "Vista de reporte",
            "/event/update": "Edición de reporte",
            "/event/list": "Listado de reportes",
            "/not-found": "No encontrada",
            };
        this.nextPage = (pageSize: number) => `Próximos ${pageSize}`;
        this.applyFilterLabel = "Aplique el filtro al listado";
        this.exportToExcel = "Exportar a Excel";
        this.openLanguagesMenu = "Menú de idionas";
        this.openUserMenu = "Menú de usuario";
        this.processingTitle = "Procesando";
        this.processingDescription = "Espera un momento por favor";
        this.errorTitle = "Hubo un error";
        this.serverError = "Error en el server";
        this.pageErrorTitle = "Error";
        this.uploadFileLabel = "Agregar un archivo de imagen";
        this.fileSizeExceeded = "Tamaño de archivo excedido";
        this.duplicatedFilename = "Nombre de archivo duplicado";
        this.reportsFilterTitle = "Filtrado de reportes";
        this.endOfListing = "Final del listado";
        this.startOfListing = "Inicio del listado";
        this.sortingHint = "Ordenar";
        this.noColumnMessage = "No hay columnas para mostrar";
        this.noDataMessage = "No hay datos para mostrar";
        this.addCommand = "Agregar";
        this.editCommand = "Editar";
        this.deleteCommand = "Borrar";
        this.commitCommand = "Ok";
        this.cancelCommand = "Cancelar";
        this.labelOrganizations = "Organizaciones";
        this.labelRoles = "Roles";
        this.authorizationError = "Error de autorización";
        this.doNotHavePermission = "No tiene permiso para realizar esta operación";
    } else if (this.language === 'pt') {
        this.codeLabel = "Código";
        this.reportSentOk = "O relatório foi salvo com sucesso";
        this.saveCORSException = "Permitir acesso a imagens de satélite";
        this.invalidEmailMessage = "E-mail inválido";
        this.confirmRestePasswordTitle = "Redefinição de senha";
        this.confirmRestePasswordDescription = "Você confirma a redefinição de senha para o usuário?";
        this.isUserActiveLabel = "Ativo";
        this.userDataTitle = "Dados do usuário";
        this.userRolesTitle = "Funções";
        this.userOrganizationsTitle = "Organizações às quais pertence";
        this.loginPathLabel = "Conecte-Se";
        this.changePasswordPathLabel = "Mudança de senha";
        this.homePathLabel =  "Página inicial";
        this.eventNewPathLabel = "Novo evento";
        this.eventViewPathLabel = "Evento";
        this.eventUpdatePathLabel = "Modificação de um Evento";
        this.eventListPathLabel = "Lista de evento";
        this.eventReviewPathLabel = "Revisão do evento";
        this.userNewPathLabel = "Novo Usuário";
        this.userUpdatePathLabel = "Modificação de um Usuário";
        this.userListPathLabel = "Lista de Usuários";
        this.pageNotFoundPathLabel = "Página não encontrada";
        this.modifyAction = "Modificar";
        this.removeAction = "Remover";
        this.eventConfidence = "Qualidade";
        this.nextReport = "Próximo";
        this.previousReport = "Anterior";
        this.saveProfile = "Salvar formato";
        this.satelliteImagesTitle = "Imagens de satélite";
        this.columnsChooserLabel = "Colunas";
        this.labelForName = "Nome";
        this.eventIdLabel = "ID de evento";
        this.authorTitle = "Autor";
        this.reviewerTitle = "Revisor";
        this.updateEventLabel = "Modificar este evento";
        this.seconds = "segundos";
        this.logInLabel = "Conecte-se";
        this.logOutLabel = "Sair";
        this.changePasswordLabel = "Alterar a senha";
        this.welcomedText = (name: string) => `${name}, bem-vindo ao sistema de relatórios de tempo severo`;
        this.passwordLabel = "Senha";
        this.newPasswordLabel = "Senha nova";
        this.qualityControlTitle = "Controle de Qualidade";
        this.qualityControlLabel = "Qualidade";
        this.sourceInformationTitle = "Fonte de Informação";
        this.sourceInformationLabel = "Fonte";
        this.eventTypeTitle = "Tipo de Evento";
        this.appTitle = "Aplicativo da Web para Relatórios de Tiempo Severo";
        this.homeTitle = "Aplicativo da web para relatórios de tiempo severo";
        this.locationTitle = "Localização";
        this.countryLabel = "País";
        this.cityLabel = "Cidade";
        this.pageNotFound = "Página não encontrada";
        this.newReportLabel = "Novo relatório";
        this.listReportLabel = "Lista de relatórios";
        this.reportingMap = "Mapa de relatórios";
        this.listUserLabel = "Lista de usuários";
        this.eventReviewLabel = "Revisão do relatórios";
        this.badEmailOrPasswordError = "Email ou senha incorretos";
        this.emailAndPasswordRequiredError = "E-mail e senha são obrigatórios";
        this.eventImagesTitle = "Imagens do fenômeno"
        this.eventHasMediaLabel = "Imagens?"
        this.eventDateTimeTitle = "Data e hora local do evento";
        this.eventDateLabel = "Data";
        this.eventTimeLabel = "Tempo";
        this.eventDateTimeDescription = "Data e hora locais aproximadas. Se a informação não estiver disponível em minutos, selecione 00, para que seja a hora que contenha a informação.";
        this.eventDurationTitle = "Duração total do evento";
        this.eventDurationLabel = "Duração total";
        this.eventDurationDescription = "Duração aproximada do evento em segundos (horas + minutos + segundos)";
        this.hoursLabel = "Horas";
        this.minutesLabel = "Minutos";
        this.secondsLabel = "Segundos";
        this.temporalPrecisionTitle = "Precisão do Tempo";
        this.temporalPrecisionLabel = "Precisão do Tempo";
        this.temporalPrecision = [
          {label: "30 minutos a 1 hora antes ou depois", seconds: (60 * 60).toString()},
          {label: "1 a 3 horas antes ou depois", seconds: (3 * 60 * 60).toString()},
          {label: "3 a 6 horas antes ou depois", seconds: (6 * 60 * 60).toString()},
          {label: "6 a 12 horas antes ou depois", seconds: (12 * 60 * 60).toString()},
          {label: "12 a 24 horas antes ou depois", seconds: (24 * 60 * 60).toString()},
        ];
        this.detailedLocationDescription = "Localização um pouco mais precisa dentro da cidade, por exemplo: cruzamento da Av. Cristóvão Colombo e Rio Branco, perto do estádio Botafogo.";
        this.detailedLocationTitle = "Localização detalhada";
        this.detailedLocationLabel = "Localização";
        this.eventLatitudeLongitudeDescription = "Em graus decimais, usando um ponto como separador decimal, com um sinal de menos para o Hemisfério Sul, por exemplo: -38.475 e para o Hemisfério Ocidental, por exemplo: -65.386. Se os dados de latitude não estiverem disponíveis, deixe em branco. .";
        this.eventLatitudeLongitudeTitle = "Latitude e Longitude";
        this.eventLatitudeTitle = "Latitude";
        this.eventLatitudeLabel = "Latitude";
        this.eventLatitudeLongitudeLabel = "Lat, Lon (Cole do Google maps)";
        this.eventLongitudeTitle = "Longitude";
        this.eventLongitudeLabel = "Longitude";
        this.spatialPrecisionDescription = "Erro de ordem...";
        this.spatialPrecisionTitle = "Precisão espacial";
        this.spatialPrecisionMetersLabel = "Precisão espacial (m)";
        this.spatialPrecisionLabel = "Precisão espacial";
        this.eventHailDiameterDescription = "Número em cm com no máximo uma casa decimal. Use ponto como separador decimal. Pode ser a conversão de uma descrição em palavras, por exemplo, \"bola de tênis\" -> 6.5 cm. O diâmetro médio será usado em caso de sendo oblóide.";
        this.eventHailDiameterTitle = "Diâmetro máximo de granizo (cm)";
        this.eventHailDiameterMMLabel = "Diâmetro granizo (mm)";
        this.eventHailDiameterLabel = "Diâmetro máximo";
        this.eventWindSpeedDescription = "Velocidade estimada do VENTO SUSTENTADO. Número SEM DECIMAIS em km/h";
        this.eventWindSpeedTitle = "Vento estimado (km/h)";
        this.eventWindSpeedLabel = "Vento estimado";
        this.eventWindMaxSpeedDescription = "Velocidade de estouro. Figura SEM DECIMAIS em km/h.";
        this.eventWindMaxSpeedTitle = "Vento máximo estimado (km/h)";
        this.eventWindMaxSpeedLabel = "Vento máximo";
        this.eventWindDirectionTitle = "Direção do vento estimada";
        this.eventWindDirectionLabel = "Direção do vento";
        this.eventWindDirectionOptions = [
              {label: "", direction: ""},
              {label: "N", direction: (360).toString()},
              {label: "NNE", direction: (22.5).toString()},
              {label: "NE", direction: (45).toString()},
              {label: "ENE", direction: (67.5).toString()},
              {label: "E", direction: (90).toString()},
              {label: "ESE", direction: (112.5).toString()},
              {label: "SE", direction: (135).toString()},
              {label: "SSE", direction: (157.5).toString()},
              {label: "S", direction: (180).toString()},
              {label: "SSO", direction: (202.5).toString()},
              {label: "SO", direction: (225).toString()},
              {label: "OSO", direction: (247.5).toString()},
              {label: "O", direction: (270).toString()},
              {label: "ONO", direction: (292.5).toString()},
              {label: "NO", direction: (325).toString()},
              {label: "NNO", direction: (347.5).toString()},
          ];
        this.eventPrecipitationAccumDescription = "Valor sem decimais em mm. Considera-se que o período acumulado é todo o período do evento (duração total). Se estiver em execução, será contado desde o início do evento até o momento do carregamento.";
        this.eventPrecipitationAccumTitle = "Chuva acumulada (mm)";
        this.eventPrecipitationAccumLabel = "Chuva (mm)";
        this.eventSnowAccumDescription = "Número sem decimais em cm. Considera-se que o período acumulado é todo o período do evento (duração total). Se estiver em execução, será contado desde o início do evento até o momento do carregamento.";
        this.eventSnowAccumTitle = "Neve caída cumulativa (cm)";
        this.eventSnowAccumMMLabel = "Neve cumulativa (mm)";
        this.eventSnowAccumLabel = "Neve cumulativa (cm)";
        this.latitudeErrorMessage = "Digite uma latitude válida com no máximo 3 casas decimais";
        this.latitudeLongitudeErrorMessage = "Digite uma latitude, longitude (como pegar no google maps)";
        this.longitudeErrorMessage = "Digite uma longitude válida com no máximo 3 casas decimais";
        this.dataSourceSectionTitle = "Fonte de informação";
        this.eventLocalizationSectionTitle = "Localização do evento";
        this.eventTimeSectionTitle = "Horário do evento";
        this.eventMagnitudeSectionTitle = "Magnitude do evento";
        this.eventImpactSectionTitle = "Impacto do evento";
        this.eventImpactLabel = "Impacto";
        this.eventImpactTypeLabel = "Tipo de impacto";
        this.requiredMessage = "Obrigatório";
        this.cityOrLatLonRequiredMessage = "A cidade ou a latitude e longitude são obrigatórios";
        this.eventReferencesTitle = "Referências";
        this.eventReferencesDescription = "Links para fontes";
        this.eventReferencesLabel = "Referências";
        this.eventDescriptionTitle = "Descrição do evento";
        this.descriptionLabel = "Descrição";
        this.eventDescriptionDescription = "Texto descritivo livre. Pode ser usado para ponderar aquelas informações cuja descrição em categorias estritas foi desconfortável, fornecer esclarecimento quantitativo dos impactos (quantidade de...), etc.";
        this.eventLocationLabel = "Localização";
        this.sendReportLabel = "Enviar relatório";
        this.eventFormStatus = "Estado do formulário";
        this.newEventRegistered = "Novo evento registrado";
        this.updateEventAction = "Modificar relatório";
        this.clearReportLabel = "Limpar o formulário atual";
        this.reporterLabel = "Repórter";
        this.breadcrumbsLabels = {
            "/": "Inicial",
            "/login": "Conecte-se",
            "/change-password": this.changePasswordLabel,
            "/event/new": "Novo relatório",
            "/event/view": "Visualização do relatório",
            "/event/update": "Edição de relatório",
            "/event/list": "Lista de relatórios",
            "/not-found": "Não encontrado",
            };
        this.nextPage = (pageSize: number) => `Próximos ${pageSize}`;
        this.applyFilterLabel = "Aplicar o filtro à lista";
        this.exportToExcel = "Exportar para Excel";
        this.openLanguagesMenu = "Menu de idionas";
        this.openUserMenu = "Menú de usuário";
        this.processingTitle = "Em processamento";
        this.processingDescription = "Espera um momento por favor";
        this.errorTitle = "Houve um erro";
        this.serverError = "Erro de servidor";
        this.pageErrorTitle = "Erro";
        this.uploadFileLabel = "Adicionar um arquivo de imagem";
        this.fileSizeExceeded = "Tamanho do arquivo excedido";
        this.duplicatedFilename = "Nome de arquivo duplicado";
        this.reportsFilterTitle = "Filtragem de relatórios";
        this.endOfListing = "Fim da listagem";
        this.startOfListing = "Início da listagem";
        this.sortingHint = "Classificar";
        this.noColumnMessage = "Não há colunas para exibir";
        this.noDataMessage = "Não há dados para exibir";
        this.addCommand = "Adicionar";
        this.editCommand = "Editar";
        this.deleteCommand = "Excluir";
        this.commitCommand = "Ok";
        this.cancelCommand = "Cancelar";
        this.labelOrganizations = "Organizações";
        this.labelRoles = "Funções";
        this.authorizationError = "Erro de autorização";
        this.doNotHavePermission = "Não tem permissão para realizar esta operação";
    } else if (this.language === 'en') {
        this.codeLabel = "Code";
        this.reportSentOk = "The report has been successfully saved";
        this.saveCORSException = "Allow access to satellite images";
        this.invalidEmailMessage = "Invalid email";
        this.confirmRestePasswordTitle = "Password reset";
        this.confirmRestePasswordDescription = "Do you confirm the password reset for the user?";
        this.isUserActiveLabel = "Activ";
        this.userDataTitle = "User data";
        this.userRolesTitle = "Roles";
        this.userOrganizationsTitle = "Organizations to which it belongs";
        this.loginPathLabel = "Login";
        this.changePasswordPathLabel = "Change password";
        this.homePathLabel =  "Home";
        this.eventNewPathLabel = "New Event";
        this.eventViewPathLabel = "Event";
        this.eventUpdatePathLabel = "Update Event";
        this.eventListPathLabel = "Event list";
        this.eventReviewPathLabel = "Event revision";
        this.userNewPathLabel = "New User";
        this.userUpdatePathLabel = "Update User";
        this.userListPathLabel = "User list";
        this.pageNotFoundPathLabel = "Page not found";
        this.modifyAction = "Modify";
        this.removeAction = "Remove";
        this.eventConfidence = "Quality";
        this.nextReport = "Next";
        this.previousReport = "Previous";
        this.saveProfile = "Save format";
        this.satelliteImagesTitle = "Satellite images";
        this.columnsChooserLabel = "Columns";
        this.labelForName = "Name";
        this.eventIdLabel = "Event ID";
        this.authorTitle = "Author";
        this.reviewerTitle = "Reviewer";
        this.updateEventLabel = "Modify this event";
        this.seconds = "seconds";
        this.logInLabel = "Log in";
        this.logOutLabel = "Log out";
        this.changePasswordLabel = "Change password";
        this.welcomedText = (name: string) => `${name}, welcome to the severe weather reporting system`;
        this.passwordLabel = "Password";
        this.newPasswordLabel = "New password";
        this.qualityControlTitle = 'Quality Control';
        this.qualityControlLabel = 'Quality';
        this.sourceInformationTitle = "Information Source";
        this.sourceInformationLabel = "Source";
        this.eventTypeTitle = "Event Type";
        this.appTitle = "Severe Weather Reporting Web App";
        this.homeTitle = "Severe weather reporting web app";
        this.locationTitle = "Location";
        this.countryLabel = "Country";
        this.cityLabel = "City";
        this.pageNotFound = "Page Not Found";
        this.newReportLabel = "New report";
        this.listReportLabel = "List of reports";
        this.reportingMap = "Reporting map";
        this.listUserLabel = "List of users";
        this.eventReviewLabel = "Reports review";
        this.badEmailOrPasswordError = "Bad Email or Password";
        this.emailAndPasswordRequiredError ="Email and Password are required";
        this.eventImagesTitle = "Images of the phenomenon"
        this.eventHasMediaLabel = "Images?"
        this.eventDateTimeTitle = "Local date and time of the event";
        this.eventDateLabel = "Date";
        this.eventTimeLabel = "Time";
        this.eventDateTimeDescription = "Approximate local date and time. If the information is not available in minutes, select 00, so that it is the time that contains the information."
        this.eventDurationTitle = "Total duration of the event";
        this.eventDurationLabel = "Total duration";
        this.eventDurationDescription = "Approximate duration of the event in seconds (hours + minutes + seconds)";
        this.hoursLabel = "Hours";
        this.minutesLabel = "Minutes";
        this.secondsLabel = "Seconds";
        this.temporalPrecisionTitle = "Temporal precision";
        this.temporalPrecisionLabel = "Temporal precision";
        this.temporalPrecision = [
          {label: "30 minutes to 1 hour before or after", seconds: (60 * 60).toString()},
          {label: "1 to 3 hours before or after", seconds: (3 * 60 * 60).toString()},
          {label: "3 to 6 hours before or after", seconds: (6 * 60 * 60).toString()},
          {label: "6 to 12 hours before or after", seconds: (12 * 60 * 60).toString()},
          {label: "12 a 24 hours before or after", seconds: (24 * 60 * 60).toString()},
        ];
        this.detailedLocationDescription = "Somewhat more precise location within the town, for example: intersection of Saint Paul St. and Ocean Drive, near the Los Angeles Lakers stadium";
        this.detailedLocationTitle = "Detailed location";
        this.detailedLocationLabel = "Location";
        this.eventLatitudeLongitudeDescription = "In decimal degrees, using a period as a decimal separator, with a minus sign for the Southern Hemisphere, for example: -38.475 and with a minus sign for the Western Hemisphere, for example: -65.385. If latitude data is not available, leave blank";
        this.eventLatitudeLongitudeTitle = "Latitude and Longitude";
        this.eventLatitudeTitle = "Latitude";
        this.eventLatitudeLabel = "Latitude";
        this.eventLatitudeLongitudeLabel = "Lat, Lon (paste from Google maps)";
        this.eventLongitudeTitle = "Longitude";
        this.eventLongitudeLabel = "Longitude";
        this.spatialPrecisionDescription = "Order error...";
        this.spatialPrecisionTitle = "Spatial precision";
        this.spatialPrecisionMetersLabel = "Spatial precision (m)";
        this.spatialPrecisionLabel = "Spatial precision";
        this.eventHailDiameterDescription = "Number in cm with one decimal at most. Use point as decimal separator. It can be the conversion of a description in words, for example, \"tennis ball\" -> 6.5 cm. The average diameter will be used in case of being obloid.";
        this.eventHailDiameterTitle = "Maximum hail diameter (cm)";
        this.eventHailDiameterMMLabel = "Hail diameter (mm)";
        this.eventHailDiameterLabel = "Maximum diameter";
        this.eventWindSpeedDescription = "Estimated speed of the SUSTAINED WIND. Figure WITHOUT DECIMALS in km/h";
        this.eventWindSpeedTitle = "Estimated wind (km/h)";
        this.eventWindSpeedLabel = "Estimated wind";
        this.eventWindMaxSpeedDescription = "Burst speed. Figure WITHOUT DECIMALS in km/h.";
        this.eventWindMaxSpeedTitle = "Estimated maximum wind (km/h)";
        this.eventWindMaxSpeedLabel = "Maximum wind";
        this.eventWindDirectionTitle = "Estimated wind direction";
        this.eventWindDirectionLabel = "Wind direction";
        this.eventWindDirectionOptions = [
              {label: "", direction: ""},
              {label: "N", direction: (360).toString()},
              {label: "NNE", direction: (22.5).toString()},
              {label: "NE", direction: (45).toString()},
              {label: "ENE", direction: (67.5).toString()},
              {label: "E", direction: (90).toString()},
              {label: "ESE", direction: (112.5).toString()},
              {label: "SE", direction: (135).toString()},
              {label: "SSE", direction: (157.5).toString()},
              {label: "S", direction: (180).toString()},
              {label: "SSW", direction: (202.5).toString()},
              {label: "SW", direction: (225).toString()},
              {label: "WSW", direction: (247.5).toString()},
              {label: "W", direction: (270).toString()},
              {label: "WNW", direction: (292.5).toString()},
              {label: "NW", direction: (325).toString()},
              {label: "NNW", direction: (347.5).toString()},
          ];
        this.eventPrecipitationAccumDescription = "Number without decimals in mm. The accumulated period is assumed to be the entire period of the event (total duration). If it is running, it will be counted from the start of the event until the loading time.";
        this.eventPrecipitationAccumTitle = "Accumulated rainfall (mm)";
        this.eventPrecipitationAccumLabel = "Rainfall (mm)";
        this.eventSnowAccumDescription = "Number without decimals in cm. The accumulated period is assumed to be the entire period of the event (total duration). If it is running, it will be counted from the start of the event until the loading time.";
        this.eventSnowAccumTitle ="Cumulative fallen snow (cm)";
        this.eventSnowAccumMMLabel ="Cumulative snow (mm)";
        this.eventSnowAccumLabel = "Cumulative snow (cm)";
        this.latitudeErrorMessage = "Enter a valid latitude with at most 3 decimal places";
        this.latitudeLongitudeErrorMessage = "Enter a valid latitude, longitude (how do you get it from google maps)";
        this.longitudeErrorMessage = "Enter a valid longitude with at most 3 decimal places";
        this.dataSourceSectionTitle = "Information source";
        this.eventLocalizationSectionTitle = "Event Location";
        this.eventTimeSectionTitle = "Time of the event";
        this.eventMagnitudeSectionTitle = "Magnitude of the event";
        this.eventImpactSectionTitle = "Event impact";
        this.eventImpactLabel = "Iimpact";
        this.eventImpactTypeLabel = "Impact type";
        this.requiredMessage = "Required";
        this.cityOrLatLonRequiredMessage = "Either the city or lat/lon are required";
        this.eventReferencesTitle = "References";
        this.eventReferencesDescription = "Links to sources";
        this.eventReferencesLabel = "References";
        this.eventDescriptionTitle = "Description of the event";
        this.descriptionLabel = "Description";
        this.eventDescriptionDescription = "Free descriptive text. It can be used to weight that information whose description in strict categories was uncomfortable, provide quantitative clarification of the impacts (quantity of...), etc.";
        this.eventLocationLabel = "Location";
        this.sendReportLabel = "Submit report";
        this.eventFormStatus = "Form status";
        this.newEventRegistered = "New event registered";
        this.updateEventAction = "Update report";
        this.clearReportLabel = "Clear the current form";
        this.reporterLabel = "Reporter";
        this.breadcrumbsLabels = {
            "/": "Home",
            "/login": "Log in",
            "/change-password": this.changePasswordLabel,
            "/event/new": "New report",
            "/event/view": "Report view",
            "/event/update": "Report edit",
            "/event/list": "List of reports",
            "/not-found": "Not found",
            };
        this.nextPage = (pageSize: number) => `Next ${pageSize}`;
        this.applyFilterLabel = "apply the filter to the list";
        this.exportToExcel = "Export to Excel";
        this.openLanguagesMenu = "Language menu";
        this.openUserMenu = "User menu";
        this.processingTitle = "Processing";
        this.processingDescription = "Wait a moment please";
        this.errorTitle = "There was an error";
        this.serverError = "Server error";
        this.pageErrorTitle = "Error";
        this.uploadFileLabel = "Add an image file";
        this.fileSizeExceeded = "File size exceeded";
        this.duplicatedFilename = "Duplicated filename";
        this.reportsFilterTitle = "Reports filtering";
        this.endOfListing = "End of listing";
        this.startOfListing = "Start of listing";
        this.sortingHint = "Sort";
        this.noColumnMessage = "There are no columns to display";
        this.noDataMessage = "There is no data to display";
        this.addCommand = "Add";
        this.editCommand = "Edit";
        this.deleteCommand = "Delete";
        this.commitCommand = "Ok";
        this.cancelCommand = "Cancel";
        this.labelOrganizations = "Organizations";
        this.labelRoles = "Roles";
        this.authorizationError = "Authorization error";
        this.doNotHavePermission = "Do not have permission to perform this operation";
    }
  }
}
