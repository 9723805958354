import * as React from "react";
import {observer} from "mobx-react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

type Props = {
    title: string,
    description: string,
    open: boolean,
}

export const ProcessingDialog: React.FC<Props> = observer(({title, description, open}) => {
    return (
        <Dialog
            open={open}
            onBackdropClick={() => {}}
            disableEscapeKeyDown
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <DialogTitle id="waiting-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                </DialogContent>
            </Box>
        </Dialog>
    );
});