import {ImageCroppingData, LatLon} from "./types";
import {nearestIndex} from "../../utils";

const discardTopMargin = 106;
const discardLeftMargin = 69;
const gridSizeX = 117;
const gridSizeY = 117;

const gridsInCrop = 3;
const totalHeightInGrids = 18.2;
const totalWidthInGrids = 18.2;
const maxTopGrid = totalHeightInGrids - gridsInCrop;
const maxLeftGrid = totalWidthInGrids - gridsInCrop;

const gridLatLon = 5;
const lats = [35, 30, 25, 20, 15, 10, 5, 0, -5, -10, -15, -20, -25, -30, -35, -40, -45, -50, -55];
const lons = [-115, -110, -105, -100, -95, -90, -85, -80, -75, -70, -65, -60, -55, -50, -45, -40, -35, -30, -25];

const heightImage = gridSizeY * gridsInCrop;
const widthImage = gridSizeX * gridsInCrop;
const deltaCenterGridLatLon = gridLatLon / 2.0;
const gridsMargin = Math.trunc(gridsInCrop / 2);
const latLonsMargin = (gridsInCrop / 2) * gridLatLon;
const pixelsPerLat = gridSizeY / gridLatLon;
const pixelsPerLon = gridSizeX / gridLatLon;
const latMarginInPixels = pixelsPerLat * latLonsMargin;
const lonMarginInPixels = pixelsPerLon * latLonsMargin;

export function cropImage(latLon: LatLon): ImageCroppingData {
    let topLeftLatLon: LatLon;
    let topImage: number;
    let leftImage: number;

    let topGridIndex = nearestIndex(latLon.lat + deltaCenterGridLatLon, lats) - gridsMargin;
    let leftGridIndex = nearestIndex(latLon.lon - deltaCenterGridLatLon, lons) - gridsMargin;

    if (0 < topGridIndex && topGridIndex < maxTopGrid && 0 < leftGridIndex && leftGridIndex < maxLeftGrid) {
        topLeftLatLon = {
            lat: latLon.lat + (deltaCenterGridLatLon + gridLatLon),
            lon: latLon.lon - (deltaCenterGridLatLon + gridLatLon),
        };
        topImage = discardTopMargin + (lats[0] - latLon.lat) * pixelsPerLat - latMarginInPixels;
        leftImage = discardLeftMargin + (-lons[0] + latLon.lon) * pixelsPerLon - lonMarginInPixels;
    } else {
        topGridIndex = Math.trunc(Math.max(0, Math.min(topGridIndex, maxTopGrid)));
        leftGridIndex = Math.trunc(Math.max(0, Math.min(leftGridIndex, maxLeftGrid)));
        topLeftLatLon = {
            lat: lats[Math.trunc(topGridIndex)],
            lon: lons[Math.trunc(leftGridIndex)]
        };
        topImage = discardTopMargin + (gridSizeY * topGridIndex);
        leftImage = discardLeftMargin + (gridSizeX * leftGridIndex);
    }

    const point = {
        y: ((topLeftLatLon.lat - latLon.lat) / gridLatLon) * gridSizeY,
        x: ((-topLeftLatLon.lon + latLon.lon) / gridLatLon) * gridSizeX,
    };

    return {
        topLeftLatLon,
        point,
        latLon,
        topImage,
        heightImage,
        leftImage,
        widthImage,
    };
}
