import React from 'react';
import {observer} from "mobx-react";
import {UserForm} from "../UserForm";

export const OneUser = observer(() => {
    return (
      <div>
        <UserForm />
      </div>
    );
});