import * as React from 'react';
import {
    Autocomplete,
    FormControl, InputLabel, MenuItem, Select, TextField, Typography
} from "@mui/material";
import {observer} from "mobx-react";
import Flags from 'country-flag-icons/react/3x2';
import {useRootStore} from "../../../../stores/contexts";
import {CountryCodes} from "../../../../types";
import {CityOption} from "../../EventNewStore";
import * as _ from 'lodash';
import {EventNewCard} from "./EventNewCard";

export const EventLocation = observer(() => {
  const rootStore = useRootStore();
  const { stateStore, langStore, cityStore, eventNewStore } = rootStore;

  const states = stateStore.stateOptions.map((option, index) => {
      return (
        <MenuItem key={index} value={option.id}>
            <Typography sx={{m:1, display: "inline"}}>{option.name}</Typography>
        </MenuItem>
      );
  })

 const stateLabel = langStore.stateLabel(stateStore.countryId ? stateStore.countryId : "AR");

  return (
      <EventNewCard id={langStore.locationTitle}
                    title={langStore.locationTitle}
                    errorText={eventNewStore.cityOrLatLonErrorMessage}>
        <FormControl>
            <Select
                sx={{m:1, minWidth: 150}}
                onChange={(event) => stateStore.onSelectCountry(event.target.value as CountryCodes)}
                value={stateStore.countryId}
              >
                <MenuItem value={"AR"}>
                    <Flags.AR style={{width: 20, display: "inline"}} title={"Argentina"} />
                    <Typography sx={{m:1, display: "inline"}}>Argentina</Typography>
                </MenuItem>
                <MenuItem value={"BR"}><Flags.BR style={{width: 20, display: "inline"}} title={"Brazil"} />
                    <Typography sx={{m:1, display: "inline"}}>Brazil</Typography>
                </MenuItem>
                <MenuItem value={"UY"}><Flags.UY style={{width: 20, display: "inline"}} title={"Uruguay"} />
                    <Typography sx={{m:1, display: "inline"}}>Uruguay</Typography>
                </MenuItem>
                <MenuItem value={"CL"}><Flags.CL style={{width: 20, display: "inline"}} title={"Chile"} />
                    <Typography sx={{m:1, display: "inline"}}>Chile</Typography>
                </MenuItem>
                <MenuItem value={"PY"}><Flags.PY style={{width: 20, display: "inline"}} title={"Paraguay"} />
                    <Typography sx={{m:1, display: "inline"}}>Paraguay</Typography>
                </MenuItem>
              </Select>
        </FormControl>

        <FormControl>
            <InputLabel id="state-helper-label">{stateLabel}</InputLabel>
            <Select
                sx={{m:1, minWidth: 330}}
                labelId="select-state"
                id="select-state"
                onChange={(event) => {
                    eventNewStore.onSelectState(event.target.value);
                    cityStore.onSelectState(event.target.value);
                }}
                value={stateStore.stateOptions.length > 0 ? eventNewStore.state : ""}
                label={stateLabel}
              >
                {states}
            </Select>
        </FormControl>

        <Autocomplete
          freeSolo
          id="combo-box-city"
          options={cityStore.cityOptions}
          value={eventNewStore.city}
          onChange={(event, value: string | CityOption | null) =>
                  eventNewStore.onSelectCity(value === null ? "" : value)}
          sx={{ m:1, width: 500 }}
          renderInput={(params) =>
              <TextField {...params}
                         onChange={_.debounce((event) =>
                             cityStore.onSelectNameFragment(event.target.value), 1000)}
                         label={langStore.cityLabel} />}
        />

        <TextField
            sx={{m: 1, width: 500}}
            label={langStore.eventLocationLabel}
            value={eventNewStore.location}
            onChange={(event) => eventNewStore.onChangeLocation(event.target.value)}
        />

      </EventNewCard>
  );
});
