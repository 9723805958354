import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, TextField, Typography} from "@mui/material";
import {EventNewCard} from "./EventNewCard";

export const EventDuration = observer(() => {
  const rootStore = useRootStore();
  const { langStore, eventNewStore } = rootStore;

  let totalSeconds = eventNewStore.totalDurationSeg;
  let totalSecondsLabel = "";
  if (totalSeconds !== null) {
      totalSecondsLabel = `${totalSeconds} seg.`;
  }

  return (
      <EventNewCard title={langStore.eventDurationTitle} description={langStore.eventDurationDescription}>
        <FormControl sx={{display: "inline"}}>
            <TextField
                error={!eventNewStore.isInteger(eventNewStore.eventDuration.hours)}
                sx={{p: 2, display:"inline", width: 30}}
                label={langStore.hoursLabel}
                value={eventNewStore.eventDuration.hours}
                onChange={(event) => eventNewStore.onChangeEventDurationHours(event.target.value)}
            />
            <TextField
                error={!eventNewStore.isInteger(eventNewStore.eventDuration.minutes)}
                sx={{p: 2, display:"inline"}}
                label={langStore.minutesLabel}
                value={eventNewStore.eventDuration.minutes}
                onChange={(event) => eventNewStore.onChangeEventDurationMinutes(event.target.value)}
            />
            <TextField
                error={!eventNewStore.isInteger(eventNewStore.eventDuration.seconds)}
                sx={{p: 2, display:"inline"}}
                label={langStore.secondsLabel}
                value={eventNewStore.eventDuration.seconds}
                onChange={(event) => eventNewStore.onChangeEventDurationSeconds(event.target.value)}
            />
            <Typography sx={{m:1, display: "inline"}} variant={"h6"}>{totalSecondsLabel}</Typography>
        </FormControl>
      </EventNewCard>
  );
});
