import {ElevationScroll} from "./ElevationScroll";
import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import {observer} from "mobx-react";
import {useResizeDetector} from "react-resize-detector";
import {getFloatingBar} from "./floatingBar";

const boxAppMargins = 0;

type Props = {
    drawerWidth: number,
    barTop: number,
    closedDrawerWidth: number,
    sideBarOpen: boolean,
    updateHeight: (size: number | undefined) => void,
    children?: JSX.Element | JSX.Element[],
}

export const ToolBar: React.FC<Props> = observer(({
    drawerWidth,
    sideBarOpen,
    barTop,
    closedDrawerWidth,
    updateHeight,
    children,
}) => {
    const [barHeight, setBarHeight] = useState<number | undefined>(undefined);

    useLayoutEffect(() => {
        if (barHeight) {
            updateHeight(barHeight);
        }
    }, [barHeight, updateHeight]);

    const onResize = useCallback((width: number | undefined, height: number | undefined) => {
      if (height !== barHeight) {
        setBarHeight(height);
      }
    }, [barHeight]);

    const barRef = useRef<HTMLDivElement | null>(null);

    useResizeDetector({
        targetRef: barRef,
        // setParentState can't be called here as parent is still rendering
        onResize,
        refreshMode: "debounce",
        handleWidth: false,
        refreshRate: 100,
        refreshOptions: { trailing: true, leading: false },
    });

    const FloatingBar = getFloatingBar(closedDrawerWidth, drawerWidth, boxAppMargins);

    return (
      <ElevationScroll>
        <FloatingBar
            ref={barRef}
            style={{marginTop: barTop}}
            position="fixed"
            open={sideBarOpen}
            color={"default"}
        >
            <Box sx={{ml: 2}}>
                {children}
            </Box>
        </FloatingBar>
      </ElevationScroll>
    );
});