import React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {ListUser} from "./ListUser";
import {ListToolbox} from "../../../common/ListToolbox";
import {Sorting} from "../../../common/types";
import {ListEntityPage} from "../../../common/entities/pages/ListEntityPage";

export const UserListPage = observer(() => {
    const rootStore = useRootStore();
    const {userListStore, langStore} = rootStore;

    const listToolbox = <ListToolbox
            paginatorProps={{
                page: userListStore.currentPage,
                totalRows: userListStore.entityList.length,
                rowsPerPage: userListStore.pageSize,
                hasMorePages: userListStore.hasMorePages,
                onPageChange: (newPage: number) => userListStore.onPageChange(newPage),
                onPageSizeChange: (pageSize: number) => userListStore.onPageSizeChange(pageSize),
            }}
            createNewElement={() => userListStore.createNewUser()}
            exportToExcel={() => userListStore.toExcel()}
            columnChooserProps={{
                columns: userListStore.columnChooserInfo,
                onChoose: (name: string) => userListStore.toggleColumnHidden(name),
                anchorMenu: userListStore.anchorMenu,
                setAnchorMenu: (element) => userListStore.setAnchorMenu(element),
            }}
        />;

    return (
      <ListEntityPage
        navigationElements={{
            links: [
                {id: "StartOfListing", label: langStore.startOfListing},
                {id: "EndOfListing", label: langStore.endOfListing},
            ],
        }}
        store={userListStore}
        secondaryToolbox={listToolbox}
      >
        <ListUser
          columnExtensions={userListStore.columnExtensions}
          columnOrder={userListStore.columnOrder}
          columnWidths={userListStore.columnWidths}
          columns={userListStore.columns}
          componentId={"ListOfUsers"}
          currentPage={userListStore.currentPage}
          viewRow={(id) => userListStore.viewUser(id)}
          editRow={(id) => userListStore.editRow(id)}
          userFormat={(user) => userListStore.userFormat(user)}
          hasMorePages={userListStore.hasMorePages}
          hiddenColumnsNames={userListStore.hiddenColumns}
          nextPage={() => userListStore.getNextPage()}
          onColumnOrderChange={(order) => userListStore.onColumnOrderChange(order)}
          onColumnWidthsChange={(widths) => userListStore.onColumnWidthsChange(widths)}
          onHiddenColumnNamesChange={(columns) => userListStore.onHiddenColumnChange(columns)}
          onSelectionChange={(selection) => userListStore.onSelectionChange(selection)}
          onSortingChange={(columns) => userListStore.onSortingChange(columns as Sorting[])}
          sorting={userListStore.sorting}
          pageSize={userListStore.pageSize}
          selectUser={(rowClickEvent) => userListStore.viewUser(rowClickEvent.data.id)}
          setPageSize={(pageSize) => userListStore.setPageSize(pageSize)}
          users={userListStore.usersOfCurrentPage}
          reloadedUser={userListStore.reloadedUser}
        />
      </ListEntityPage>
    );
});
