import * as React from 'react';
import {observer} from "mobx-react";
import {GridExpress, IActionColumn} from "../../../components";
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import {ChangeSet, Column} from "@devexpress/dx-react-grid";
import {ColumnWidthInfo} from "../types";
import {Table} from "@devexpress/dx-react-grid-material-ui";

const TableCell: any = Table.Cell;

export type RowCrudStatus = "added" | "added-deleted" | "added-updated"
        | "original-updated" | "original-updated-deleted"
        | "original" | "original-deleted";

export const originalDeletedStatusList: RowCrudStatus[] = ["original-deleted", "original-updated-deleted"];
export const originalUpdatedStatusList: RowCrudStatus[] = ["original-updated"];
export const addedStatusList: RowCrudStatus[] = ["added", "added-updated"];

export type CrudRows = Record<string, {
    __status__: RowCrudStatus,
    id: string,
    [name: string]: any
}>

type Props = {
    rows: CrudRows;
    onCommitChanges: (changes: ChangeSet) => void,
    onRestore: (id: string) => void,
    columns: Column[],
    columnWidths?: ColumnWidthInfo[],
    hiddenColumnNames?: string[],
    actionColumns?: IActionColumn[];
    dummyRestoredObservable: any;
    showEditCommand?: boolean;
    showDeleteCommand?: boolean;
    showAddCommand?: boolean;
    inlineEdit?: boolean;
    popupComponent?: (row: any) => JSX.Element,
};

export const ItemsCRUD: React.FC<Props> = observer((
    {
        rows,
        onRestore,
        onCommitChanges,
        columns,
        columnWidths,
        hiddenColumnNames=[],
        actionColumns=[],
        dummyRestoredObservable,
        showEditCommand,
        showDeleteCommand,
        showAddCommand,
        inlineEdit= true,
        popupComponent,
}) => {
    const gridRows = Object.values(rows);
    const allActionColumns = [
        ...actionColumns,
        {
          columnName: "__action_restore",
          // label: "Restore",
          onClick: (props: any) => {
              onRestore(props.id);
          },
          isActionEnabled: (row: any) => ["original-updated-deleted", "original-deleted"].includes(row.__status__),
          icon: <RestoreIcon />
        }
    ];

  let dummy = dummyRestoredObservable;
  dummy++;

  const tableColumns = [
      {name: "__action_restore", title: ""},
      ...columns
  ]

  const bgColor = (status: RowCrudStatus) =>
      status === "added" ? "#caefc2" :
          ["original-updated-deleted", "original-deleted", "added-deleted"].includes(status) ? "#e5acac" :
            ["original-updated", "added-updated"].includes(status) ? "#e5e2ac" :
                "";

  const cellComponent = (props:any) => {
    return <TableCell {...props} style={{backgroundColor: bgColor(props.row.__status__)}} />;
  }

  return (
      <div>
        <GridExpress
            columns={tableColumns}
            rows={gridRows}
            columnWidths={columnWidths}
            editingProps={{
                onCommitChanges: (changes: ChangeSet) => onCommitChanges(changes),
                inlineEdit,
            }}
            actionColumns={allActionColumns}
            hiddenColumnNames={hiddenColumnNames}
            // sorting={sorting}
            // onSortingChange={}
            // columnExtensions={}
            // currentPage={}
            // pageSize={}
            // onSelectionChange={}
            cellComponent={cellComponent}
            // columnOrder={}
            // onColumnOrderChange={}
            // onColumnWidthsChange={}
            showEditCommand={showEditCommand}
            showDeleteCommand={showDeleteCommand}
            showAddCommand={showAddCommand}
            editComponent={popupComponent ? popupComponent : undefined}
            editInPopup={true}
        />
      </div>
  );
});
