// Wrapper for devexpress Grid because does not support typescript in Rect 18
import {reactGridExpress} from "./reactGridExpress";
import {
    Column,
    Sorting,
    SortingState,
    EditingStateProps,
    TableColumnWidthInfo
} from "@devexpress/dx-react-grid";
import * as React from "react";
import {observer} from "mobx-react";
import {IActionColumn} from "./plugins/ActionColumns";
import {
    Table,
    TableColumnVisibilityProps,
} from "@devexpress/dx-react-grid-material-ui";
import {useRootStore} from "../../stores/contexts";

import {PopupEditing} from "./plugins/PopupEditing";
import {PopupDialog, PopupDialogProps} from "./plugins/PopupDialog";
import {DetailEditCell} from "./plugins/DetailEditCell";
import {ToggleCell} from "./plugins/ToggleCell";
import {DetailCell} from "./plugins/DetailCell";
import {Button, TextField} from "@mui/material";

const TableCell: any = Table.Cell;

export interface RowType {
    id: string;
    [key: string]: any;
}

export interface CustomEditingStateProps extends EditingStateProps {
   inlineEdit?: boolean;
}

type Props = {
    columns?: Column[];
    rows?: RowType[];
    sorting?: Sorting[];
    onSortingChange?: (sorting: Sorting[]) => void;
    columnExtensions?: SortingState.ColumnExtension[];
    currentPage?: number;
    pageSize?: number;
    onSelectionChange?: (selection: (number | string)[]) => void;
    cellComponent?: (props: any) => JSX.Element;
    actionColumns?: IActionColumn[];
    columnOrder?: string[];
    onColumnOrderChange?: (nextOrder: string[]) => void;
    columnWidths?: TableColumnWidthInfo[];
    onColumnWidthsChange?: (nextColumnWidths: TableColumnWidthInfo[]) => void;
    tableColumnVisibility?: TableColumnVisibilityProps;
    hiddenColumnNames?: string[],
    onHiddenColumnNamesChange?: (hiddenColumnNames: string[]) => void,
    editingProps?: CustomEditingStateProps,
    showEditCommand?: boolean,
    showDeleteCommand?: boolean,
    showAddCommand?: boolean,
    editComponent?: (row: any) => JSX.Element,
    editInPopup?: boolean;
};

export const GridExpress: React.FC<Props> = observer(({
    columns= [],
    rows= [],
    sorting,
    onSortingChange= () => {},
    columnExtensions,
    currentPage,
    pageSize,
    onSelectionChange,
    cellComponent= (props) => <TableCell {...props} />,
    actionColumns= [],
    columnOrder,
    onColumnOrderChange,
    columnWidths,
    onColumnWidthsChange= () => {},
    hiddenColumnNames,
    onHiddenColumnNamesChange,
    editingProps,
    showEditCommand = true,
    showDeleteCommand = true,
    showAddCommand = true,
    editComponent,
    editInPopup= false,
}) => {
    const rootStore = useRootStore();
    const { langStore } = rootStore;

    const getRowId = (row: RowType) => row.id;

    const popupDialog = editComponent && editInPopup ?(props: PopupDialogProps) => {
        return (
          <PopupDialog
            editComponent={editComponent(props.row)}
            onApplyChanges={props.onApplyChanges}
            onCancelChanges={props.onCancelChanges}
            onChange={props.onChange}
            open={props.open}
            row={props.row} />
          );
      } :  undefined;


    // Call to js version of devexpress Grid because does not support typescript in Rect 18
    return reactGridExpress(
        {
            columns,
            rows,
            getRowId,
            sorting,
            onSortingChange,
            columnExtensions,
            currentPage,
            pageSize,
            onSelectionChange,
            cellComponent,
            actionColumns,
            columnOrder,
            onColumnOrderChange,
            columnWidths,
            onColumnWidthsChange,
            hiddenColumnNames,
            onHiddenColumnNamesChange,
            sortingHint: langStore.sortingHint,
            noColumnMessage: langStore.noColumnMessage,
            editingProps,
            noDataMessage: langStore.noDataMessage,
            inlineEdit: editingProps && editingProps.inlineEdit === true,
            addCommand: langStore.addCommand,
            editCommand: langStore.editCommand,
            deleteCommand: langStore.deleteCommand,
            commitCommand: langStore.commitCommand,
            cancelCommand: langStore.cancelCommand,
            showEditCommand,
            showDeleteCommand,
            showAddCommand,
            PopupEditing,
            DetailEditCell,
            DetailCell,
            ToggleCell,
            popupDialog,
            editComponent: !editInPopup ? editComponent : undefined,
        });
})


