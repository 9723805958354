import * as React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FilteredEventList} from "../FilteredEventList";
import {Sorting} from "../../../common/types";
import {ReportsMap} from "../../../../components/maps/ReportsMap";
import {Box, Grid, Typography} from "@mui/material";
import {getInfoBlocks} from "../eventInfo";
import {useTheme} from "@mui/material/styles";

export const ListEvent = observer(() => {
    const rootStore = useRootStore();
    const {
        eventListStore,
        breadcrumbsStore,
        langStore,
    } = rootStore;

    const theme = useTheme();

    const event = eventListStore.selectedEvent;
    const boxesColor = `${theme.palette.primary.main}30`;
    const {mainInfo, impactInfo, referencesInfo} = getInfoBlocks({rootStore, event, boxesColor});
    const tableColumnWidth = "360px";

    return (
        <div id={langStore.reportingMap}>
            <Typography variant={"h6"}>{langStore.reportingMap}</Typography>
            <Grid container>
              <Grid item width={"700px"}>
                <Box>
                  <ReportsMap
                      featureCollection={eventListStore.eventsForMap}
                      selectFeature={(id) => eventListStore.selectFeature(id)}
                      selectedId={eventListStore.selectedEvent?.id}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Grid container spacing={1} direction={"row"}>
                  <Grid item style={{width: tableColumnWidth}}>
                    {mainInfo}
                  </Grid>
                  <Grid item style={{width: tableColumnWidth}}>
                      {impactInfo}
                  </Grid>
                  <Grid item style={{width: tableColumnWidth}}>
                    {referencesInfo}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{marginBottom: 30}} />
            <FilteredEventList
                componentId={"EventsFiltering"}
                events={eventListStore.eventsOfCurrentPage}
                reloadedEvent={eventListStore.reloadedEvent}
                filter={eventListStore.filter}
                setFilterValue={(filter) => eventListStore.setFilterValue(filter)}
                applyFilter={() => eventListStore.applyFilter()}
                selectEvent={(rowClickEvent) => breadcrumbsStore.push(`/event/view`, false, {id: rowClickEvent.data.id})}
                pageSize={eventListStore.pageSize}
                hasMorePages={eventListStore.hasMorePages}
                nextPage={() => eventListStore.getNextPage()}
                setPageSize={(pageSize) => eventListStore.setPageSize(pageSize)}

                onColumnWidthsChange={(widths) => eventListStore.onColumnWidthsChange(widths)}
                columnWidths={eventListStore.columnWidths}
                onColumnOrderChange={(order) => eventListStore.onColumnOrderChange(order)}
                columnOrder={eventListStore.columnOrder}
                viewRow={(id) => eventListStore.viewRow(id)}
                editRow={(id) => eventListStore.editRow(id)}
                deleteRow={(id) => eventListStore.deleteRow(id)}
                columns={eventListStore.columns}
                onHiddenColumnNamesChange={(columns) => eventListStore.onHiddenColumnChange(columns)}
                hiddenColumnsNames={eventListStore.hiddenColumns}
                onSortingChange={(columns) => eventListStore.onSortingChange(columns as Sorting[])}
                sorting={eventListStore.sorting}
                columnExtensions={eventListStore.columnExtensions}
                onSelectionChange={(selection) => eventListStore.onSelectionChange(selection)}
                eventFormat={(e) => eventListStore.grid.eventFormat(e)}
                currentPage={eventListStore.currentPage}
            />
            <div id={"EndOfListing"} />
        </div>
    );
});
