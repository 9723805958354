import * as React from 'react';
import {observer} from "mobx-react";
import {RadioButtons} from "../../../../components";
import {useRootStore} from "../../../../stores/contexts";
import {EventNewCard} from "./EventNewCard";


export const EventInformationSource = observer(() => {
  const rootStore = useRootStore();
  const { langStore, informationSourceStore, eventNewStore } = rootStore;

  return (
    <EventNewCard id={langStore.sourceInformationTitle} title={langStore.sourceInformationTitle} errorText={eventNewStore.informationSourceErrorMessage}>
      <RadioButtons
        value={eventNewStore.informationSource}
        onChange={(id: string) => eventNewStore.onSelectInformationSource(id)}
        options={informationSourceStore.informationSourceOptions} />
    </EventNewCard>
  );
});
