import React from 'react';
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {useRootStore} from "../../../stores/contexts";

type Props = {
    onClick: () => void;
    disabled?: boolean;
}

export const SaveButton: React.FC<Props> = observer(({onClick, disabled}) => {
    const rootStore = useRootStore();
    const {
        prefStore,
        langStore
    } = rootStore;

    return (
        <Button
            size={prefStore.toolbarSize}
            variant="outlined" startIcon={<SaveIcon />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onClick}>
            {langStore.commitCommand}
        </Button>
    );
});

export const SaveAndCreateButton: React.FC<Props> = observer(({onClick, disabled}) => {
    return (
        <Button
            size={"small"}
            variant="outlined" startIcon={<SaveAsIcon />}
            disabled={disabled === undefined ? false : disabled}
            onClick={onClick}>
            Guardar y Crear uno nuevo
        </Button>
    );
});
