import { GraphQLClient } from "graphql-request";
import { getSdk } from "./graphql";
import {AuthStore} from "../auth";
import { AuthStoreInterface } from "../auth/AuthStoreInterface";

const ENDPOINT = process.env.REACT_APP_ENDPOINT || "https://baseseveros.cima.fcen.uba.ar";

export async function login(auth: AuthStore) {
    const endpoint = `${ENDPOINT}/graphql`;
    const client = new GraphQLClient(endpoint, { headers: {} });
    const api = getSdk(client);
    try {
        const data = await api.Login({email: auth.email, password: auth.password});
        auth.setToken(data.login.token);
    } catch (error) {
        auth.setToken(null);
    }
}

export function getApi(auth: AuthStore) {
    const endpoint = `${ENDPOINT}/graphql`;
    const clientHeaders = {
             authorization: auth.token !== null ? `Bearer ${auth.token}` : ""
         };
    const client = new GraphQLClient(endpoint, { headers: clientHeaders });
    return getSdk(client);
}

export async function apiUploadFile(
  auth: AuthStoreInterface,
  preflight = false,
  file: File
) {
  const endpoint = `${ENDPOINT}/graphql`;
  const clientHeaders: HeadersInit = {
    authorization: auth.token !== null ? `Bearer ${auth.token}` : "",
  };
  if (preflight) {
    clientHeaders["apollo-require-preflight"] = "true";
  }

  const formData = new FormData();
  const query = {
    query:
      "mutation fileUpload($file: Upload!) {\n  singleUpload(file: $file) {\n    ticket\n    filename\n    mimetype\n    encoding\n  }\n}\n",
    variables: { file: null },
    operationName: "fileUpload",
  };
  formData.append("operations", JSON.stringify(query));
  formData.append("map", `{ "1": ["variables.file"] }`);
  formData.append("1", file);

  let response = await fetch(endpoint, {
    method: "POST",
    headers: clientHeaders,
    body: formData,
  });
  const json = await response.json();
  return json.data;
}

export async function download(auth: AuthStore, directory: string) {
    const endpoint = `${ENDPOINT}/download`;
    const response = await fetch(endpoint + directory, {
          method: 'GET',
          headers: {
             authorization: auth.token !== null ? `Bearer ${auth.token}` : ""
          }
    });
    return await response.blob();
}

