import {makeAutoObservable, computed} from 'mobx';
import {RootStore} from '../../stores';
import {CountryCodes, ErrorMessageItem, ServerErrorMessage, UploadedFileType} from "../../types";
import {apiUploadFile, download, getApi} from "../../api/apiClient";
import {
  Event,
  EventImage,
  NewEventMutationVariables,
  ReferenceLink,
  UpdateEventMutationVariables
} from "../../api/graphql";
import {StoreStatus, WebPageStore} from "../../stores/types";
import {downloadFromBlob, getUrlFromBlob} from "./imageUtils";
import {ChangeSet} from "@devexpress/dx-react-grid";
import {
  addedStatusList,
  CrudRows,
  originalDeletedStatusList,
  originalUpdatedStatusList,
  RowCrudStatus
} from "../common/controls/ItemsCRUD";
import {getApiError} from "../../api/apiErrors";

export type Duration = {hours: string, minutes: string, seconds: string};
export type CityOption = { value: string; label: string; stateId: string; }

interface ReferenceCrudRow extends ReferenceLink {
    __status__: RowCrudStatus,
}
type ReferenceCrudRows = Record<string, ReferenceCrudRow>;

const MAX_FILE_SIZE_BYTES = 1000 * 1000 * 10; // 10 Mb

export class EventNewStore implements WebPageStore {
  readonly rootStore: RootStore;
  private uniqueCounter = 1;
  eventTypes: Record<string, boolean> = {};
  eventTypeKeys: string[] = [];
  impacts: Record<string, boolean> = {};
  impactKeys: string[] = [];
  confidence: string = "RAW";
  informationSource: string = "";
  savedEventId = "";
  currentEventId = "";
  callbackUpdatedParams: any = undefined;
  deletedImages: Record<string,EventImage> = {};
  state = "";
  city:  string | CityOption = "";
  location:  string = "";
  dummyRestoredObservable: number = 0;
  eventTime: Date | null = null;
  eventDuration: Duration = {hours: "", minutes: "", seconds: ""};
  latitude = "";
  longitude = "";
  latitudeLongitude = "";
  temporalPrecisionSeg = "";
  spacialPrecisionMeters = "";
  windDirectionDegrees = "";
  detailedLocation = "";
  hailMaxDiameterCM = "";
  windSpeedKmH = "";
  windSpeedMaxKmH = "";
  precipitationAccumMM = "";
  snowAccumCM = "";
  description = "";
  references: ReferenceLink[] = [];
  referencesCrudRows: ReferenceCrudRows = {};
  saveErrorMessage: ServerErrorMessage = {message_en: '', message_es: '', message_pt: '', code: ''};
  status: StoreStatus = "uninitialized";
  storeId: string = "";
  event: Event | null = null;
  contentTop: number = 0;
  errorDialogOpen: boolean = false;
  errorTitle: string = "Error";
  errorDescription: string = "Hubo un error";
  uploadedFiles: Record<string, UploadedFileType> = {};
  navigateTo = "";
  navigateToHash = "";
  currentImage: string = "";
  confirmMessage: string = "";

  constructor(rootStore: RootStore, instanceId: string) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.storeId = instanceId;
  }

  destroy() {
    // this object will be unusable
  }

  setStatus(status: StoreStatus) {
    this.status = status;
  }

  navigateToError(id: string) {
    this.navigateTo = id;
    this.navigateToHash = (this.uniqueCounter++).toString();
  }

  setContentTop(contentTop: number) {
    this.contentTop = contentTop;
  }

  @computed get isDisableHail() {
    return !this.eventTypes["HAIL"];
  }

  @computed get isDisableWind() {
    const filtered = ["WHITE_WIND", "WIND", "FOHEN"].filter(value => value in this.eventTypes);
    return filtered.length === 0;
  }

  @computed get isDisabledPrecipitation() {
    return !this.eventTypes["RAIN"];
  }

  @computed get isDisableSnow() {
    return !this.eventTypes["SNOW"];
  }

  onMessageConfirmed() {
    this.confirmMessage = "";
  }

  onSelectEventType(id: string, checked: boolean) {
    if (!checked && this.eventTypes[id]) {
      const index = this.eventTypeKeys.indexOf(id, 0);
      if (index > -1) {
        this.eventTypeKeys.splice(index, 1);
      }
      delete this.eventTypes[id];
    } else if(checked) {
      this.eventTypes[id] = checked;
      this.eventTypeKeys.push(id);
    }
  }

  onSelectImpact(id: string, checked: boolean) {
    if (!checked && this.impacts[id]) {
      const index = this.impactKeys.indexOf(id, 0);
      if (index > -1) {
        this.impactKeys.splice(index, 1);
      }
      delete this.impacts[id];
    } else if(checked) {
      this.impacts[id] = checked;
      this.impactKeys.push(id);
    }
  }

  onSelectConfidence(id: string) {
    this.confidence = id;
  }

  onSelectInformationSource(id: string) {
    this.informationSource = id;
  }

  onSelectState(id: string) {
    this.state = id;
    if (id !== null && this.city !== null && (typeof this.city) !== "string")  {
      const stateId = (this.city as CityOption).stateId;
      if (stateId !== id) {
        this.city = "";
      }
    }
  }

  onSelectCity(value: string | CityOption) {
    this.city = value;
    if (value !== null && (typeof value) !== "string")  {
      const stateId = (value as CityOption).stateId;
      if (stateId !== null) {
        this.state = stateId;
      }
    }
  }

  onChangeLocation(value: string) {
    this.location = value;
  }

  onSelectTemporalPrecision(value: string) {
    this.temporalPrecisionSeg = value;
  }

  onChangeWindSpeedKmH(value: string) {
    this.windSpeedKmH = value;
  }

  onChangeWindMaxSpeedKmH(value: string) {
    this.windSpeedMaxKmH = value;
  }

  onSelectSpatialPrecision(value: string) {
    this.spacialPrecisionMeters = value;
  }

  onSelectWindDirectionDegrees(value: string) {
    this.windDirectionDegrees = value;
  }

  onChangeDescription(value: string) {
    this.description = value;
  }

  onChangeEventDateTime(value: Date | null) {
    this.eventTime = value;
  }

  onChangeEventLatitude(value: string) {
    this.latitude = value;
  }

  getValidLatitudeLongitude(): string[] | null {
    let latLon: string[];
    try {
      latLon =  this.latitudeLongitude.split(",");
      if (latLon.length === 2) {
        let lat = parseFloat(latLon[0]).toFixed(3);
        let lon = parseFloat(latLon[1]).toFixed(3);
        return [lat, lon];
      }
    } catch {
      return null;
    }
    return null;
  }

  onChangeEventLatitudeLongitude(value: string) {
    this.latitudeLongitude = value;
    if (value !== "") {
      const latLon = this.getValidLatitudeLongitude();
      if (latLon !== null) {
        this.onChangeEventLatitude(latLon[0]);
        this.onChangeEventLongitude(latLon[1]);
      } else {
        this.onChangeEventLatitude("");
        this.onChangeEventLongitude("");
      }
    }
  }

  @computed get isLatitudeLongitudeValid() {
    if (this.latitudeLongitude === "") return true;
    return this.getValidLatitudeLongitude() !== null;
  }

  @computed get confidenceErrorMessage() {
    if (this.confidence === "") {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  @computed get eventTypesErrorMessage() {
    if (this.eventTypeKeys.length === 0) {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  @computed get informationSourceErrorMessage() {
    if (this.informationSource === "") {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  @computed get eventTimeErrorMessage() {
    if (!this.isEventTimeValid) {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  @computed get temporalPrecisionErrorMessage() {
    if (this.temporalPrecisionSeg === "") {
      return this.rootStore.langStore.requiredMessage;
    }
    return undefined;
  }

  @computed get cityOrLatLonErrorMessage() {
    if (this.city === "" && (this.latitude === "" || this.longitude === "")) {
      return this.rootStore.langStore.cityOrLatLonRequiredMessage;
    }
    return undefined;
  }

  @computed get latitudeErrorMessage() {
    if (!this.isLatitudeValid) {
      return this.rootStore.langStore.latitudeErrorMessage;
    }
    return "";
  }

  @computed get latitudeLongitudeErrorMessage() {
    if (!this.isLatitudeValid || !this.isLongitudeValid) {
      return this.rootStore.langStore.latitudeLongitudeErrorMessage;
    }
    return "";
  }

  onChangeEventLongitude(value: string) {
    this.longitude = value;
  }

  @computed get isEventTimeValid() {
    if (this.eventTime) {
      return this.eventTime.getTime() < new Date().getTime()
          && this.eventTime.getTime() > new Date(1800, 1, 1, 0, 0, 0, 0).getTime();
    }
    return false;
  }

  @computed get isLatitudeValid() {
    if (this.longitude === "" && this.latitude === "") {
      return true;
    }
    const valid = /^[+-]?\d{1,3}(\.\d{1,3})?$/.test(this.latitude);
    if (valid) {
      const value = parseFloat(this.latitude);
      if (value <= -55 || value >= 35) {
        return false;
      }
    }
    return valid;
  }

  @computed get isLongitudeValid() {
    if (this.longitude === "" && this.latitude === "") {
      return true;
    }
    const valid = /^[+-]?\d{1,3}(\.\d{1,3})?$/.test(this.longitude);
    if (valid) {
      const value = parseFloat(this.longitude);
      if (value <= -115 || value >= -25) {
        return false;
      }
    }
    return valid;
  }

  @computed get longitudeErrorMessage() {
    if (!this.isLongitudeValid) {
      return this.rootStore.langStore.longitudeErrorMessage;
    }
    return "";
  }

  isHailDiameterValid(value: string) {
    if (value === "") return true;
    return /^[+-]?\d{1,3}(\.\d{1,3})?$/.test(value);
  }

  onChangeHailDiameter(value: string) {
    this.hailMaxDiameterCM = value;
  }

  isInteger(value: string) {
    if (value === "") return true;
    return /^\d+$/.test(value);
  }

  onChangeEventDurationHours(value: string) {
    this.eventDuration.hours = value;
  }

  onChangeEventDurationMinutes(value: string) {
    this.eventDuration.minutes = value;
  }

  onChangeEventDurationSeconds(value: string) {
    this.eventDuration.seconds = value;
  }

  onChangeDetailedLocation(value: string) {
    this.detailedLocation = value;
  }

  onChangePrecipitationAccum(value: string) {
    this.precipitationAccumMM = value;
  }

  onChangeSnowAccum(value: string) {
    this.snowAccumCM = value;
  }

  @computed get canSendReport() {
    return this.formErrors.length === 0;
  }

  onCommitChanges(changes: ChangeSet, crudRows: CrudRows) {
    if (changes.added) {
      changes.added.forEach(x=>{
        const id = `__${this.uniqueCounter++}`;
        crudRows[id] = {...x, id, __status__: "added"}
      })
    }
    if (changes.changed) {
      Object.entries(changes.changed).forEach(([id, x])=> {
        if (x) {
          let areEquals = true;
          Object.entries(x).forEach(item => {
            if (crudRows[id][item[0]] !== item[1]) {
              areEquals = false;
            }
          });
          if (!areEquals) {
            if (crudRows[id].__status__ === "original") {
                crudRows[id] = {...crudRows[id], ...x, __status__: "original-updated"}
            } else if (crudRows[id].__status__ === "original-deleted") {
              crudRows[id] = {...crudRows[id], ...x, __status__: "original-updated-deleted"}
            } else {
              crudRows[id] = {...crudRows[id], ...x}
            }
          }
        }
      })
    }
    if (changes.deleted) {
      this.dummyRestoredObservable = this.uniqueCounter++;
      changes.deleted.forEach(x=>{
        if (crudRows[x].__status__ === "added") {
          delete crudRows[x];
        } else {
          if (crudRows[x].__status__ === "original") {
            crudRows[x].__status__ = "original-deleted";
          } else if (crudRows[x].__status__ === "original-updated") {
            crudRows[x].__status__ = "original-updated-deleted";
          }
        }
      })
    }
  }

  onReferencesCommitChanges(changes: ChangeSet) {
    this.onCommitChanges(changes, this.referencesCrudRows);
  }

  onRestore(id: string, crudRows: CrudRows) {
    if (crudRows[id].__status__ === "original-deleted") {
       crudRows[id].__status__ = "original";
    } else if (crudRows[id].__status__ === "original-updated-deleted") {
       crudRows[id].__status__ = "original-updated";
    }
    this.dummyRestoredObservable = this.uniqueCounter++;
  }

  onRestoreReference(id: string) {
    this.onRestore(id, this.referencesCrudRows);
  }

  setEventId(eventId: string) {
    this.currentEventId = eventId;
  }

  setCallbackUpdateParams(callbackUpdatedParams: any) {
    this.callbackUpdatedParams = callbackUpdatedParams;
  }

  * close() {
    if (this.callbackUpdatedParams) {
      yield this.rootStore.returnedData(this.callbackUpdatedParams);
      window.history.go(-1);
    }
  }

  setEvent(event: Event) {
    this.event = event;
    this.copyFromEvent(event);
  }

  clearReport() {
    this.saveErrorMessage.message_en = "";
    this.saveErrorMessage.message_pt = "";
    this.saveErrorMessage.message_es = "";
    this.references = [];
    this.referencesCrudRows = {};
    this.uploadedFiles = {};

    Object.keys(this.eventTypes).forEach(k => this.eventTypes[k] = false);
    this.eventTypeKeys = [];
    Object.keys(this.impacts).forEach(k => this.impacts[k] = false);
    this.impactKeys = [];

    this.event = null;
    this.savedEventId = "";
    this.eventTime = null;
    this.informationSource = "";
    this.temporalPrecisionSeg = "";
    this.eventDuration.hours = "";
    this.eventDuration.minutes = "";
    this.eventDuration.seconds = "";
    this.description = "";
    this.confidence = "RAW";
    this.latitude = "";
    this.longitude = "";
    this.latitudeLongitude = "";
    this.state = "";
    this.city = "";
    this.spacialPrecisionMeters = "";
    this.location = "";
    this.detailedLocation = "";
    this.hailMaxDiameterCM = "";
    this.windSpeedKmH = "";
    this.windSpeedMaxKmH = "";
    this.windDirectionDegrees = "";
    this.precipitationAccumMM = "";
    this.snowAccumCM = "";
  }

  initReferencesCrud(references: ReferenceLink[]) {
    references.forEach(x => {
      this.referencesCrudRows[x.id] = {__status__: "original", ...x};
    })
  }

  copyFromEvent(event: Event) {
      this.savedEventId = event.id;
      event.eventTypes.forEach(x => {this.eventTypes[x.id] = true});
      this.eventTypeKeys = event.eventTypes.map(x => {return x.id});
      event.impacts?.forEach(x => {this.impacts[x.id] = true});
      this.impactKeys = event.impacts ? event.impacts.map(x => {return x.id}) : [];
      this.eventTime = new Date(event.eventTime);
      this.informationSource = event.source.id;
      this.temporalPrecisionSeg = event.temporalPrecisionSeg ? event.temporalPrecisionSeg.toString() : "";
      if (event.totalDurationSeg) {
        const seconds = event.totalDurationSeg % (60);
        const minutes = ((event.totalDurationSeg - seconds) / 60) % (60);
        const hours = Math.floor(event.totalDurationSeg / (60*60));
        this.eventDuration.seconds = seconds === 0 ? "" : seconds.toString();
        this.eventDuration.minutes = minutes === 0 ? "" : minutes.toString();
        this.eventDuration.hours = hours === 0 ? "" : hours.toString();
      } else {
        this.eventDuration.seconds ="";
        this.eventDuration.minutes = "";
        this.eventDuration.hours = "";
      }
      this.description = event.description ? event.description.toString() : "";
      this.references = event.references ? event.references : [];
      this.initReferencesCrud(this.references);
      this.confidence = event.confidence ? event.confidence.id : "";
      this.latitude = event.latitude ? event.latitude.toString() : "";
      this.longitude = event.longitude ? event.longitude.toString() : "";
      this.state = event.state ? event.state.id : "";
      if (event.city) {
        this.rootStore.cityStore.setCityPage([event.city]);
        this.city = {value: event.city.id, label: event.city.name, stateId: event.city.state.id};
        this.state = event.city.state.id ?event.city.state.id : "";
      } else {
        this.city = "";
      }
      this.spacialPrecisionMeters = event.spacialPrecisionMeters ? event.spacialPrecisionMeters.toString() : "";
      this.location = event.location ? event.location : "";
      this.detailedLocation = event.detailedLocation ? event.detailedLocation : "";
      this.hailMaxDiameterCM = event.hailMaxDiameterMM ? (event.hailMaxDiameterMM / 10).toString() : "";
      this.windSpeedKmH = event.windSpeedKmH ? event.windSpeedKmH.toString() : "";
      this.windSpeedMaxKmH = event.windSpeedMaxKmH ? event.windSpeedMaxKmH.toString() : "";
      this.windDirectionDegrees = event.windDirectionDegrees ? event.windDirectionDegrees.toString() : "";
      this.precipitationAccumMM = event.precipitationAccumMM ? event.precipitationAccumMM.toString() : "";
      this.snowAccumCM = event.snowAccumMM ? (event.snowAccumMM / 10).toString() : "";
  }

  @computed get formErrors(): ErrorMessageItem[] {
    const errors = [];
    const { langStore } = this.rootStore;
    if (this.confidenceErrorMessage) {
      errors.push({name: langStore.qualityControlTitle, error: this.confidenceErrorMessage});
    }
    if (this.informationSourceErrorMessage) {
      errors.push({name: langStore.sourceInformationTitle, error: this.informationSourceErrorMessage});
    }
    if (this.eventTimeErrorMessage) {
      errors.push({name: langStore.eventDateTimeTitle, error: this.eventTimeErrorMessage});
    }
    if (this.eventTypesErrorMessage) {
      errors.push({name: langStore.eventTypeTitle, error: this.eventTypesErrorMessage});
    }
    if (this.temporalPrecisionErrorMessage) {
      errors.push({name: langStore.temporalPrecisionLabel, error: this.temporalPrecisionErrorMessage});
    }
    if (this.cityOrLatLonErrorMessage) {
      errors.push({name: langStore.locationTitle, error: this.cityOrLatLonErrorMessage});
    }
    if (this.latitudeErrorMessage) {
      errors.push({name: langStore.eventLatitudeLabel, error: this.latitudeErrorMessage});
    }
    if (this.longitudeErrorMessage) {
      errors.push({name: langStore.eventLongitudeLabel, error: this.longitudeErrorMessage});
    }
    return errors;
  }

  @computed get totalDurationSeg(): number | null {
    let totalSeconds: number = 0;
    if (this.eventDuration.hours !== "") {
        totalSeconds += 60 * 60 * Number(this.eventDuration.hours);
    }
    if (this.eventDuration.minutes !== "") {
        totalSeconds += 60 * Number(this.eventDuration.minutes);
    }
    if (this.eventDuration.seconds !== "") {
        totalSeconds += Number(this.eventDuration.seconds);
    }
    return totalSeconds === 0 ? null : totalSeconds;
  }

  @computed get processingTitle() {
    return  this.rootStore.langStore.processingTitle;
  }

  @computed get processingDescription() {
    return this.rootStore.langStore.processingDescription;
  }

  * download(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    downloadFromBlob(blob, name);
  }

  * downloadImage(eventId: string, imageId: string, name: string): any {
    const blob = yield download(this.rootStore.authStore, `/event-images/${eventId}/${imageId}`);
    if (blob.type.split("/")[0] === "image") {
      this.currentImage = yield getUrlFromBlob(blob);
    } else {
      downloadFromBlob(blob, name);
    }
  }

  setErrorDialogOpen(value: boolean, title?: string, description?: string) {
    if (title) {
      this.errorTitle = title;
    }
    if (description) {
      this.errorDescription = description;
    }
    this.errorDialogOpen = value;
  }

  addToDeletedImages(image: EventImage) {
    this.deletedImages[image.id] = image;
  }

  restoreFromDeletedImages(image: EventImage) {
    delete this.deletedImages[image.id];
  }

  * deleteFile(fileData: UploadedFileType): any {
    try {
      this.status = "processing";
      const api = getApi(this.rootStore.authStore);
      const data = yield api.deleteUploadedFile({ticket: fileData.ticket});
      if (data.deleteUploadedFile !== fileData.ticket) {
        this.status = "error";
        this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, "Error");
        return false;
      }
      delete this.uploadedFiles[fileData.ticket];
      this.status = "ok";
      return true;
    } catch (error) {
      this.status = "error";
      const message = (error instanceof Error) ? error.message : undefined;
      this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
      return false;
    }
  }

  * onUploadFile(file: File): any {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      this.status = "error";
      const message = this.rootStore.langStore.fileSizeExceeded;
      this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
      return false;
    }
    for (let x of Object.values(this.uploadedFiles)) {
      if (file.name === x.filename) {
        this.status = "error";
        const message = this.rootStore.langStore.duplicatedFilename;
        this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
        return false;
      }
    }
    try {
      this.status = "processing";
// const api = getApi(this.rootStore.authStore);
      const data = yield apiUploadFile(this.rootStore.authStore, true, file);
//      const data = yield api.fileUpload({file: file});
      this.uploadedFiles[data.singleUpload.ticket] = data.singleUpload;
      this.status = "ok";
      return true;
    } catch (error) {
      this.status = "error";
      const message = (error instanceof Error) ? error.message : undefined;
      this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, message);
      return false;
    }
  }

  * sendReport(): any {
    if (this.canSendReport) {
      const removeAttrs = (x: any, attrs: string[]) => {
        for (const a of attrs) {
          delete x[a];
        }
        return x;
      }
      const api = getApi(this.rootStore.authStore);
      try {
        if (this.currentEventId) {
          const variables: UpdateEventMutationVariables = {
            id: this.currentEventId,
            eventTime: this.eventTime,
            sourceId: this.informationSource,
            eventTypes: this.eventTypeKeys,
            temporalPrecisionSeg: this.temporalPrecisionSeg === "" ? null : Number(this.temporalPrecisionSeg),
            totalDurationSeg: this.totalDurationSeg,
            description: this.description === "" ? null : this.description,
            miscellany: null,
            references: {
              delete: Object.values(this.referencesCrudRows).filter(x=>originalDeletedStatusList.includes(x.__status__)).map(x=>{return {id: x.id}}),
              create: Object.values(this.referencesCrudRows).filter(x=>addedStatusList.includes(x.__status__)).map(x=> {return removeAttrs({...x}, ["__status__", "id"])}),
              update: Object.values(this.referencesCrudRows).filter(x=>originalUpdatedStatusList.includes(x.__status__)).map(x=>{return removeAttrs({...x}, ["__status__"])}),
            },
            impacts: this.impactKeys.length === 0 ? null : this.impactKeys,
            images: {
              create: Object.keys(this.uploadedFiles).map((x) => {return {
                ticket: this.uploadedFiles[x].ticket,
                file: this.uploadedFiles[x].filename,
              }}),
              delete: Object.keys(this.deletedImages).map(x => {return {id: x}}),
            },
            confidenceId: this.confidence,
            content: null,
            latitude: this.latitude === "" ? null : this.latitude,
            longitude: this.longitude === "" ? null : this.longitude,
            cityId: (typeof this.city) === "string" ? null : (this.city as CityOption).value,
            stateId: this.state === "" ? null :this.state,
            countryId: this.rootStore.stateStore.countryId,
            spacialPrecisionMeters: this.spacialPrecisionMeters === "" ? null : Number(this.spacialPrecisionMeters),
            location: this.location === "" ? null : this.location,
            detailedLocation: this.detailedLocation === "" ? null : this.detailedLocation,
            hailMaxDiameterMM: this.hailMaxDiameterCM === "" ? null : Number(this.hailMaxDiameterCM) * 10,
            windSpeedKmH: this.windSpeedKmH === "" ? null : Number(this.windSpeedKmH),
            windSpeedMaxKmH: this.windSpeedMaxKmH === "" ? null : Number(this.windSpeedMaxKmH),
            windDirectionDegrees: this.windDirectionDegrees === "" ? null : Number(this.windDirectionDegrees),
            precipitationAccumMM: this.precipitationAccumMM === "" ? null : Number(this.precipitationAccumMM),
            snowAccumMM: this.snowAccumCM === "" ? null : Number(this.snowAccumCM) * 10,
          };
          const result = yield api.UpdateEvent(variables);
          this.savedEventId = result.eventUpdate.id;
          this.saveErrorMessage = {message_en: '', message_es: '', message_pt: '', code: ''};
          this.confirmMessage = this.rootStore.langStore.reportSentOk;
          this.status = "ok";
        } else {
          const variables: NewEventMutationVariables = {
            eventTime: this.eventTime,
            sourceId: this.informationSource,
            eventTypes: this.eventTypeKeys,
            temporalPrecisionSeg: this.temporalPrecisionSeg === "" ? null : Number(this.temporalPrecisionSeg),
            totalDurationSeg: this.totalDurationSeg,
            description: this.description === "" ? null : this.description,
            miscellany: null,
            references: Object.values(this.referencesCrudRows).filter(x=>addedStatusList.includes(x.__status__)).map(x=> {return removeAttrs({...x}, ["__status__", "id"])}),
            impacts: this.impactKeys.length === 0 ? null : this.impactKeys,
            images: Object.keys(this.uploadedFiles).map((x) => {return {
              ticket: this.uploadedFiles[x].ticket,
              file: this.uploadedFiles[x].filename,
            }}),
            confidenceId: this.confidence,
            content: null,
            latitude: this.latitude === "" ? null : this.latitude,
            longitude: this.longitude === "" ? null : this.longitude,
            cityId: (typeof this.city) === "string" ? null : (this.city as CityOption).value,
            stateId: this.state === "" ? null :this.state,
            countryId: this.rootStore.stateStore.countryId,
            spacialPrecisionMeters: this.spacialPrecisionMeters === "" ? null : Number(this.spacialPrecisionMeters),
            location: this.location === "" ? null : this.location,
            detailedLocation: this.detailedLocation === "" ? null : this.detailedLocation,
            hailMaxDiameterMM: this.hailMaxDiameterCM === "" ? null : Number(this.hailMaxDiameterCM) * 10,
            windSpeedKmH: this.windSpeedKmH === "" ? null : Number(this.windSpeedKmH),
            windSpeedMaxKmH: this.windSpeedMaxKmH === "" ? null : Number(this.windSpeedMaxKmH),
            windDirectionDegrees: this.windDirectionDegrees === "" ? null : Number(this.windDirectionDegrees),
            precipitationAccumMM: this.precipitationAccumMM === "" ? null : Number(this.precipitationAccumMM),
            snowAccumMM: this.snowAccumCM === "" ? null : Number(this.snowAccumCM) * 10,
          };
          const result = yield api.NewEvent(variables);
          this.savedEventId = result.eventCreate.id;
          this.saveErrorMessage = {message_en: '', message_es: '', message_pt: '', code: ''};
          this.uploadedFiles = {};
          this.confirmMessage = this.rootStore.langStore.reportSentOk;
          this.status = "ok";
        }
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
        }
        this.savedEventId = "";
        this.status = "error";
      }
    }
  }

  private async queryEvent(eventId: string): Promise<Event> {
    let data;
    const api = getApi(this.rootStore.authStore);
    data = await api.GetEvent({id: eventId});
    return data.eventOne as Event;
  }

  * initialize(): any {
      try {
        this.status = "processing";
        const {eventTypeStore, impactTypeStore, impactStore, confidenceStore, informationSourceStore, stateStore} = this.rootStore;
        yield eventTypeStore.initialize();
        yield impactTypeStore.initialize();
        yield impactStore.initialize();
        yield confidenceStore.initialize();
        yield informationSourceStore.initialize();
        yield stateStore.initialize();

        if (this.rootStore.authStore.me) {
          const countryCode = this.rootStore.authStore.me.organizations[0].country.id as CountryCodes;
          this.rootStore.stateStore.setCountryId(countryCode);
        }

        if (this.currentEventId) {
          const event = yield this.queryEvent(this.currentEventId);
          this.setEvent(event);
        }

        this.status = "ok";
      } catch (error) {
        if (error instanceof Error) {
          const apiError = getApiError(error, this.rootStore.langStore);
          let message = apiError.message;
          this.setErrorDialogOpen(true, apiError.title, message);
        } else {
          this.setErrorDialogOpen(true, this.rootStore.langStore.serverError, undefined);
        }
        this.status = "error";
      }
  }

  onCloseErrorDialog(): void {
      this.errorDialogOpen = false;
  }
}