/**
 * @author Fido García <garciafido@gmail.com>
 */

import {Box, Button, ListItem, ListItemIcon, Typography} from "@mui/material";
import List from "@mui/material/List";
import CircleIcon from "@mui/icons-material/Circle";
import ViewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import * as React from "react";
import {Event} from "../../../api/graphql";
import {RootStore} from "../../../stores";


export const getInfoBlocks = ({rootStore, event, boxesColor}: {rootStore: RootStore, event: Event | null | undefined, boxesColor: string}) => {
    const {
        langStore,
        eventReviewStore,
    } = rootStore;

    let mainInfo;
    let impactInfo;
    let referencesInfo;

    if (event) {
        const bgColor = (confidence: string) => confidence === "VERIFIED" ? "#1c8005" : confidence === "RAW" ? "" : "#e51a1a";
        const fgColor = (confidence: string) => confidence === "VERIFIED" ? "white" : confidence === "RAW" ? "" : "white";

        mainInfo = <div>
            <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                    <Typography variant={"h6"}>{langStore.sourceInformationTitle}</Typography>
                    <Typography>
                        {`${event.author.name} (${event.author.email})`}
                    </Typography>
                    <Typography>
                        {`${langStore.sourceInformationLabel}: ${event.source[langStore.fieldNameLang]}`}
                    </Typography>
                    {event.confidence && <Typography bgcolor={bgColor(event.confidence.id)} color={fgColor(event.confidence.id)}>
                        {`${langStore.eventConfidence}: ${event.confidence[langStore.fieldNameLang]}`}
                    </Typography>}
                    {event.reviewer && <Typography>
                        {`${langStore.reviewerTitle}: ${event.reviewer.name}`}
                    </Typography>}
            </Box>

            <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                <Typography variant={"h6"}>{langStore.eventLocalizationSectionTitle}</Typography>
                {event.country && <Typography>
                    {`${event.city ? event.city.name + ", " : ""}${event.state ? event.state.name + " - " : ""}${event.country.name}`}
                </Typography>}
                {event.city && <Typography>
                    {`(${event.city?.latitude}, ${event.city?.longitude})`}
                </Typography>}
                {event.latitude && <Typography>
                    {`Lat: ${event.latitude} Lon: ${event.longitude}`}
                </Typography>}
                {event.spacialPrecisionMeters && <Typography>
                    {`${langStore.spatialPrecisionLabel}: ${event.spacialPrecisionMeters} m`}
                </Typography>}
            </Box>

            <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                <Typography variant={"h6"}>{langStore.eventTimeSectionTitle}</Typography>
                <Typography>
                    {`${event.eventTime.toString().replace("T", " ").replace(".000Z", " UTC")}`}
                </Typography>
                {event.temporalPrecisionSeg && <Typography>
                    {`${langStore.temporalPrecisionLabel}: ${Math.trunc(event.temporalPrecisionSeg/60)} min`}
                </Typography>}
                {event.totalDurationSeg && <Typography>
                    {`${langStore.eventDurationLabel}: ${event.totalDurationSeg/60} min`}
                </Typography>}
            </Box>
        </div>;

        impactInfo = <div>
            <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                <Typography variant={"h6"}>
                    {`${langStore.eventTypeTitle}`}
                </Typography>
                <Typography>
                    {`${event.eventTypes.map(x => x[langStore.fieldNameLang]).join(", ")}`}
                </Typography>
                {event.hailMaxDiameterMM && <Typography>
                    {`${langStore.eventHailDiameterMMLabel}: ${event.hailMaxDiameterMM}`}
                </Typography>}
                {event.windSpeedKmH && <Typography>
                    {`${langStore.eventWindSpeedLabel}: ${event.windSpeedKmH} km/h`}
                </Typography>}
                {event.windSpeedMaxKmH && <Typography>
                    {`${langStore.eventWindMaxSpeedLabel}: ${event.windSpeedMaxKmH} km/h`}
                </Typography>}
                {event.windDirectionDegrees && <Typography>
                    {`${langStore.eventWindDirectionLabel}: ${event.windDirectionDegrees}º`}
                </Typography>}
                {event.precipitationAccumMM && <Typography>
                    {`${langStore.eventPrecipitationAccumTitle}: ${event.precipitationAccumMM}`}
                </Typography>}
                {event.snowAccumMM && <Typography>
                    {`${langStore.eventSnowAccumMMLabel}: ${event.snowAccumMM}`}
                </Typography>}
            </Box>

            {event.impacts && event.impacts.length > 0 && <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                <Typography variant={"h6"}>{langStore.eventImpactSectionTitle}</Typography>
                {<List>
                    {event.impacts.map((x, index) =>
                        <ListItem key={index} sx={{paddingLeft: 0, marginLeft: 0, marginTop: 0, paddingTop: 0}} dense={true}>
                            <div>
                                <ListItemIcon  sx={{minWidth: 16, verticalAlign: "middle"}}>
                                    <CircleIcon sx={{fontSize: 7}} />
                                </ListItemIcon>
                                {x[langStore.fieldNameLang]}
                            </div>
                        </ListItem>)}
                </List>}
            </Box>}

            {event.description && <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                <Typography variant={"h6"}>{langStore.descriptionLabel}</Typography>
                <Typography>
                    {event.description}
                </Typography>
            </Box>}

        </div>;

        referencesInfo = <div>
            {event.references && event.references.length > 0 && <Box  sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                    <Typography variant={"h6"}>{langStore.eventReferencesTitle}</Typography>
                    {event.references.map((x, index) =>
                        <ListItem key={index} sx={{paddingLeft: 0, marginLeft: 0, marginTop: 0, paddingTop: 0}} dense={true}>
                            <div>
                                <ListItemIcon  sx={{minWidth: 16, verticalAlign: "middle"}}>
                                    <CircleIcon sx={{fontSize: 7}} />
                                </ListItemIcon>
                                    <a href={x.link} target='_blank' rel='noopener noreferrer'>
                                        {x.link.substring(0, x.link.length > 30 ? 30 : undefined) + "..."}
                                    </a>
                            </div>
                        </ListItem>)}
            </Box>}

            {event.images && event.images.length > 0 && <Box sx={{
                  backgroundColor: boxesColor,
                  boxShadow: 2,
                  borderRadius: 2,
                  marginBottom: 1,
                  p: 1
                }}>
                    <Typography variant={"h6"}>{langStore.eventImagesTitle}</Typography>
                    {event.images.map((x, index) =>
                        <ListItem key={index} sx={{paddingLeft: 0, marginLeft: 0, marginTop: 0, paddingTop: 0}} dense={true}>
                            <div>
                                <ListItemIcon  sx={{minWidth: 16, verticalAlign: "middle"}}>
                                    <CircleIcon sx={{fontSize: 7}} />
                                </ListItemIcon>
                                <Button onClick={() => event && eventReviewStore.downloadImage(event.id, x.id, x.name)}>
                                     {x.name.substring(0, x.name.length > 30 ? 30 : undefined) + "..."}
                                </Button>
                                <IconButton onClick={() => event && eventReviewStore.showImage(event.id, x.id, x.name)}>
                                  <ViewIcon />
                                </IconButton>
                            </div>
                        </ListItem>)}
            <img src={eventReviewStore.currentImage} alt="" />
            </Box>
            }

        </div>;
    } else {
        mainInfo = <div />;
        impactInfo = <div />;
        referencesInfo = <div />;
    }

    return {
      mainInfo,
      impactInfo,
      referencesInfo
    }
}
