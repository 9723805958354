import * as React from 'react';

import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {User} from "../../../../api/graphql";
//import 'devextreme/dist/css/dx.light.css';
import EditIcon from '@mui/icons-material/Edit';
import ResetPasswordIcon from '@mui/icons-material/SyncLock';
import {
    SortingState,
    Sorting,
    Column, ChangeSet,
} from "@devexpress/dx-react-grid";
import {
    Table,
} from "@devexpress/dx-react-grid-material-ui";
import {TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {Typography} from "@mui/material";
import {GridExpress} from "../../../../components";
import {UserForm} from "../UserForm";
import {ConfirmCancelDialog} from "../../../common/dialogs/ConfirmCancelDialog";

const TableCell: any = Table.Cell;

type Props = {
    users: User[],
    reloadedUser?: User | null,
    selectUser: (rowClickUser: any) => void,
    pageSize: number,
    currentPage: number,
    setPageSize: (size: number) => void,
    hasMorePages: boolean,
    nextPage: () => void,

    onColumnWidthsChange: (nextColumnWidths: TableColumnWidthInfo[]) => void;
    columnWidths: TableColumnWidthInfo[];
    onColumnOrderChange: (nextOrder: string[]) => void;
    columnOrder: string[];
    viewRow: (id: string) => void;
    editRow: (id: string) => void;
    columns: Column[];
    onHiddenColumnNamesChange: (hiddenColumnNames: string[]) => void;
    hiddenColumnsNames: string[];
    onSortingChange: (sorting: Sorting[]) => void;
    sorting: Sorting[] | undefined;
    columnExtensions: SortingState.ColumnExtension[] | undefined;
    onSelectionChange: (selection: (number | string)[]) => void;
    userFormat: (user: User) => Record<string, any>;
    componentId: string;
};

export const ListUser: React.FC<Props> = observer(({
    users,
    reloadedUser,
    selectUser,
    pageSize,
    currentPage,
    setPageSize,
    hasMorePages,
    nextPage,
    onColumnWidthsChange,
    columnWidths,
    onColumnOrderChange,
    columnOrder,
    viewRow,
    editRow,
    columns,
    onHiddenColumnNamesChange,
    hiddenColumnsNames,
    onSortingChange,
    sorting,
    columnExtensions,
    onSelectionChange,
    userFormat,
    componentId,
}) => {
    const rootStore = useRootStore();
    const {
      langStore,
      userListStore
    } = rootStore;

    let rows: any = [];
    if (users) {
        rows = users.map(user => userFormat(user));
    }

    const actionColumns = [
        {
          columnName: "__action_edit",
          onClick: (props: any) => editRow(props.id),
          icon: <EditIcon />
        },
        {
          columnName: "__action_reset_password",
          onClick: (props: any) => userListStore.setResetPasswordId(props.id),
          isActionEnabled: (row: any) => row.active === true,
          icon: <ResetPasswordIcon />
        },
      ];

    const fontWeight = (id: string) =>  reloadedUser && id === reloadedUser.id ? "bold" : "regular";

    const renderReferencedIdCell = (props: any) => {
        const ids = props.value;
        if (ids) {
            const labels = ids.map((id: string, index: any) => {
                return (
                  <Typography key={index} style={{marginBottom: 1}}>
                    {id}
                  </Typography>
                )});
            return <TableCell {...props} style={{fontWeight: fontWeight(props.row.id)}}>{labels}</TableCell>
        }
        return <div />;
    }

    const cellComponent = (props:any) => {
        if (["roles", "organizations"].includes(props.column.name)) {
            return renderReferencedIdCell(props);
        } else if (["active"].includes(props.column.name)) {
            return (
              <TableCell {...props} style={{fontWeight: fontWeight(props.row.id)}}>
                <Typography style={{marginBottom: 1}}>
                    {props.value.toString()}
               </Typography>
              </TableCell>
            );
        }
        return <TableCell {...props} style={{fontWeight: fontWeight(props.row.id)}} />;
    }

    return (
        <div id={componentId}>
            <div id={"StartOfListing"} />
            <Typography variant={"h6"}>{langStore.listUserLabel}</Typography>

            <GridExpress
                columns={columns}
                rows={rows}
                sorting={sorting}
                onSortingChange={onSortingChange}
                columnExtensions={columnExtensions}
                currentPage={currentPage}
                pageSize={pageSize}
                onSelectionChange={onSelectionChange}
                cellComponent={cellComponent}
                actionColumns={actionColumns}
                columnOrder={columnOrder}
                onColumnOrderChange={onColumnOrderChange}
                columnWidths={columnWidths}
                onColumnWidthsChange={onColumnWidthsChange}
                onHiddenColumnNamesChange={onHiddenColumnNamesChange}
                hiddenColumnNames={hiddenColumnsNames}
                // showEditCommand={false}
                // showDeleteCommand={false}
                // showAddCommand={false}
                // editingProps={{
                //     onCommitChanges: (changes: ChangeSet) => userListStore.onCommitChanges(changes),
                // }}
                // editComponent={(row) => <UserForm editedRow={row} />}
                // editInPopup={true}
            />
        <div id={"EndOfListing"} />
          <ConfirmCancelDialog
              title={langStore.confirmRestePasswordTitle}
              description={langStore.confirmRestePasswordDescription}
              open={userListStore.resetPasswordDialogOpen}
              onConfirm={() => userListStore.resetPassword()}
              onCancel={() => userListStore.cancelResetPassword()}
          />
        </div>
    );
});
