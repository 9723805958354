import * as React from 'react';
import {
    FormControl,
    RadioGroup, FormControlLabel, Radio
} from "@mui/material";
import {observer} from "mobx-react";
import {RelatedOption} from "../types";
import {useRootStore} from "../stores/contexts";

type Props = {
    options: RelatedOption[] | null;
    value: string | null;
    onChange: (id: string) => void;
}

export const RadioButtons: React.FC<Props> = observer(({options, value, onChange}) => {
  let elements: JSX.Element[] = [];
  const rootStore = useRootStore();
  const { langStore } = rootStore;

  if (options !== null) {
      elements = options.map((x, index) => {
          return <FormControlLabel
              key={index}
              value={x.id}
              control={<Radio checked={x.id === value}
                              sx={{ m: 1 }}
                              onChange={(event) => onChange(event.target.value)}
                              size="small" />}
              label={x[langStore.labelNameLang]} />;
      });
  }

  return (
        <FormControl component="fieldset">
          <RadioGroup>
              {elements}
          </RadioGroup>
        </FormControl>
  );
});
