import React from 'react';
import {PageLayout} from "../layout";
import {Button} from "@mui/material";
import {observer} from "mobx-react";
import {useRootStore} from "../../stores/contexts";

export const HomePage = observer(() => {
    const rootStore = useRootStore();
    const { langStore, breadcrumbsStore } = rootStore;
    const name = rootStore.authStore.me !== null ? rootStore.authStore.me.name : "";

    if (rootStore.authStore.status === "logged" && rootStore.authStore.me !== null) {
        return (
            <PageLayout>
                <h1>{langStore.welcomedText(name)}</h1>
            </PageLayout>
        );
    }
    return (
        <PageLayout>
            <h1>{langStore.homeTitle}</h1>
            <Button onClick={() => breadcrumbsStore.push("/login")}>
                {langStore.logInLabel}
            </Button>
        </PageLayout>
    );
});