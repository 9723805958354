import {PageLayout} from "../layout";
import React from "react";
import {observer} from "mobx-react";
import {ChangePasswordForm} from "../../auth";

export const ChangePasswordPage = observer(() => {
    return (
        <PageLayout>
            <ChangePasswordForm />
        </PageLayout>
    );
});
