/**
 * @author Fido García <garciafido@gmail.com>
 */
import React from 'react';
import {observer} from "mobx-react";
import {useRootStore} from "../../../../stores/contexts";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export type UserFormProps = {
  editedRow?: any;
}

export const OrganizationMiniComponent: React.FC<UserFormProps> = observer((
  {
    editedRow,
  }) => {
  const rootStore = useRootStore();
  const {
    organizationStore,
    userOneStore,
    langStore
  } = rootStore;

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{langStore.labelOrganizations}</InputLabel>
      <Select
        labelId="role-simple-select-label"
        id="role-simple-select"
        value={userOneStore.selectedOrganizationId}
        label={langStore.labelOrganizations}
        onChange={(event)=>{userOneStore.setSelectedOrganizationId(event.target.value)}}
      >
        {
        organizationStore.organizationList.map(x=>{
          return (<MenuItem key={x.id} value={x.id}>{`${x.name} - ${x.description}`}</MenuItem>);
        })
        }
      </Select>
    </FormControl>
  );

});